/* eslint-disable */
import { CircularProgress, Grid, RACButton, RACModalCard, Typography } from "@rentacenter/racstrap";
import { useEffect, useState } from "react";
import { ReactComponent as AlterIcon } from '../../../assets/images/Alert-1.svg';
import { fetchDropDownAPI, getXlFile } from "../../../api/user";
import { ReactComponent as SomethingWrong } from '../../../assets/images/no-records-found.svg';

export default function MissingPrice(props: any) {
    useEffect(() => {
        fetchMissingPriceDetails()
    }, [])

    const [popupop, setpopupop] = useState<boolean>(false);
    const [loader, setloader] = useState<boolean>(false);
    const [errorPopUp, setErrorPopUp] = useState<boolean>(false);
    const [link, setLink] = useState<string>('');

    const masterLoader: any = {
        position: "fixed",
        backgroundColor: "#212529",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        opacity: 0.6,
        zIndex: 1000,
        textAlign: "center",
        margin: 0,
    }

    const Loader: any = {
        display: "block",
        position: "fixed",
        zIndex: 1051,
        top: "50%",
        right: "50%",
    }

    const gridtextcenterNorecords: any = {
        textAlign: "center",
        marginTop: '5px',
        width: "100%",
        fontFamily: "OpenSans-regular",
        fontSize: '14px',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }

    async function fetchMissingPriceDetails() {
        try {
            const payload: any = {
                type: ["OVERALL_MISSING_PRICE"]
            }
            const response = await fetchDropDownAPI(payload)
            if (response && response.status == 200 && response.data && response.data.overAllMissingPrice &&
                response.data.overAllMissingPrice.length > 0) {
                const missingCount = response.data.overAllMissingPrice[0].missingCount || 0;
                const docKey = response.data.overAllMissingPrice[0].docKey || '';
                props.data.setMissingPrice({ ...props.data.missingPrice, missingCount: missingCount, docKey: docKey })
            } else {
                props.data.setMissingPrice({ ...props.data.missingPrice, missingCount: 0 })
            }
        }
        catch (e) {
            props.data.setMissingPrice({ ...props.data.missingPrice, missingCount: 0 })
        }
    }

    const getReportXlFiles = async (dockey: any) => {
        try{
            setloader(true)
            const key = {
                key: dockey,
                type: 'GET',
            };
            const ReportResposne = await getXlFile(key);
            setloader(false)
            if (ReportResposne.status == 200 && ReportResposne.status != undefined) {
                const link = ReportResposne.data.generatedUrl;
                setLink(link)
            } else {
                setErrorPopUp(true);
            }
            setpopupop(true);
        }
        catch(e){
            setErrorPopUp(true);
        }
    };

    const downloadReport = () => {
        const element = document.createElement('a');
        element.setAttribute('href', `${link}`);
        element.setAttribute('style', 'display:none');
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }


    const DownloadReportPopup = () => {
        return (
            <>{errorPopUp ?
                <>
                    <Grid style={gridtextcenterNorecords}>
                        <SomethingWrong style={{ width: '50px' }} />
                    </Grid>
                    <Grid style={{ fontFamily: 'OpenSans-bold', textAlign: 'center', padding: '20px' }}>
                        Something Went Wrong !
                    </Grid>
                </> :
                <Grid style={{ textAlign: "center", width: "100%", padding: "0%" }}>
                    <Grid>
                        <Typography style={{ marginBottom: '10px', fontFamily: 'OpenSans-bold' }}>
                            {props.data.missingPrice.missingCount} RMS items doesn't have complete pricing details
                        </Typography>
                        <Typography style={{ fontFamily: 'OpenSans-bold' }}>
                            Please <span style={{ color: '#3876ff', textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => downloadReport()}>Download Report</span> to view more details and insert
                        </Typography>
                        <Typography style={{ fontFamily: 'OpenSans-bold', marginBottom: '20px' }}>
                            pricing for the missing RMS items
                        </Typography>
                    </Grid>
                    <Grid>
                        <RACButton
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setpopupop(false)
                            }}
                        >
                            OK
                        </RACButton>
                    </Grid>
                </Grid>}
            </>
        );
    };

    return (
        <>
            {loader ? (
                <Grid style={masterLoader}>
                    <Grid style={Loader}>
                        <CircularProgress />
                    </Grid>
                </Grid>
            ) : null}
            <Grid style={{ backgroundColor: 'pink', padding: '10px 20px', borderRadius: '3px', display: 'flex', cursor: 'pointer' }}
                onClick={() => {
                    if (!link) {
                        getReportXlFiles(props.data.missingPrice.docKey)
                    } else {
                        setpopupop(true);
                    }
                }}>
                <AlterIcon style={{ width: '20px', height: '20px', cursor: 'pointer' }} />
                <Typography style={{ color: '#e75c5c', marginLeft: '5px', cursor: 'pointer' }}>{props.data.missingPrice.missingCount}</Typography>
            </Grid >
            <RACModalCard
                isOpen={popupop}
                closeIcon={true}
                maxWidth="xs"
                onClose={() => setpopupop(false)}
                children={DownloadReportPopup()}
                borderRadius={'10px !important'}
            />
        </>
    )
}