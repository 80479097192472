/* eslint-disable */
import {
  RACSelect,
  Typography,
  RACButton,
  Grid,
  CardContent,
  Card,
  RACCheckBox,
  RACTable,
  RACTableCell,
  RACTableRow,
  RACTextbox,
  RACModalCard,
  RACDatePicker,
  CircularProgress,
  RACRadio,
} from "@rentacenter/racstrap";
import { filterObj } from "../shared/interface/getProfileBasedOnMenuInterface";
import { ReactComponent as RacFilter } from "../..//assets/images/filter-white.svg";
import { ReactComponent as UpArrow } from "../../assets/images/up-arrow-filter.svg";
import { ReactComponent as DownArrow } from "../../assets/images/down-arrow-filter.svg";
import { ReactComponent as SortdownIcon } from "../../assets/images/sort-down-icon.svg";
import { ReactComponent as SortupIcon } from "../../assets/images/sort-icon-up.svg";
import { ReactComponent as EditIcon } from "../../assets/images/Edit Icon.svg";
import { ReactComponent as SomethingWrong } from "../../assets/images/no-records-found.svg";
import { ReactComponent as SuccessIcon } from "../../assets/images/success-icon.svg";
import { ReactComponent as Danger } from "../../assets/images/danger.svg";
import { ReactComponent as NorecordsIcon } from '../../assets/images/No-records.svg';
import React, { useContext, useEffect, useState } from "react";
import { ProgressBar } from "../Pricing/Styles/ProgressStyles";
import { globalStyles } from "../Pricing/Styles/globalstyles";
import { headcolumns } from "../Pricing/PricingUpload";
import { object } from "yup";
import { getEmployeeInfoAndReferenceData, validateApprover } from "../shared/getEmployeeInfoAndReferenceData";
import { createContext } from "react";
import ReactPaginate from "react-paginate";
import TwoFactorAuthentication from "../shared/TwoStepAuthentication";
// import { getFilterData, insertZoneValue, postFilterValue } from "../../api/user";
import { ContainerContext } from "../../app/App";
import { getZoneDD, insertZoneValue, postFilterValue} from "../../api/user";
import updatePricingQueueStatusAPI from "../../api/user";
import moment from "moment";
import { PricingReportStyle } from "./Styles/PricingReport";

// store number should be empty or a 5-digit string
const validateStoreNumber = (storeNumber: unknown): boolean =>
  typeof storeNumber === 'undefined' ||
  storeNumber === '' ||
  (typeof storeNumber === 'string' && /^\d{5}$/.test(storeNumber));

export default function StoreZone() {
  const containerData = useContext(ContainerContext);
const pricing = PricingReportStyle();
  console.log("storeZone");
  const statusobject = [
    { value: "", label: "select" },
    { value: "Completed", label: "Completed" },
    { value: "Failed", label: "Failed" },
    { value: "Partially Completed", label: "Partially Completed" },
    { value: "Pending", label: "Pending" },
    { value: "Validation Failed", label: "Validation Failed" },
  ];
  const iframes = document.querySelector('iframe');
        if (iframes) {
          iframes?.remove();
        }
 
  const [loader, setLoader] = useState(false);
  const [states,setStates] = useState([{
    label: 'Select',
    value: '0'}]);
    const [storeNumber, setStoreNumber] = useState([{
    label: 'Select',
    value: '0'}])
  const [isApprovalOpen, setIsApprovalOpen] = useState(false);
  const [call2stepAuth, setCall2stepAuth] = useState(false);
  const [gridLoader, setGridLoader] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false)//added for showing the no grid data found. for true and false.
  const [filterPopup, setFilterPopup] = useState(true);// To show the filter card to the user (Boolean) 
  const [disableFlag, setDisableFlag] = useState(false);// For disabling the field in the form
  const [enableGrid, setEnableGrid] = useState(false);// to enable the grid after the apply click.
  const [pageCount, setPageCount] = useState<number>(1);
  const [totRecord, setTotRecord] = useState<any>();
  const [pginitial, setpginitial] = useState(0);
  const [cantEditPopup, setCantEditPopup] = useState(false);// To invoke the racmodelcard for the cantEditpopup.
  const [updateStoreZonePopup, setUpdateStoreZonePopup] = useState(false);//to invoke the racmodelcard for updatestorezone
  const [newStoreZonepopup, setNewStoreZonepopup] = useState(false);// to invoke the racmodelcard for newstorezone
  const [rmsNumber, setRmsNumber] = useState("08");
  const [errorMsgStoreZone, setErrorMsgStoreZone] = useState("");//For displaying the error messages in the something went wrong popup.
  const [submittedPopup, setSumbittedPopup] = useState(false);// for opening the approval popup
  const [pricingQueueId, setPricingQueueId] = useState("");
  const [successMessage, setSuccessMessage] = useState(0);// got to be used later.
  const [orphanStore, setOrphanStore] = useState(false);
  const [zoneDropDown, setZoneDropDown] = useState([]);
  const [orpahnChecked, setOrphanChecked] = useState(false);
  const [franchisee, setFranchisee] = useState();
  const [sucessPopup, setSuccessPopup] = useState(false)
  const [selectQueueType, setSelectQueueType] = useState<string>("");
  const[companyCodeStorezone, setCompanyCodeStorezone] = useState<any>()
  
  //For two step authentication
  const [emailId,setEmailId]= useState();
  const [employeeId, setEmployeeId]= useState();
  const [isApprover, setIsApprover]= useState(false);
  const[authOpen, setAuthOpen] = useState(false);
  const [isSLACompleted, setIsSLACompleted]= useState(false);
  const [isSLACancel,setIsSLACancel] = useState(false);
  const [zoneNumberForRmsCount, setZoneNumberForRmsCount] = useState<any>();
  const insertZoneSuccessText = 'Zone has been added successfully';
  const updateZoneSuccessText = 'Zone has been updated successfully';
  

  /* const [errorPopup, setErrorPopup] = useState(false); */ /* not using this */ 
  /* const [noDataFoundPopup, setNoDataFoundPopup] = useState(false); */ /* not using this */
  const [gridErrorPopup, setGridErrorPopup] = useState(false);
  const[bindFilterData,setFilterBindData] =useState<any>()
  const[RmsAffected,setRmsAffected]=useState<any>({})
  const[zoneName,setZoneName]=useState<any>()
  const[updtstoreZoneErrMsg,setUpdtStoreZoneErrMsg]=useState<any>()
const[RMSObj,setRMSObj] =useState<any>([])
const [rmsAffectedCount, setRmsAffectedCount] = useState<any>()
  const refObj={
    type:["STORE_STATE","ZONE","RMSITEM_COUNT", "STATE"]
  }
  const insertZoneModalObj = {
  submittedBy:"",
  status:"WAITING",
  companyCode:"",
  type:"STOREZONE",
  queueData: {
    zoneStore: {
      zoneNumber:"",
      storeNumber:"",
      startDate:""
        }
    }
  };
  const [UpdtZoneObj, setUpdtZoneObj] = useState(insertZoneModalObj);
  const [insertNewZoneObj, setInsertNewZoneObj] = useState<any>(insertZoneModalObj);//For new zone creation.  
  const [zoneValue, setZoneValue] = useState<any>([]);
  const [ApprovedBy, setApprovedBy] = useState();
  const [canAccess, setCanAccess] = useState(false);
  const classes = globalStyles();
  const progressclasses = ProgressBar();

  const sortDirection = {
    ASC: "asc",
    DESC: "desc",
  };
  let tableColumns = [
    {
      columnTitle: "Store",
      sortOrder: sortDirection.ASC,
      columnId: "storeNumber",
      IsSorted: true,
      IsSortable: true,
    },
    {
      columnTitle: "Zone",
      sortOrder: sortDirection.ASC,
      columnId: "zoneNumber",
      IsSorted: true,
      IsSortable: true,
    },
    {
      columnTitle: "Zone Name",
      sortOrder: sortDirection.ASC,
      columnId: "zoneName",
      IsSorted: false,
      IsSortable: false,
    },
    {
      columnTitle: "Company Name",
      sortOrder: sortDirection.ASC,
      columnId: "companyName",
      IsSorted: false,
      IsSortable: false,
    },
    {
      columnTitle: "Company Code",
      sortOrder: sortDirection.ASC,
      columnId: "companyCode",
      IsSorted: true,
      IsSortable: true,
    },
    {
      columnTitle: "Store Type",
      sortOrder: sortDirection.ASC,
      columnId: "storeType",
      IsSorted: false,
      IsSortable: false,
    },
    {
      columnTitle: "State",
      sortOrder: sortDirection.ASC,
      columnId: "stateCode",
      IsSorted: false,
      IsSortable: false,
    },
    {
      columnTitle: "Start Date",
      sortOrder: sortDirection.ASC,
      columnId: "startDate",
      IsSorted: true,
      IsSortable: true,
    },
    {
      columnTitle: "End Date",
      sortOrder: sortDirection.ASC,
      columnId: "endDate",
      IsSorted: true,
      IsSortable: true,
    },
    {
      columnTitle: "Action",
      sortOrder: sortDirection.ASC,
      columnId: "action",
      IsSorted: false,
      IsSortable: false,
    },
  ];

  
  const [tableRecord, SetTableRecord] = useState<any>([]);
  const [tableHeader, setTableHeader] = useState(tableColumns);

  const [filterValueObj, setFilterValueObj] = useState<filterObj>({
    zoneNumber: [],
    storeNumber: "",
    stateCode: "0",
    orphanStore: "N",
    offset: "1",
    limit: "25",
    companyCode: []
  });

  useEffect(() => {
  
    if (isSLACancel== false && isSLACompleted == true){
      console.log('The price queue Completed');

      updatePricingFun()
      // updatePricingQueueStatus(alterPopUp?.value);} approved popup
    }
  }, [isSLACancel, isSLACompleted]);

  useEffect(() => {

    bindFilterValue();
    // setLoader(false);
    // setErrorPopup(true);
  }, []);

  //calling the useEffect function and getEmployeeInfoAndReferenceData for getting all the dropdowns 
  async function bindFilterValue() {
    try {
      setGridLoader(true);
      const response : any  = await getEmployeeInfoAndReferenceData(containerData, refObj);
      // const response: any = await Promise.all([getEmployeeInfoAndReferenceData(containerData, refObj)/* ,getFilterData(refObj)] */]) ;
      console.log("response for getEmployeeInfoAndReferenceData", response)
      console.log(response.dropdownData, "response getEmployeeInfoAndReferenceData");
      setCanAccess(true);
      if (response.isFranchiseeUser) {
        setFranchisee(response?.isFranchiseeUser);

          if(response.companyCode?.length>0)
          setCompanyCodeStorezone(response.companyCode);
        else{
          setErrorMsgStoreZone('Something Went Wrong');
          setGridErrorPopup(true);
          setCompanyCodeStorezone(response.companyCode);
      }
      }
      setFranchisee(response?.isFranchiseeUser);
      setZoneDropDown(response.dropdownData.zone.sort((a: any, b: any) =>
      a["zoneNumber"].localeCompare(b["zoneNumber"], undefined, { numeric: 'true' })));
      setMenuConfigArray(response.menuConfigArray);
      if (response.status == 200) {
        console.log(response,'validateApprover',validateApprover(response.menuConfigArray));
        setEmployeeId(response.employeeId);
        setEmailId(response.emailId);
        setIsApprover(validateApprover(response.menuConfigArray))
        insertZoneModalObj.submittedBy = response.userName
        let tempZoneArr : any = [];
        tempZoneArr = response?.zone.sort((a: any, b: any) =>
        a["label"].localeCompare(b["label"], undefined, { numeric: 'true' }));
        tempZoneArr.unshift({
        label:'Select',
        value:'0',
      })
        setZoneValue(response?.zone);
        let stateArr= response.dropdownData.state.map((item : any)=> {
          return ({
            label : item.stateCode,
            value : item.stateCode
        })
        })

        let tempStateArr : any = [];
        tempStateArr = stateArr;
        tempStateArr.sort((a: any, b: any) =>
        a["label"].localeCompare(b["label"], undefined, { numeric: 'true' })).unshift({
        label:'Select',
        value:'0',
      })
        setStates(stateArr);
        let storeNumberArr = response.dropdownData.storeState.map((item : any)=> {
          return ({
            label : item.storeNumber,
            value : item.storeNumber
        })
        })

        let tempStoreNumberArr : any = [];
        tempStoreNumberArr = storeNumberArr;
        tempStoreNumberArr.unshift({
        label:'Select',
        value:'0',
      })
      setStoreNumber(storeNumberArr);
      setRMSObj(response.dropdownData.rmsItemCount);

      insertNewZoneObj.companyCode = response.companyCode[0];
      console.log("response.companyCode.length", response.companyCode.length)
     setFilterValueObj({...filterValueObj, companyCode: response.companyCode.length >0 ? response.companyCode: undefined})
      setInsertNewZoneObj({...insertNewZoneObj, companyCode: insertNewZoneObj.companyCode})
      console.log("insertNewZoneObj.companyCode", insertNewZoneObj.companyCode)
      console.log("response.dropdownData.rmsItemCount", response.dropdownData.rmsItemCount)
      } 
      else if(response.status==400){
        setErrorMsgStoreZone(response.data.errors[0].error);
        setGridErrorPopup(true);
      }  
      else {
        setErrorMsgStoreZone("Something Went Wrong");
        setGridErrorPopup(true);
      }
    } catch (error) {
      console.log(error);
    }
    finally{
      setGridLoader(false);
    }
  }
  //if no records found 
  const rendernorecordsheader = () => {
    return (
      <React.Fragment>
        <RACTableCell className={`${classes.pt1} ${classes.pb1}`}>Store</RACTableCell>
        <RACTableCell className={`${classes.pt1} ${classes.pb1}`}>Zone</RACTableCell>
        <RACTableCell className={`${classes.pt1} ${classes.pb1}`}>Zone Name</RACTableCell>
        <RACTableCell className={`${classes.pt1} ${classes.pb1}`}>company Name</RACTableCell>
        <RACTableCell className={`${classes.pt1} ${classes.pb1}`}>Company Code</RACTableCell>
        <RACTableCell className={`${classes.pt1} ${classes.pb1}`}>Store Type</RACTableCell>
        <RACTableCell className={`${classes.pt1} ${classes.pb1}`}>State</RACTableCell>
        <RACTableCell className={`${classes.pt1} ${classes.pb1}`}>Start Date</RACTableCell>
        <RACTableCell className={`${classes.pt1} ${classes.pb1}`}>End Date</RACTableCell>
        <RACTableCell className={`${classes.p2} ${classes.pt1} ${classes.pb1} ${classes.textCenter}`}> Action</RACTableCell>
        </React.Fragment>
    )
  }

  let pricingQueueObj = {
    pricingQueueId: pricingQueueId,
    status: "A",
    actionBy: ApprovedBy,
  }
    const updatePricingFun = async () => {
      setLoader(true);
      console.log("pricingQueueObj.actionBy", pricingQueueObj.actionBy)
      console.log("pricingQueueId", pricingQueueId)
      let responseFromUpdatePricing = await updatePricingQueueStatusAPI(pricingQueueObj);
      if(responseFromUpdatePricing.status == 200){
        newStoreZoneClear();
        setLoader(false);
      setAuthOpen(false);
      setSuccessPopup(true)
        console.log("response for the if", responseFromUpdatePricing)
      }else if(responseFromUpdatePricing.status == 400){
        console.log("responseFromUpdatePricing", responseFromUpdatePricing)
        setErrorMsgStoreZone(responseFromUpdatePricing.data.errors[0].error);
      setGridErrorPopup(true);
      }else{
        setErrorMsgStoreZone("Something Went Wrong");
      setGridErrorPopup(true);
      }
      setLoader(false);
    }
  

  function handleFilterValues(e: any, field: string) {
    if(field == "storeNumber"){
      setFilterValueObj({ ...filterValueObj, [field]: e.target.value.replace(/\D/g, '')});
    }else if(field == "zoneNumber"){
      setFilterValueObj({ ...filterValueObj, [field]: e.target.value == '0' ? []  : [e.target.value]});
    }else{
      setFilterValueObj({ ...filterValueObj, [field]: e.target.value });
    }
  }

  console.log("length of zone in filtervalueobj");
 
  // For binding the grid data when the user clicks the apply button.
  const applyFilter= async (params: any = '') =>{
    setTotRecord(0);
    setEnableGrid(true) 
    setNoRecordsFound(false);
    setGridLoader(true);
    console.log("filter value for the orphan storezone", filterValueObj)
    try{
      let copyFilterValue = JSON.parse(JSON.stringify(filterValueObj));
    copyFilterValue.zoneNumber = (filterValueObj?.zoneNumber[0] != "0" && filterValueObj?.zoneNumber?.length != 0) ? filterValueObj?.zoneNumber : undefined;
    copyFilterValue.stateCode = filterValueObj.stateCode != '0' ? filterValueObj.stateCode : undefined; 
    copyFilterValue.storeNumber = filterValueObj.storeNumber!="" ? filterValueObj.storeNumber : undefined;
    copyFilterValue.orphanStore = filterValueObj.orphanStore!="" ? filterValueObj.orphanStore : undefined;
    copyFilterValue.companyCode = franchisee ? filterValueObj.companyCode?.length ? filterValueObj.companyCode : undefined : undefined;
    if(params == "A"){
      copyFilterValue.offset = "1";
      setpginitial(0)
    }


    console.log("the value of copy", copyFilterValue)
    const response:any = await postFilterValue(copyFilterValue);
    // const response:any = await postFilterValue(filter);
    console.log(response, "The rep");
    if (response.status == 200) {
        SetTableRecord(response.data.storeZoneDetails);
        setPageCount(Math.ceil(response.data.totalRecordsCount / 25));
        setTotRecord(response.data.totalRecordsCount);
        setNoRecordsFound(false);
      setFilterPopup(false);

      if(response.data == ""){
        SetTableRecord([]);
        setEnableGrid(true);
        setNoRecordsFound(true);
      }
    } 
    else if(response.status == 400){
      if((response.data.errors[0].error == "No data found")){
        SetTableRecord([]);
        setEnableGrid(true);
        setNoRecordsFound(true);
        }
      else {
        setErrorMsgStoreZone(response.data.errors[0].error);
        setGridErrorPopup(true)
      }
    }
    else {
      setErrorMsgStoreZone("Something Went Wrong");
      setGridErrorPopup(true);
      setNoRecordsFound(false);
    }
  } 
  catch(err){
console.log(err);
}
  finally {
    setGridLoader(false);
  }
  }
  console.log("checking if the tableREcodr is setted", tableRecord)

  //open filter zone  
  const openFilterStoreZone = () => {
    return (
      <Card
        style={{
          width: "100%",
          borderRadius: "12px",
          marginTop: "77px",
          marginBottom: "20px",
          height: "171px",
        }}
      >
        <CardContent>
          <Grid
            style={{ position: "relative", top: "11px" }}
            container
            md={12}
            spacing={2}
          >
            <Grid item md={2} lg={2} sm={2}>
              <RACSelect
                isDisabled={disableFlag}
                inputLabel="Zone"
                options={zoneValue}
                loading={zoneValue?.length > 0 || (franchisee && companyCodeStorezone?.length ==0) ? false : true}
                defaultValue={filterValueObj.zoneNumber?filterValueObj.zoneNumber:'0'}
                onChange={(e) => {
                  handleFilterValues(e, "zoneNumber");
                  // this below code restricts the word "select" to be shown in the fileld of zone drop down  when user selects the options
                  /* let tempArray: string[] = [...e.target.value];
                  if (tempArray.includes("0")) {
                    tempArray.splice(0, tempArray.length);
                    setFilterValueObj({ ...filterValueObj, zoneNumber: tempArray });
                  }
 */                }}
              ></RACSelect>
            </Grid>
            <Grid item md={2} lg={2} sm={2}>
              <RACTextbox
                disabled={disableFlag}
                value={filterValueObj.storeNumber ? filterValueObj.storeNumber : ''}
                minlength = {5}
                maxlength = {5}
                inputlabel="Store"
                OnChange={(e) => handleFilterValues(e, "storeNumber")}
              ></RACTextbox>
            </Grid>
            <Grid item md={2} lg={2} sm={2}>
              <RACSelect
                isDisabled={disableFlag}
                inputLabel="State"
                options={states}
                loading={states.length > 1 ? false : true}

                defaultValue={filterValueObj.stateCode ? filterValueObj.stateCode : '0'}
                onChange={(e) => {
                  handleFilterValues(e, "stateCode");
                }}
              ></RACSelect>
            </Grid>
            <Grid style={{ position: "relative", top: "20px" }} item md={2}>
              <RACCheckBox
                label="Orphan store"
                onChange={(e) => (e.target.checked ? setFilterValueObj({...filterValueObj, orphanStore: "Y", zoneNumber: [], stateCode: "0",storeNumber: ""}) : 
                setFilterValueObj({...filterValueObj, orphanStore: "N", zoneNumber: [], stateCode: "0", storeNumber: ""}),
                disableFlag == false ? setDisableFlag(true) : setDisableFlag(false), e.target.checked  ? setOrphanChecked(true) : setOrphanChecked(false))
                }
                checked = {orpahnChecked}
              ></RACCheckBox>
            </Grid>
          </Grid>
          <Grid>
            <Grid
              style={{ float: "right", position: "relative", top: "44px" }}
              item
              md={4}
            >
              <RACButton
                disabled={ ((filterValueObj.zoneNumber?.length != 0 && filterValueObj.zoneNumber != undefined)  ||  (filterValueObj.storeNumber != "" && filterValueObj.storeNumber != undefined)  || filterValueObj.orphanStore != "N" || (filterValueObj.stateCode != '0' && filterValueObj.stateCode != undefined))  ? false : true}
                variant="text"
                onClick={() => {
                  setFilterValueObj({...filterValueObj, orphanStore: "N", zoneNumber: [], stateCode: "0", storeNumber: ""}), setOrphanChecked(false), setDisableFlag(false) 
                }}
                style={{backgroundColor: "white", color: "blue", marginBottom: "10px", marginRight: "5px"}}
              >
                Clear
              </RACButton>
              <RACButton
                disabled={ validateStoreNumber(filterValueObj.storeNumber) && ((filterValueObj.zoneNumber?.length != 0 && filterValueObj.zoneNumber != undefined)  ||  (filterValueObj.storeNumber != "" && filterValueObj.storeNumber != undefined)  || filterValueObj.orphanStore != "N" || (filterValueObj.stateCode != '0' && filterValueObj.stateCode != undefined))  ? false : true}
                variant="contained"
                color="primary"
                onClick={() => applyFilter("A")}
                  /* setGridLoader(false); */
                style={{ color: "white", marginBottom: "10px" }}
              >
                Apply
              </RACButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };
  console.log("filterValueObj", filterValueObj);

  const bindGridHead = () => {
    return (
      <>
        {tableHeader.map((value: any, index: any) => {
          return value.columnTitle == "Action" ? (
            <RACTableCell className={`${classes.textCenter}`}>
              Action
            </RACTableCell>
          ) : (
            <RACTableCell key={index} >
              {value.IsSortable ? (
                <a
                  style={{
                    color: "black",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  id={value.columnId}
                  onClick={() =>
                    sortArray(value.columnId, index, tableHeader, tableRecord)
                  }
                >
                  {value.columnTitle + "  "}
                  {value.IsSorted === true ? (
                    value.sortOrder == sortDirection.DESC ? (
                      <SortupIcon className={classes.ml0} />
                    ) : (
                      <SortdownIcon className={classes.ml0} />
                    )
                  ) : null}
                </a>
              ) : (
                <a
                  style={{
                    color: "black",
                    textDecoration: "none",
                  }}
                  id={value.columnId}
                >
                  {value.columnTitle + "  "}
                </a>
              )}
            </RACTableCell>
          );
        })}
      </>
    );
  };

  function formatDate(date: any) { 
    console.log("date", date)
    var d = new Date(date), 
  month = '' + (d.getUTCMonth() + 1), 
  day = '' + d.getUTCDate(),
  year = d.getUTCFullYear()
  if (month.length < 2) {
  month = '0' + month;
  } 
  if (day.length < 2) {
  day = '0' + day; 
  }
  return [month, day, year].join('/');
  }

  const updtObj = {
    storeNumber: "",
    zoneNumber: "",
    zoneName: "",
    companyName: "",
    companyCode: "",
    storeType: "",
    stateCode: "0",
    startDate: "",
    endDate: "",
  };

  const [updtPopupData, setUpdtPopupData] = useState(updtObj);

  function editData(index: any) {
    if(!tableRecord[index])
    {
      return [];
    }
    console.log("index", index);
    console.log("tableRecord[index].store", tableRecord[index].store);
    console.log("tableRecord[index].endDate", tableRecord[index].endDate)
    let nullDate = tableRecord[index].endDate
    let endDate = new Date(tableRecord[index].endDate);
    console.log("endDatedd", endDate)
    let endDateYear = endDate.getUTCFullYear();
    console.log("endDateYear", endDateYear)
    if (endDateYear == 2099 || nullDate == null) {
      setUpdateStoreZonePopup(true);
    } else {
      setCantEditPopup(true);
    }
    if(nullDate != null){
      const sDate: any = tableRecord[index].startDate;
    var d = new Date(sDate);
    let month = '' + (d.getUTCMonth() + 1); 
    let day = '' + d.getUTCDate();
    let year = d.getUTCFullYear(); 
    if (month.length < 2) {
    month = '0' + month;
    } 
    if (day.length < 2) {
    day = '0' + day; 
    }
    const newStartDate = `${month}-${day}-${year}`
    const newStartDate2 = `${year}-${month}-${day}`
    console.log("tableRecord[index].zoneNumber", tableRecord[index].zoneNumber)
    setZoneNumberForRmsCount(tableRecord[index].zoneNumber)
    setUpdtPopupData({
      ...updtPopupData,
      storeNumber: tableRecord[index].storeNumber,
      zoneNumber: tableRecord[index].zoneNumber,
      zoneName: tableRecord[index].zoneName,
      companyName: tableRecord[index].companyName,
      companyCode: tableRecord[index].companyCode,
      storeType: tableRecord[index].storeType,
      stateCode: tableRecord[index].stateCode,
      startDate: newStartDate,
      endDate: tableRecord[index].endDate,
    });

    setUpdtZoneObj({ ...UpdtZoneObj, queueData: {...UpdtZoneObj.queueData, zoneStore: {...UpdtZoneObj.queueData.zoneStore, zoneNumber: tableRecord[index].zoneNumber, storeNumber: tableRecord[index].storeNumber, startDate: newStartDate2}} });
    
    }else{
      let newStartDate = "MM-DD-YYYY"
      console.log("newStartDate", newStartDate)
      console.log("tableRecord[index].zoneNumber", tableRecord[index].zoneNumber)
    setZoneNumberForRmsCount(tableRecord[index].zoneNumber)
      setUpdtPopupData({
        ...updtPopupData,
        storeNumber: tableRecord[index].storeNumber,
        zoneNumber: tableRecord[index].zoneNumber,
        zoneName: tableRecord[index].zoneName,
        companyName: tableRecord[index].companyName,
        companyCode: tableRecord[index].companyCode,
        storeType: tableRecord[index].storeType,
        stateCode: tableRecord[index].stateCode,
        startDate: newStartDate,
        endDate: tableRecord[index].endDate,
      });
  
      setUpdtZoneObj({ ...UpdtZoneObj, queueData: {...UpdtZoneObj.queueData, zoneStore: {...UpdtZoneObj.queueData.zoneStore, zoneNumber: tableRecord[index].zoneNumber, storeNumber: tableRecord[index].storeNumber, startDate: newStartDate}} });
    }
    

    // this is for updating the storezone.
    
  }
  console.log("updtPopupData", updtPopupData);

  const buildTableContent = () => {
    return (
      <>
        {gridLoader? (
          <>
          <Grid style={{position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    left: '50%'}}>
           <Grid className={pricing.textSpaceNorecords}>
             <Grid className={pricing.textcenterImage}>
             <CircularProgress />
             </Grid>
           </Grid>
         </Grid>
          </>
        ) :tableRecord?.map((value:any,index:any) => {
          let sDate = formatDate(value.startDate)
          let eDate = formatDate(value.endDate)
          return (
            <>
              <RACTableRow
                key={index}
                className={classes.bgTransparent}
              >
                <RACTableCell  style={{ fontSize: "0.85rem" }}>
                  {value.storeNumber}
                </RACTableCell>
                <RACTableCell  style={{ fontSize: "0.85rem" }}>
                  {value.zoneNumber ? value.zoneNumber : "-"}
                </RACTableCell>
                <RACTableCell  style={{ fontSize: "0.85rem" }} title={value.zoneName > 20 ? value.zoneName : ""}>
                  {value.zoneName?.lenght > 20 ? value.zoneName.slice(0, 20) + "..." : value.zoneName ? value.zoneName : "-"}
                </RACTableCell>
                <RACTableCell style={{ fontSize: "0.85rem" }} title={value.companyName.length > 35 ? value.companyName : ""}>
                {value.companyName?.length > 35 ? value.companyName.slice(0, 35) + "..." : value.companyName ? value.companyName : "-"}
                </RACTableCell>
                <RACTableCell style={{ fontSize: "0.85rem" }}>
                  {value.companyCode}
                </RACTableCell>
                <RACTableCell style={{ fontSize: "0.85rem" }}>
                  {value.storeType}
                </RACTableCell>
                <RACTableCell style={{ fontSize: "0.85rem" }}>
                  {value.stateCode}
                </RACTableCell>
                <RACTableCell style={{ fontSize: "0.85rem" }}>
                  {value.startDate ? sDate : "-"}
                </RACTableCell>
                <RACTableCell style={{  fontSize: "0.85rem" }}>
                  {value.endDate ? eDate : "-"}
                </RACTableCell>
                <RACTableCell className={`${classes.textCenter}`}>
                  <RACButton
                    onClick={() => {
                      if(validateApprover(menuConfigArray,'StoreZone'))
                      editData(index);
                    else 
                    {// alert('Hi')
                    setErrorMsgStoreZone("Access to this feature is currently restricted to this user");
                    setGridErrorPopup(true);}
                    }}
                  >
                    <EditIcon />
                  </RACButton>
                </RACTableCell>
              </RACTableRow>
            </>
          );
        })}
      </>
    );
  };

  function recordCantEdit() {
    return (
      <>
        <Grid>
            <Grid style={{ position: "relative", top: "-24px" }}>
              <Grid className={progressclasses.successPopup}>
                <Danger style={{ position: "relative", top: "13px" }} />
              </Grid>
              <Grid
                className={`${progressclasses.textcenterImage} ${classes.fontBold}`}
              >
                This record cannot be edited,since the end date has been changed
              </Grid>
              <Grid className={`${progressclasses.loader} ${classes.mt2}`}>
                <RACButton
                  style={{ position: "relative", top: "10px" }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setCantEditPopup(false);
                  }}
                >
                  OK
                </RACButton>
              </Grid>
            </Grid>
        </Grid>
      </>
    );
  }

  const sortArray = (
    columnId: string,
    index: any,
    datacolumn: any,
    dataValues: any
  ) => {
    try {
      const sortedData =
        datacolumn[index].sortOrder == sortDirection.DESC
          ? dataValues.sort((a: any, b: any) =>
              b[columnId] < a[columnId] ? -1 : 1
            )
          : dataValues.sort((a: any, b: any) =>
              a[columnId] < b[columnId] ? -1 : 1
            );
      const reorderColumns = datacolumn;
      reorderColumns[index].sortOrder =
        reorderColumns[index].sortOrder == sortDirection.ASC
          ? sortDirection.DESC
          : sortDirection.ASC;
      reorderColumns.map((value: any, index1: any) => {
        reorderColumns[index1].IsSorted = index == index1 ? true : true;
      });
      SetTableRecord([...sortedData]);
      setTableHeader([...reorderColumns]);
    } catch (error) {
      console.log(error);
    }
  };


  //Rendering the okPopup
  const renderOkPopup= ()=>{
    return (
      <>
        <Grid>
          <Grid style={{justifyContent: "center", display: "flex",}}>
            <SuccessIcon width={"10%"} height={"10%"} />
          </Grid>
          <Grid>
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                padding: "5%",
              }}
            >
              Request has been approved.
            </Typography>
          </Grid>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <RACButton
              onClick={async() => {setLoader(false);
                setSuccessPopup(false)
                setIsSLACompleted(false);
                setIsSLACancel(false);
                await applyFilter("A");
                setSelectQueueType("");
                }}
              color="primary"
              variant="contained"
            >
              OK
            </RACButton>
          </Grid>
          
        </Grid>
      </>
    );
  }
  
  const zoneNameFun = (item: any, item2: any) => {
    setUpdtPopupData({...updtPopupData, zoneName: item, zoneNumber: item2});

  }

  function handleUpdtStoreZnValues(e: any, key: any) {
  
    if (key == "startDate") {
      setUpdtZoneObj({ ...UpdtZoneObj, queueData: {...UpdtZoneObj.queueData, zoneStore: {...UpdtZoneObj.queueData.zoneStore, [key]: e}} });
      //insertNewZoneObj.queueData.zoneStore.storeNumber
    }  
    /* if(key=="zoneNumber"){
      const znName = zoneName.filter(
            (value: any, index: any) => {
              zoneName.include(value.zone);
            }
          );
          
    } */

     /* setUpdtPopupData({...updtPopupData, [key]: e.target.value}) */
  if(key == "zoneNumber"){
    const filteredItems:any = zoneDropDown.filter((item: any) => item.zoneNumber === e.target.value)
    console.log("filteredItems", filteredItems[0].zoneName);
    zoneNameFun(filteredItems[0].zoneName, e.target.value);  
    setUpdtZoneObj({ ...UpdtZoneObj, queueData: {...UpdtZoneObj.queueData, zoneStore: {...UpdtZoneObj.queueData.zoneStore, [key]: e.target.value}} });
  }

  console.log("updtPopupData", updtPopupData)

  setUpdtZoneObj({ ...UpdtZoneObj, queueData: {...UpdtZoneObj.queueData, zoneStore: {...UpdtZoneObj.queueData.zoneStore, [key]: e.target.value}} });
  
 /*  setUpdtZoneObj({ ...UpdtZoneObj, [key]: e.target.value }); */
  }
  console.log("UpdtZoneObj console", UpdtZoneObj);

  //Needs to be removed for disabled attribute.
 
 
//updatestorezone html. 
  function updateStoreZone() {
    return (
      <>
        <Grid container md={12} spacing={2}>
          <Grid style={{ padding: "10px" }} item md={4}>
            <RACSelect
              isDisabled={true}
              inputLabel="Store"
              options={storeNumber}
              defaultValue={updtPopupData.storeNumber ? updtPopupData.storeNumber : ["0"]}
            ></RACSelect>
          </Grid>
          <Grid style={{ padding: "10px" }} item md={4}>
            <RACSelect
              required
              options={zoneValue}
              defaultValue={updtPopupData.zoneNumber ? updtPopupData.zoneNumber : "0"}
              inputLabel="zone"
              onChange={(e) => handleUpdtStoreZnValues(e, "zoneNumber")}
            ></RACSelect>
          </Grid>
          <Grid style={{ padding: "10px" }} item md={4}>
            <RACTextbox
              disabled={true}
              value={updtPopupData.zoneName}
              inputlabel="Zone Name"
            ></RACTextbox>
          </Grid>
          <Grid style={{ padding: "10px" }} item md={4}>
            <RACTextbox
              disabled={true}
              value={updtPopupData.companyName}
              inputlabel="Company Name"
            ></RACTextbox>
          </Grid>
          <Grid style={{ padding: "10px" }} item md={4}>
            <RACTextbox
              disabled={true}
              value={updtPopupData.companyCode}
              inputlabel="Company Code"
            ></RACTextbox>
          </Grid>
          <Grid style={{ padding: "10px" }} item md={4}>
            <RACTextbox
              disabled={true}
              value={updtPopupData.storeType}
              inputlabel="Store Type"
            ></RACTextbox>
          </Grid>
          <Grid style={{ padding: "10px" }} item md={4}>
            <RACTextbox
              disabled={true}
              value={updtPopupData.stateCode}
              inputlabel="State"
            ></RACTextbox>
          </Grid>
          <Grid item md={4}>
            <RACDatePicker
            required
            inputProps={{min: moment().format("YYYY-MM-DD")}}
              value={/* String(new Date(updtPopupData.startDate)) */ moment(updtPopupData.startDate).format('YYYY-MM-DD')}
              onChange={(e) => {
                handleUpdtStoreZnValues(e, "startDate");
              }}
              label="Start Date"
            />
          </Grid>
          <Grid
                  spacing={3}
                  item
                  xs={12}
                  md={12}
                  style={{ marginTop: "0.5%", marginLeft: "2% !important" }}
                >
                  <Typography>
                    {" "}
                    How do you want to update the storezone?
                    <span style={{ color: "red" }}>*</span>{" "}
                  </Typography>
                  <Grid
                    style={{
                      margin: "1%",
                    }}
                  >
                    <RACRadio
                      value={""}
                      checked={selectQueueType == "IMMEDIATE" ? true : false}
                      label="Immediately"
                      name="updateType"
                      onClick={() => setSelectQueueType("IMMEDIATE")}
                    ></RACRadio>
                    <RACRadio
                      value={""}
                      checked={selectQueueType == "OVERNIGHT" ? true : false}
                      label="Overnight"
                      name="updateType"
                      onClick={() => setSelectQueueType("OVERNIGHT")}
                    ></RACRadio>
                  </Grid>
                </Grid>
          <Grid style={{ height: "69px" }} item md={12}>
            <Grid style={{ float: "right" }}>
              <RACButton
                variant="outlined"
                color="primary"
                onClick={() => {
                  setUpdateStoreZonePopup(false);
                  setSelectQueueType("")
                }}
                style={{ float: 'left', marginBottom: "8px", marginRight: "5px", paddingTop: "8px"}}
              >
                Cancel
              </RACButton>
              <RACButton
                disabled={((UpdtZoneObj.queueData.zoneStore.zoneNumber == "0" || UpdtZoneObj.queueData.zoneStore.zoneNumber == null)|| UpdtZoneObj.queueData.zoneStore.storeNumber == "0" || UpdtZoneObj.queueData.zoneStore.startDate == "" || UpdtZoneObj.queueData.zoneStore.startDate == "MM-DD-YYYY" || selectQueueType == "") ? true : false}
                variant="contained"
                color="primary"
                onClick={() => {
                  clickingNext(),
                setUpdateStoreZonePopup(false)
                }}
                style={{ color: "white", marginBottom: "10px" }}
              >
                Next
              </RACButton>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }

  //updatestorezone api call
async function clickingNext(){
console.log("updtPopupData console to check if all the datas are updated", UpdtZoneObj)
setLoader(true)
debugger
  let rmsAffectedCount: any
  RMSObj.find((item3: any) => {
    console.log("zoneNumberForRmsCount inside the rmsObjfind method", zoneNumberForRmsCount)
    if(item3.zoneNumber == zoneNumberForRmsCount){
      console.log("zoneNumberForRmsCount", zoneNumberForRmsCount)
      rmsAffectedCount = item3.rmsItemCount
    }
  })
  setRmsAffectedCount(rmsAffectedCount)
  console.log("rmsAffectedCount for new storezone creation", rmsAffectedCount)
  let copyUpdtZoneObj = JSON.parse(JSON.stringify(UpdtZoneObj))
  copyUpdtZoneObj.schedule = selectQueueType
const responseFromInsertZoneValue:any = await insertZoneValue(copyUpdtZoneObj)
if(responseFromInsertZoneValue.status==200){
  console.log("responseFromInsertZoneValue", responseFromInsertZoneValue)
  setPricingQueueId(responseFromInsertZoneValue?.data?.pricingQueueId)
  setSumbittedPopup(true);
  setUpdateStoreZonePopup(false);
  newStoreZoneClear();
  console.log("insertNewZoneObj.queueData.zoneStore.zoneNumber", UpdtZoneObj.queueData.zoneStore.zoneNumber)
  /* if(UpdtZoneObj.queueData.zoneStore.zoneNumber){
    const rms = RMSObj.filter(
      (value: any, index: any) => {
        RMSObj.include(updtPopupData.zoneNumber);
      }
    );
    console.log("checking if the rms value is assigned", rms)
    setRmsAffected(rms);
  } */
}
else if(responseFromInsertZoneValue.status==400){
  setErrorMsgStoreZone(responseFromInsertZoneValue.data.errors[0].error);
  setGridErrorPopup(true);
}  
else {
  setErrorMsgStoreZone("Something Went Wrong");
  setGridErrorPopup(true);
}
setLoader(false);
}


async function clickingNextNewZone(){
  console.log("newStoreZone creating payload insertNewZoneObj", insertNewZoneObj)
  setLoader(true)
  const responseFromGetZoneDd: any = await getZoneDD()
  console.log("responseFromGetZoneDd.data.result", responseFromGetZoneDd.data.result)
  console.log(insertNewZoneObj.queueData.zoneStore.storeNumber, "for finding the store")
  debugger
  let storeInZone: any
  const zoneNumberRmsCount: any = responseFromGetZoneDd.data.result.filter((item: any, index: any) => {
    console.log("item.store for the item.store", item.store)
    item.store.find((item2: any) => 
    {
      console.log("item2.storeNumber", item2)
     if(item2 == insertNewZoneObj.queueData.zoneStore.storeNumber){
      console.log("item2.storeNumber", item2)
      storeInZone = item.zone
     } 
    }) 
  })

  let rmsAffectedCount: any
  RMSObj.find((item3: any) => {
    if(item3.zoneNumber == storeInZone){
      rmsAffectedCount = item3.rmsItemCount
    }
  })
  setRmsAffectedCount(rmsAffectedCount)
  console.log("rmsAffectedCount for new storezone creation", rmsAffectedCount)
  let copyInsertNewZoneObj = JSON.parse(JSON.stringify(insertNewZoneObj))
  copyInsertNewZoneObj.schedule = selectQueueType
  const responseFromInsertZoneValue:any = await insertZoneValue(copyInsertNewZoneObj)
  
  if(responseFromInsertZoneValue.status==200){

    console.log("responseFromInsertZoneValue", responseFromInsertZoneValue)
    setPricingQueueId(responseFromInsertZoneValue?.data?.pricingQueueId)
    setSumbittedPopup(true);
    setNewStoreZonepopup(false);// for closing the newzoepopup
    newStoreZoneClear();
    console.log("insertNewZoneObj.queueData.zoneStore.zoneNumber", insertNewZoneObj.queueData.zoneStore.zoneNumber)
    /* if(insertNewZoneObj.queueData.zoneStore.zoneNumber){
      const rms =RMSObj.filter(
        (value: any, index: any) => {
          value.include(insertNewZoneObj.queueData.zoneStore.zoneNumber);
        }
      );
      console.log("checking if the rms value is assigned", rms)
      setRmsAffected(rms)
    }
     */
  }
  else if(responseFromInsertZoneValue.status==400){
    setErrorMsgStoreZone(responseFromInsertZoneValue.data.errors[0].error);
    setGridErrorPopup(true);
  }
  else {
    setErrorMsgStoreZone("Something Went Wrong");
    setGridErrorPopup(true);
  }
  setLoader(false);   
}

const newStoreZoneClear = () => {
  setInsertNewZoneObj({ ...insertNewZoneObj, queueData: {...insertNewZoneObj.queueData, zoneStore: {...insertNewZoneObj.queueData.zoneStore, storeNumber: "", zoneNumber: "", startDate: "" }}});
  setSelectQueueType("")
}


//newstorezone api call
  function newStoreZone() {
    return (
      <>
        <Grid container md={12} spacing={2}>
          <Grid item md={4}>
            <RACSelect
              required
              isDisabled={false}
              inputLabel="Store"
              options={storeNumber}
              defaultValue={insertNewZoneObj.queueData.zoneStore.storeNumber ? insertNewZoneObj.queueData.zoneStore.storeNumber : "0"}
              onChange={(e) => {
                handleNewZoneValues(e, "storeNumber");
              }}
            ></RACSelect>
          </Grid>
          <Grid item md={4}>
            <RACSelect
              required
              isDisabled={false}
              inputLabel="Zone"
              options={zoneValue}
              defaultValue={insertNewZoneObj.queueData.zoneStore.zoneNumber ? insertNewZoneObj.queueData.zoneStore.zoneNumber: "0"} 
              onChange={(e) => {
                handleNewZoneValues(e, "zoneNumber");
              }}
            ></RACSelect>
          </Grid>
          <Grid item md={4}>
            <RACDatePicker
              onChange={(e) => {
                handleNewZoneValues(e, "startDate");
              }}
              inputProps={{min: moment().format("YYYY-MM-DD")}}
              required
              label="Start Date"
              value={insertNewZoneObj.queueData.zoneStore.startDate}
            />
          </Grid>
          <Grid
                  spacing={3}
                  item
                  xs={12}
                  md={12}
                  style={{ marginTop: "0.5%", marginLeft: "2% !important" }}
                >
                  <Typography>
                    {" "}
                    How do you want to update the storezone?
                    <span style={{ color: "red" }}>*</span>{" "}
                  </Typography>
                  <Grid
                    style={{
                      margin: "1%",
                    }}
                  >
                    <RACRadio
                      value={""}
                      checked={selectQueueType == "IMMEDIATE" ? true : false}
                      label="Immediately"
                      name="updateType"
                      onClick={() => setSelectQueueType("IMMEDIATE")}
                    ></RACRadio>
                    <RACRadio
                      value={""}
                      checked={selectQueueType == "OVERNIGHT" ? true : false}
                      label="Overnight"
                      name="updateType"
                      onClick={() => setSelectQueueType("OVERNIGHT")}
                    ></RACRadio>
                  </Grid>
                </Grid>
          <Grid style={{ height: "69px" }} item md={12}>
            <Grid style={{ float: "right" }}>
              <RACButton
                onClick={() => {setNewStoreZonepopup(false), newStoreZoneClear()}}
                variant="outlined"
              color="primary"
              style={{ float: 'left', marginBottom: "8px", marginRight: "5px", paddingTop: "8px"}}
              
              >
                Cancel
              </RACButton>
              <RACButton
                disabled={(insertNewZoneObj.queueData.zoneStore.zoneNumber == "0" || insertNewZoneObj.queueData.zoneStore.storeNumber == "0" || insertNewZoneObj.queueData.zoneStore.startDate == "" || selectQueueType == "") ? true : false}
                variant="contained"
                color="primary"
                onClick={() => {
                  clickingNextNewZone();
                  setNewStoreZonepopup(false)
                  
                  /* setCall2stepAuth(true); */// commented by rajesh CHANGED
                }}
                style={{ color: "white", marginBottom: "10px" }}
              >
                Next
              </RACButton>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }

  //PageClick functionality
  const handlePageClick = (event: any) => {
  
     setpginitial(event.selected  );
    console.log("event.selected", event.selected)
    scrollTop;
    console.log(event.selected + 1,"Tje value dfawfnol");
    console.log("page initial checking if the state is setted", pginitial)
    filterValueObj.offset = (event.selected + 1).toString();
    console.log("event", event)
    console.log("page initial", pginitial);
    applyFilter();
  };
 
  const scrollTop = (ref: any) => {
    if (ref) {
      const element = ref;
      console.log("elementsss", element);
      const clientWidth = element.getBoundingClientRect().height;
      console.log("clientWidth refAssignCallback", clientWidth);
      const negCal = clientWidth * 2;
      const negVal = clientWidth - negCal;
      window.scrollBy(0, negVal);
    }
  };

  //approval popup after setting the state variable with the id got from inseting the new store to the zone. so need to set true after setting the state with the id to call the popup. 
  const approvalPopup = () => {
    return (
      <>
        <Grid>
          <Grid style={{ textAlign: "center", marginBottom: "2%" }}>
            <SuccessIcon />
          </Grid>
          <Grid>
            <Typography
              variant="h5"
              style={{ textAlign: "center", marginBottom: "2%" }}
            >
              {" "}
              Request has been submitted
            </Typography>
            <Typography variant="h5" style={{ textAlign: "center", marginBottom: "15px" }}>
              Do you want to continue for approval?
            </Typography>
            <span style={{textAlign: "center", color: "#c9202f", fontSize: "small", padding: "0px 0px 0px 50px"}}><span style={{fontWeight: "bolder", color: "#c9202f" }}>Note:</span> The updated StoreZone will affect {rmsAffectedCount != undefined ? rmsAffectedCount: "0"} RMS #.</span>
          </Grid>
          <Grid
            style={{ display: "flex", justifyContent: "center", margin: "5%" }}
          >
            <RACButton
              style={{ marginRight: "10%" }}
              variant="outlined"
              onClick={() => {
                setSumbittedPopup(false), newStoreZoneClear(), setLoader(false)
              }}
            >
              No
            </RACButton>
            <RACButton
              variant="contained"
              color="primary"
              onClick={() => {
                setIsApprovalOpen(false);
                setLoader(false)
                setSumbittedPopup(false)
                setAuthOpen(true)
              }}
            >
              Yes
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };


  //rendering the recordData and pagination
  const renderRecordData = () => {
    return (
      <>
        <Grid className={`${classes.mb0} ${classes.textRight}`} style={{marginTop: "32px"}}>
          <Grid className={`${progressclasses.totrec}`} >
          { !totRecord ? null : <Typography >
              {/* # of Records: {pageCount - pginitial == 0 ? (totRecord) : pginitial *25  
              /*  totRecord - (pginitial* 25) <  25 ? (pginitial *25) : totRecord - (pginitial* 25)  }/{totRecord}{" "} */}
              # of Records:&nbsp;
                    {pginitial == 0
                      ? totRecord < 25 ? totRecord :25 * 1
                      : (pginitial+1) == pageCount
                      ? totRecord
                      : (pginitial + 1) * 25}
                    /{totRecord}
            </Typography>}
          </Grid>
          {totRecord ?<ReactPaginate
            nextLabel=">"
            onPageChange={(num: any) => {
              // setZoneDetailsState({...zoneDetailsState, ['offset'] : `${(num.selected + 1 )}`})
              handlePageClick(num)
            }
            }
              initialPage= {pginitial}
            pageCount={pageCount} // pageCount needs to be set here.
            pageRangeDisplayed={2}
            marginPagesDisplayed={0}
            previousLabel="<"
            pageClassName={progressclasses.pagination}
            pageLinkClassName={progressclasses.pagelink}
            previousLinkClassName={
              pginitial === 0
                ? progressclasses.paginationprevnextdisable
                : progressclasses.paginationprevnext
            }
            disableInitialCallback={true}
            nextLinkClassName={
               pginitial === pageCount - 1
                ? progressclasses.paginationprevnextdisable
                : progressclasses.paginationprevnext
            }
            breakLabel=""
            breakClassName={progressclasses.pagination}
            breakLinkClassName={progressclasses.pagination}
            containerClassName={progressclasses.pagination}
            activeClassName={progressclasses.pageactive}
            forcePage={pginitial === 0 ? 0 : undefined}
          /> : null}
        </Grid>
      </>
    )
  }

  function handleNewZoneValues(e: any, key: any) {
    console.log("e", e);
    if (key == "startDate") {
      setInsertNewZoneObj({ ...insertNewZoneObj, queueData: {...insertNewZoneObj.queueData, zoneStore: {...insertNewZoneObj.queueData.zoneStore, [key]: e }}});
    }
    setInsertNewZoneObj({ ...insertNewZoneObj, queueData: {...insertNewZoneObj.queueData, zoneStore: {...insertNewZoneObj.queueData.zoneStore, [key]: e.target.value }}});
    console.log("This is for the newzonevalue", e.target.value);
    console.log("This is for the newzonevalue in the ", insertNewZoneObj);
  }

  function somethingWentWrongShow() {
    return (
      <>
        <Grid>
          {true ? (
            <Grid style={{ position: "relative", top: "-24px" }}>
              <Grid className={progressclasses.successPopup}>
                <SomethingWrong style={{ position: "relative", top: "13px" }} />
              </Grid>
              <Grid
                className={`${progressclasses.textcenterImage} ${classes.fontBold}`}
              >
                {errorMsgStoreZone}
              </Grid>
              <Grid className={`${progressclasses.loader} ${classes.mt2}`}>
                <RACButton
                  style={{ position: "relative", top: "10px" }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setGridErrorPopup(false);//CHANGED
                  }}
                >
                  OK
                </RACButton>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </>
    );
  }

  const [menuConfigArray, setMenuConfigArray] = useState<any>([]);

  
  return (
    <>
        <Grid
          style={{ /* backgroundColor: "red",  */width: "100%", height: "auto", paddingTop: "0.75rem", marginBottom: "60px" }}
        >
          <Grid style={{
            position: "relative",
            top: "10px",
              width: "100%",
              left: "0",
              zIndex: "1"
            }}>

          <Typography
            style={{ float: "left", position: "relative", top: "12px" }}
            variant="h4"
          >
            StoreZone Details
          </Typography>
          <RACButton
            variant="contained"
            color="primary"
            onClick={() =>
             { filterPopup == true ? setFilterPopup(false) : setFilterPopup(true)}
            }
            style={{ float: "right", color: "white", marginBottom: "10px" }}
          >
            <RacFilter></RacFilter>
            <Typography
              style={{ position: "relative", left: "6px" }}
              variant="h6"
            >
              Filter
            </Typography>
            {filterPopup == true ? (
              <UpArrow style={{ marginLeft: "10px" }} />
            ) : (
              <DownArrow style={{ marginLeft: "10px" }} />
            )}
          </RACButton>
          </Grid>
          {filterPopup == true ?(openFilterStoreZone()) : null}
          {enableGrid == true ? (
            <Card
              style={{ width: "100%", borderRadius: "12px", marginTop: filterPopup ? "5px" : "77px",  marginBottom: "5%"}}
            >
              <CardContent style={{paddingBottom: "0px"}}>
                {!noRecordsFound ? (
                <>
                <Grid className={classes.storeZoneDetailGrid} style={{position:'relative'}}>
                      <Grid className={classes.tableCell}>
                        <RACTable
                          renderTableHead={bindGridHead}
                          renderTableContent={buildTableContent}
                        ></RACTable>
                      </Grid>
                    
                    </Grid>
                    {renderRecordData()}
                    </>
                    ) :(
                <Grid>
                <RACTable renderTableHead={rendernorecordsheader} />
                  <Grid item className={progressclasses.textSpaceNorecords} style={{height: "25vh"}}>
                    <Grid className={progressclasses.textcenterNorecords} >
                        <NorecordsIcon className={progressclasses.racErrorIcon} />
                    </Grid>
                    <Grid className={progressclasses.textcenterImage}>
                                    No Records Found !
                    </Grid>
                  </Grid>
                </Grid>)
                }
                    
              </CardContent>
            </Card>
          ) : null}
          <RACModalCard
            isOpen={cantEditPopup}
            maxWidth="xs"
            closeIcon={true}
            borderRadius="20px"
            onClose={() => {setCantEditPopup(false)}}
            children={recordCantEdit()}
            title=""
          />
          <RACModalCard
            isOpen={updateStoreZonePopup}
            maxWidth="sm"
            closeIcon={true}
            borderRadius="20px"
            onClose={() => {setUpdateStoreZonePopup(false), newStoreZoneClear()}}
            children={updateStoreZone()}
            title="Update StoreZone"
          />
          <Card
            style={{
              position: "fixed",
              bottom: "0px",
              width: "100%",
              zIndex: "1",
              left: "0",
            }}
          >
            <CardContent style={{padding:'1% !important'}}>
              <Grid style={{ float: "right" }}>
                <RACButton
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ bottom: "7px" }}
                  disabled={canAccess ? false  : true }
                  onClick={() => {
                    console.log(validateApprover(menuConfigArray,'StoreZone'),"The sotezone");
                    
                    if(validateApprover(menuConfigArray,'StoreZone'))
                    setNewStoreZonepopup(true);
                  else 
                    {// alert('hi')
                    setErrorMsgStoreZone("Access to this feature is currently restricted to this user");
                    setGridErrorPopup(true);}
                  }}
                >
                  New StoreZone
                </RACButton>
              </Grid>
            </CardContent>
          </Card>
          <RACModalCard
            isOpen={newStoreZonepopup}
            maxWidth="sm"
            closeIcon={true}
            borderRadius="20px"
            onClose={() => {setNewStoreZonepopup(false), newStoreZoneClear()}}
            children={newStoreZone()}
            title="New StoreZone"
          />
          <RACModalCard
            isOpen={submittedPopup}
            maxWidth="xs"
            closeIcon={true}
            borderRadius="20px"
            onClose={() =>{ setSumbittedPopup(false), setLoader(false)}}
            children={approvalPopup()}
            title=""
          />
          <RACModalCard
            isOpen={sucessPopup}
            maxWidth="xs"
            closeIcon={false}
            borderRadius="10px"
            onClose={() => setSuccessPopup(false)}
            children={renderOkPopup()}
          />
          
          <RACModalCard //For showing all the errors in the storezone. 
            isOpen={gridErrorPopup}
            maxWidth="xs"
            closeIcon={false}
            borderRadius="20px"
            onClose={() => {setGridErrorPopup(false)}}
            children={somethingWentWrongShow()}
          />
          {/* {call2stepAuth == true ? (
            <TwoStepAuthentication submitted={true} />
          ) : null} */}
        </Grid>
        {loader === true ? (
        <Grid
          className={progressclasses.masterLoader}
        >
          <Grid
           className={progressclasses.Loader}
          >
            <CircularProgress />
          </Grid>
        </Grid>
      ) : null }

<TwoFactorAuthentication
              IsHigherAuthority={isApprover} isVisible={authOpen} setIsVisible={setAuthOpen} loader={loader}setloader={setLoader} setTwoFactorCompleted={setIsSLACompleted} setTwoFactorCancelClick={setIsSLACancel} twoFactorCompleted={isSLACompleted} twoFactorCancelClick={isSLACancel} setSomethingWentWrong={setGridErrorPopup} mailId={emailId} employeeId={employeeId} submitedBy ={sessionStorage.getItem('userName')}  ApprovedBy={setApprovedBy} />
    </>
  );
}
