import React, { useState, useContext } from 'react';
import { useEffect } from 'react';
import { ProgressBar } from './Styles/ProgressStyles';
import { ReactComponent as RacFilter } from '../../assets/images/RacSort.svg';
import { ReactComponent as FilterClose } from '../../assets/images/filter-close-icon.svg';
import { ReactComponent as SortdownIcon } from '../../assets/images/sort-down-icon.svg';
import { ReactComponent as SortupIcon } from '../../assets/images/sort-icon-up.svg';
import { ReactComponent as ExcelImg } from '../../assets/images/excel.svg';
import { ReactComponent as SomethingWrong } from '../../assets/images/no-records-found.svg';
import { ReactComponent as Success } from '../../assets/images/success-icon.svg';
import { ReactComponent as UploadClose } from '../../assets/images/uploadclose.svg';
import { useHistory } from 'react-router';
import { ReactComponent as SearchbarIcon } from '../../assets/images/searchbarIcon.svg';
import { ReactComponent as WaitingIcon } from '../../assets/images/WaitingIcon.svg';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { globalStyles } from './Styles/globalstyles';
import { PricingReportStyle } from './Styles/PricingReport';
import { ReactComponent as NorecordsIcon } from '../../assets/images/No-records.svg';
import axios, { AxiosRequestConfig } from 'axios';
import {
  getUserID,
  getUploadGridData,
  pricingDocumnetUpload,
  getXlFile,
  getZoneDD,
} from '../../api/user';
import ReactPaginate from 'react-paginate';
/* eslint-disable */
import Tooltip from "@material-ui/core/Tooltip";
import { ContainerContext } from '../../app/App';
import { CustomPropInterface } from '../../index';
import { ZoneRecordsCount, ZoneStoreResponse, zoneStoreData } from '../../components/shared/interface/PricingModels';
import * as consts from '../../components/shared/constants';
import {
  RACTableCell,
  RACTable,
  CircularProgress,
  RACTableRow,
  Grid,
  Card,
  CardContent,
  RACButton,
  RACTextbox,
  Typography,
  RACSelect,
  RACDatePicker,
  RACInfoIcon,
  RACModalCard,
  RACRadio, RACCheckBox, Link, RACTooltip
} from '@rentacenter/racstrap';
import { EXCEL_COLUMN_HEADER, EXCEL_COLUMN_VALUES, VERIFY_IN_PROG } from '../../constants/constants';
export const sortDirection = {
  ASC: 'asc',
  DESC: 'desc'
}
export const headcolumns = [
  {
    columnId: 'documentName',
    sortOrder: sortDirection.ASC,
    columnTitle: 'Document Name',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'uploadedBy',
    sortOrder: sortDirection.ASC,
    columnTitle: 'Uploaded by',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'type',
    sortOrder: sortDirection.ASC,
    columnTitle: 'Type',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'subtype',
    sortOrder: sortDirection.ASC,
    columnTitle: 'Sub Type',
    IsSorted: false,
    IsSortable: false,
  },
  {
    columnId: 'dateandTime',
    sortOrder: sortDirection.ASC,
    columnTitle: 'Date & Time',
    IsSorted: true,
    IsSortable: true,
  },
  {
    columnId: 'Progress',
    // sortOrder: sortDirection.ASC,
    columnTitle: 'Progress',
    IsSorted: false,
    IsSortable: false,
  },
  {
    columnId: 'status',
    sortOrder: sortDirection.ASC,
    columnTitle: 'Status',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'Action',
    // sortOrder: sortDirection.ASC,
    columnTitle: 'Action',
    IsSorted: false,
    IsSortable: false,
  }
]
export interface columns {
  columnId: string,
  sortOrder: string,
  columnTitle: string,
  IsSorted: boolean,
  IsSortable: boolean,
  columnType?: 'link',
  elipse?: boolean,
  handleColumnClick1?: () => void,
  handleColumnClick2?: () => void,
  subColumns?: columns[],
  index?: string,
  isAction?: boolean
}


export function PricingUpload() {
  const { ZONE_INDEX, TYPE_INDEX, DEPARTMENT_INDEX, SUB_DEPARTMENT_INDEX, BRACKET_INDEX, RMS_ITEM_NUMBER_INDEX, WEEKLY_RATE_NEW_INDEX, WEEKLY_RATE_USED_INDEX, ADDON_WEEKLY_RATE_INDEX, TERM_INDEX, CASH_PRICE_FACTOR_INDEX, FORCED_CASH_PRICE_INDEX, MANUALPO_TURNS_INDEX, START_DATE_INDEX, END_DATE_INDEX, VALIDATION_STATUS_INDEX, VALIDATION_COMMENTS_INDEX, UPLOAD_STATUS_INDEX, UPLOAD_COMMENTS_INDEX } = EXCEL_COLUMN_VALUES
   const {
  ZONE ,
  TYPE,
  DEPARTMENT,
  SUB_DEPARTMENT ,
  BRACKET,
  RMS_ITEM_NUMBER,
  WEEKLY_RATE_NEW,
  WEEKLY_RATE_USED ,
  ADDON_WEEKLY_RATE,
  TERM ,
  CASH_PRICE_FACTOR,
  FORCED_CASH_PRICE ,
  MANUAL_PO_TURNS,
  START_DATE ,
  END_DATE ,
  VALIDATION_STATUS ,
  VALIDATION_COMMENTS ,
  UPLOAD_STATUS,
  UPLOAD_COMMENTS 
  }  =EXCEL_COLUMN_HEADER
  const history = useHistory()
  const classes = globalStyles();
  const Pricingrep = PricingReportStyle();
  const progressclasses = ProgressBar();
  const uploadPricingReqModel = {
    documentName: '',
    uploadedBy: '',
    type: '',
    subtype: [],
    status: '',
    documentKey: ''
  }
  const [Griddata, setGriddata] = useState<any>([])
  const [newPendingcheckarray, setnewPendingcheckarray] = useState<any>([])
  const [Tabledata, setTabledata] = useState<any>([])
  const [headerdata, setHeaderData] = useState(headcolumns)
  const [popup, setPopup] = useState('');// for upload popup
  const [type, settype] = useState('')
  const [filename, setFilename] = useState('');// showing file name near  upload button
  const [subtype, setSubtype]: any = useState([]);//store subtypes
  const [uploadfile, setuploadfile]: any = useState();
  const [ValidationErrorMsg, setValidationErrorMsg] = useState('');// for upload popup
  const [errormsg, seterrormsg] = useState('');  //State variable for error popup
  const [errormsgTo, seterrormsgTo] = useState('');
  const filterobjectmodel = { Status: '', From: '', To: '' }
  const [filterobject, setFilterObject] = useState<any>(filterobjectmodel)//filter object
  const [uploadPricingReq, setuploadPricingReq] = useState<any>(uploadPricingReqModel)//filter object
  const [Downloadloader, setDownloadloader] = useState('-1');
  const [masterLoader, setMasterLoader]: any = React.useState(false);
  const [currentItems, setCurrentItems] = useState<any>([]);//to set the total pages
  const [pageCount, setpageCount] = useState(0);//dividing the data
  const [itemOffset, setItemOffset] = useState(0);//Page OutOff
  const [documentName, setDocumentName] = useState('')
  const [outoffrec, setoutoffrec] = useState(0);
  const [totrecord, settotrecord] = useState(0);
  const [fromdt, setfromdt] = useState('');
  const [pginitial, setpginitial] = useState(-1);
  const [payloadCountLimit, setpayloadCountLimit] = useState(0);//For deciding whether to process the file immediately or overnight

  let newuploadPricingReq: any;
  let validationStatusFlag: string;
  let total: any, successPercent: any, failPercent: any;
  const zoneRecordsCountArray : ZoneRecordsCount[]= [];

  const statusobject = [
    { value: '', label: 'Select' },
    { value: 'Completed', label: 'Completed' },
    { value: 'Failed', label: 'Failed' },
    { value: 'Partially Completed', label: 'Partially Completed' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Validation Failed', label: 'Validation Failed' },
    {value: VERIFY_IN_PROG, label: VERIFY_IN_PROG},
    { value: 'Scheduled', label: 'Scheduled' }
  ];

  const containerData = useContext(ContainerContext) as CustomPropInterface;
  useEffect(() => {
    console.log('inside useeffect 1')
    const PayLoad = async () => {
      setMasterLoader(true);
      let uploadSummaryReq: any;
      let gridresponse: any;
      let empName;
      if (containerData?.GetName() != undefined) {
        console.log('containerData,....', containerData)
        console.log('containerData,....GetRole', containerData.GetRole())
        console.log('containerData,....GetEmployeeId', containerData.GetEmployeeId())
        console.log('containerData,....GetName', containerData.GetName())
        console.log('containerData,....GetEmailId', containerData.GetEmailId())
        empName = containerData.GetName()
      }
      else {
        const currentuserresponse = await getUserID();
        if (currentuserresponse.status == 200) {
          empName = currentuserresponse.data.firstName + currentuserresponse.data.lastName
        }
        else {
          setMasterLoader(false)
          setPopup('curentUserresponsefail');
        }
      }
      if (empName !== undefined) {
        console.log("uploadSummaryReq of getCurrent user Api", uploadSummaryReq);
        //Getting the Grid response
        gridresponse = await getUploadGridData();
        console.log('gridresponse', gridresponse);
        if (gridresponse.status == 200) {
          setuploadPricingReq({
            ...uploadPricingReq,
            uploadedBy: empName,
          });
          if (gridresponse.data != '') {
            let Griddataa = gridresponse.data.result;
            setpayloadCountLimit(Number(gridresponse.data.payloadCountLimit));
            const datearray = Griddataa.filter((obj: any) => {
              return (new Date(obj.date).getMonth() + '/' + new Date(obj.date).getDate() + '/' + new Date(obj.date).getFullYear());
            })
            datearray.sort((a: any, b: any) => a.dateandTime > b.dateandTime ? -1 : 1)
            setGriddata(datearray);
            setTabledata(datearray);
            const itemsPerPage = 25;
            const endOffset = itemOffset + itemsPerPage;
            console.log(`Loading items from ${itemOffset} to ${endOffset} in useEffect1`);
            console.log("table dataaaaaaa", Tabledata)
            setCurrentItems(datearray.slice(itemOffset, endOffset));
            console.log("currentItems first UseEffect", currentItems);
            setMasterLoader(false);
            setpginitial(0);
            console.log('datearray', datearray)
          }
          else {
            setGriddata([]);
            setTabledata([]);
            setMasterLoader(false);
          }
        }
        else {
          setMasterLoader(false);
          setPopup('uploadResponseFail');
        }

      }
      else {
        setMasterLoader(false);
        setPopup('curentUserresponsefail');
      }
    }
    PayLoad();
  }, [])

  useEffect(() => {
    const pagefn = () => {
      const itemsPerPage = 25;
      const endOffset = itemOffset + itemsPerPage;
      if (endOffset > Tabledata.length) {
        setoutoffrec(Tabledata.length);
      }
      else {
        const endset = endOffset % Tabledata.length;
        setoutoffrec(endset);
      }
      console.log(`Loading items from ${itemOffset} to ${endOffset} in useEffect 2`);
      console.log("table dataaaaaaa", Tabledata)
      settotrecord(Tabledata.length);
      setCurrentItems(Tabledata.slice(itemOffset, endOffset));
      console.log('Current Items data in useEffect2', currentItems);
      setpageCount(Math.ceil(Tabledata.length / 25));
    }
    const noRecfn = () => {
      settotrecord(Tabledata.length);
      setoutoffrec(Tabledata.length);
      setpageCount(0);
    }
    if (Tabledata.length != 0) {
      pagefn();
    }
    else {
      noRecfn();
    }

  }, [itemOffset, Tabledata]);

  const gridloadapi = async () => {
    let gridresponse = await getUploadGridData();
    if (gridresponse.status == 200) {
      console.log("Grid Resposne of PricingUpload", gridresponse);
      console.log("Grid Resposne ", gridresponse.data);
      if (gridresponse.data != '') {
        console.log("Grid Resposne ", gridresponse.data.result[0].documentName);
        let Griddataa = gridresponse.data.result;
        const datearray = Griddataa.filter((obj: any) => {
          return (new Date(obj.date).getMonth() + '/' + new Date(obj.date).getDate() + '/' + new Date(obj.date).getFullYear());
        })
        datearray.sort((a: any, b: any) => a.dateandTime > b.dateandTime ? -1 : 1)
        setGriddata(datearray);
        setTabledata(datearray);
        setMasterLoader(false);
        // setPopup('');
        console.log('datearray', datearray)
      }
      else {
        setGriddata([]);
        setTabledata([]);
        setMasterLoader(false);
      }
    }
    else {
      setMasterLoader(false);
      setPopup('uploadResponseFail');
    }
  }
  const uploadDocumentAPI = async (newkey: any, validationsStatus: any, s3uploadkey: any, newarrayexcel: any) => {
    newuploadPricingReq = uploadPricingReq;
    newuploadPricingReq.type = type;
    newuploadPricingReq.subtype = subtype;
    newuploadPricingReq.status = validationsStatus;
    newuploadPricingReq.documentKey = newkey;
    console.log('inside uploadDocumentAPI ');
    console.log('inside useeffect 2 payload');
    console.log('newuploadPricingReq', newuploadPricingReq);
    const uploadResponse = await pricingDocumnetUpload(newuploadPricingReq)
    console.log('Pricing upload response', uploadResponse);
    if (uploadResponse.status == 200) {
      const getnewurlresponse = await getXlFile(s3uploadkey);
      console.log('getnewurlresponse', getnewurlresponse);
      if (getnewurlresponse.status == 200) {
        const presignedUploadUrl = getnewurlresponse.data.generatedUrl;
        var config: AxiosRequestConfig = {
          method: 'PUT',
          url: presignedUploadUrl,
          data: newarrayexcel
        };
        console.log('config', config)
        const uploadins3response = await axios(config)
          .catch(function (error) {
            console.log('error', error);
          });
        console.log('uploadins3response', uploadins3response);
        setPopup('uploadResponseSuccess');
      }
      else {
        setMasterLoader(false);
        setPopup('somethigwentwrong');
      }
    }
    else {
      seterrormsg(uploadResponse.data.errors[0].error);
      setMasterLoader(false);
      setPopup('uploadResponse400');
      console.log('uploadResponse in 400', uploadResponse);
      console.log('uploadResponse in 400', uploadResponse.data.errors[0].error);
    }
  }

  const filterbyparams = () => {

    if (filterobject.Status !== null && filterobject.Status !== '' && fromdt == '' && filterobject.To == '') {
      const arry = Griddata.filter((el: any) => {
        return el.status == filterobject.Status
      })
      const sortarrays = arry.sort((a: any, b: any) => a.dateandTime < b.dateandTime ? -1 : 1);
      setTabledata(sortarrays);
      if (headerdata.map((value: any) => {
        value.columnId == 'dateandTime'
        value.sortOrder = sortDirection.DESC
      }))
        setPopup('');
    }
    else if (fromdt !== '' && filterobject.To !== '' && filterobject.Status == '') {
      const arry = Griddata.filter((el: any) => {
        return moment(el.dateandTime).format('YYYY-MM-DD') >= moment(fromdt).format('YYYY-MM-DD') && moment(el.dateandTime).format('YYYY-MM-DD') <= moment(filterobject.To).format('YYYY-MM-DD');
      })
      const sortarrays = arry.sort((a: any, b: any) => a.dateandTime < b.dateandTime ? -1 : 1);
      setTabledata(sortarrays);
      if (headerdata.map((value: any) => {
        value.columnId == 'dateandTime'
        value.sortOrder = sortDirection.DESC
      }))
        setPopup('');
    }
    else if (fromdt !== '' && filterobject.To !== '' && filterobject.Status !== '') {
      const arry = Griddata.filter((el: any) => {
        return (moment(el.dateandTime).format('YYYY-MM-DD') >= moment(fromdt).format('YYYY-MM-DD') && moment(el.dateandTime).format('YYYY-MM-DD') <= moment(filterobject.To).format('YYYY-MM-DD') && el.status == filterobject.Status)
      })
      const sortarrays = arry.sort((a: any, b: any) => a.dateandTime < b.dateandTime ? -1 : 1);
      setTabledata(sortarrays);
      if (headerdata.map((value: any) => {
        value.columnId == 'dateandTime'
        value.sortOrder = sortDirection.DESC
      }))
        setPopup('');
    }
  }

  const sortArray = (columnId: string, index: any, datacolumn: any, dataValues: any) => {
    try {
      const sortedData = datacolumn[index].sortOrder == sortDirection.DESC ?
        dataValues.sort((a: any, b: any) => b[columnId] < a[columnId] ? -1 : 1) :
        dataValues.sort((a: any, b: any) => a[columnId] < b[columnId] ? -1 : 1);
      const reorderColumns: columns[] = datacolumn;
      reorderColumns[index].sortOrder = reorderColumns[index].sortOrder == sortDirection.ASC ? sortDirection.DESC : sortDirection.ASC
      reorderColumns.map((value: any, index1: any) => {
        reorderColumns[index1].IsSorted = index == index1 ? true : false
      });
      setTabledata([...sortedData]);
      setHeaderData([...reorderColumns]);
    }
    catch (error) {
      console.log(error)
    }
  }

  const buildGridHeader = (dataColumns: any, dataValues: any) => {
    return (
      <>
        {headerdata.map((value: any, index: any) => {

          return (
            value.columnTitle == 'Action' ?
              <RACTableCell key={index} className={`${classes.p2} ${classes.textCenter}`}>Action</RACTableCell>
              :
              <RACTableCell key={index} className={classes.p2}>
                {value.IsSortable ?
                  <a style={{ color: 'black', textDecoration: 'none', cursor: 'pointer' }} id={value.columnId}
                    onClick={() => sortArray(value.columnId, index, dataColumns, dataValues)}
                  >
                    {value.columnTitle + "  "}
                    {value.IsSorted === true ?
                      (value.sortOrder == sortDirection.DESC) ? (<SortupIcon className={classes.ml0} />) : <SortdownIcon className={classes.ml0} />
                      : null}
                  </a> :
                  <a
                    style={{
                      color: 'black',
                      textDecoration: 'none',
                    }}
                    id={value.columnId}
                  >
                    {value.columnTitle + '  '}
                  </a>}
              </RACTableCell>
          )
        })}
      </>
    );


  }

  const rendernorecordsheader = () => {
    return (
      <React.Fragment>
        <RACTableCell className={classes.p2}>Document Name</RACTableCell>
        <RACTableCell className={classes.p2}>Uploaded by</RACTableCell>
        <RACTableCell className={classes.p2}>Type</RACTableCell>
        <RACTableCell className={classes.p2}>Subtype</RACTableCell>
        <RACTableCell className={classes.p2}>Date & Time</RACTableCell>
        <RACTableCell className={classes.p2}>Progress</RACTableCell>
        <RACTableCell className={classes.p2}>Status</RACTableCell>
        <RACTableCell className={`${classes.p2} ${classes.textCenter}`}> Action</RACTableCell>
      </React.Fragment>

    )
  }

  let subtyperowindex = 0;
  const renderTableContent = () => {



    if (Tabledata != undefined) {

      return currentItems.map((obj: any, index: any) => {
        if (obj.status == "Partially Completed" || (obj.status == VERIFY_IN_PROG && (obj.failCount))) {
          total = parseInt(obj.failCount) + parseInt(obj.successCount);
          successPercent = Math.trunc((parseInt(obj.successCount) / total) * 100);
          failPercent = Math.trunc(100 - successPercent);
          if (failPercent < 5) { failPercent = 5 }
          if (successPercent < 5) { successPercent = 5 }
          console.log('successcc', successPercent, failPercent)
        }
        return (
          <React.Fragment>
            <RACTableRow className={classes.bgTransparent}>
              <RACTableCell className={classes.p2} title={obj.documentName.length > 20 ? obj.documentName : ''}>
                {obj.documentName.length > 20 ?
                  <Typography variant="caption" className={`${classes.textGrey} ${classes.fs6} ${classes.fontSemiBold}`}>
                    {obj.documentName.slice(0, 20)}...
                  </Typography>
                  :
                  <Typography variant="caption" className={`${classes.textGrey} ${classes.fs6} ${classes.fontSemiBold}`}>
                    {obj.documentName}
                  </Typography>
                }
              </RACTableCell>
              <RACTableCell className={classes.p2}>
                <Typography variant="caption" className={`${classes.textGrey} ${classes.fs6} ${classes.fontSemiBold}`}> {obj.uploadedBy} </Typography>
              </RACTableCell>
              <RACTableCell className={classes.p2}>
                <Typography variant="caption" className={`${classes.textGrey} ${classes.fs6} ${classes.fontSemiBold}`}> {obj.type} </Typography>
              </RACTableCell>
              <RACTableCell className={classes.p2}>
                <Typography variant="caption" className={`${classes.textGrey} ${classes.fs6} ${classes.fontSemiBold}`}>
                  {obj.subType.length > 0 ?
                    obj.subType.sort().join(", ") : '-'}
                </Typography>
              </RACTableCell>
              <RACTableCell className={classes.p2}>
                <Typography variant="caption" className={`${classes.textGrey} ${classes.fs6} ${classes.fontSemiBold}`}>
                  {moment(new Date(obj.dateandTime)).format("MM/DD/YYYY")
                    + '  ' +
                    moment(new Date(obj.dateandTime)).format("hh:mm A")} </Typography>
              </RACTableCell>
              <RACTableCell className={classes.p2}>
              {
                  obj.status == consts.SCHEDULED_STATUS ?
                    <Typography variant="caption" className={`${classes.textGrey} ${classes.fs6} ${classes.fontSemiBold}`}>
                      <Grid className={`${progressclasses.progress} ${progressclasses.bgScheduled}`} style={{ width: "100%" }}>
                      </Grid>
                    </Typography> :
                    ''}
                {
                  obj.status == "Pending" ?
                    <Typography variant="caption" className={`${classes.textGrey} ${classes.fs6} ${classes.fontSemiBold}`}>
                      <Grid className={`${progressclasses.progress} ${progressclasses.bgStriped}`} style={{ width: "100%" }}>
                      </Grid>
                    </Typography> :
                    ''}
                {
                  obj.status == "Validation Failed" ?
                    <Typography variant="caption" className={`${classes.textGrey} ${classes.fs6} ${classes.fontSemiBold}`}>
                      <Grid className={`${progressclasses.progress}`} style={{ width: "100%" }}>
                      </Grid>
                    </Typography>
                    : ''}
                {
                  obj.status == "Completed" ?
                    <Typography variant="caption" className={`${classes.textGrey} ${classes.fs6} ${classes.fontSemiBold}`}>
                      <Grid className={progressclasses.progress}>
                        <Tooltip title={`${obj.successCount}`} placement="top" >
                          <Grid className={`${progressclasses.progressBar} ${progressclasses.bgCompleted}`} style={{ width: "100%" }} ></Grid>
                        </Tooltip>
                      </Grid>
                    </Typography>
                    : ''}
                {
                  obj.status == "Failed" ?
                    <Typography variant="caption" className={`${classes.textGrey} ${classes.fs6} ${classes.fontSemiBold}`}>
                      <Grid className={progressclasses.progress}>
                        <Tooltip title={`${obj.failCount}`} placement="top" >
                          <Grid className={`${progressclasses.progressBar} ${progressclasses.bgFailed}`} style={{ width: `100%` }} ></Grid>
                        </Tooltip>
                      </Grid>
                    </Typography>

                    : ''}
                {

                  obj.status == "Partially Completed" ?
                    <Typography variant="caption" className={`${classes.textGrey} ${classes.fs6} ${classes.fontSemiBold}`}>
                      <Grid className={progressclasses.progress}>
                        <Tooltip title={`${obj.successCount}`} placement="top" >
                          <Grid className={`${progressclasses.progressBar} ${progressclasses.progressBarRightRadius0} ${progressclasses.bgCompleted}`} style={{ width: `${successPercent}%` }} ></Grid>
                        </Tooltip>
                        <Tooltip title={`${obj.failCount}`} placement="top" >
                          <Grid className={`${progressclasses.progressBar} ${progressclasses.progressBarLeftRadius0} ${progressclasses.bgFailed}`} style={{ width: `${failPercent}%` }} ></Grid>
                        </Tooltip>
                      </Grid>
                    </Typography>

                    : ''}
                    {
                     obj.status == VERIFY_IN_PROG ? 
                     ((obj.failCount) > 0 ) ? 
                     <Typography variant="caption" className={`${classes.textGrey} ${classes.fs6} ${classes.fontSemiBold}`}>
                      <Grid className={progressclasses.progress}>
                        <Tooltip title={`${obj.successCount}`} placement="top" >
                          <Grid className={`${progressclasses.progressBar} ${progressclasses.progressBarRightRadius0} ${progressclasses.bgCompleted}`} style={{ width: `${successPercent}%` }} ></Grid>
                        </Tooltip>
                        <Tooltip title={`${obj.failCount}`} placement="top" >
                          <Grid className={`${progressclasses.progressBar} ${progressclasses.progressBarLeftRadius0} ${progressclasses.bgFailed}`} style={{ width: `${failPercent}%` }} ></Grid>
                        </Tooltip>
                      </Grid>
                    </Typography>
                     :
                     <Typography variant="caption" className={`${classes.textGrey} ${classes.fs6} ${classes.fontSemiBold}`}>
                      <Grid className={progressclasses.progress}>
                        <Tooltip title={`${obj.successCount}`} placement="top" >
                          <Grid className={`${progressclasses.progressBar} ${progressclasses.bgCompleted}`} style={{ width: "100%" }} ></Grid>
                        </Tooltip>
                      </Grid>
                    </Typography>
                      : ''

                    }


              </RACTableCell>
              <RACTableCell className={classes.p2}>
                <Typography variant="caption" className={`${classes.textGrey} ${classes.fs6} ${classes.fontSemiBold}`}> {obj.status} </Typography>
              </RACTableCell>
              <RACTableCell title={Downloadloader != obj.documentKey && Downloadloader != '-1' ? 'Please wait while downloading...' : undefined}
                className={`${classes.p2} ${classes.textCenter} ${Downloadloader != obj.documentKey && Downloadloader != '-1' ? `progressclasses.waitcursor` : undefined}`}>
                {obj.status == "Pending" ?
                  <RACButton disabled color="primary" className={classes.bgLightBlue}> Download</RACButton>
                  :
                  Downloadloader != obj.documentKey ?
                    Downloadloader != '-1' ?
                      <RACButton disabled color="primary" className={`${classes.bgLightBlue}`} id={index}>
                        Download
                      </RACButton>
                      :
                      <RACButton color="primary" className={classes.bgLightBlue} id={index}
                        onClick={(event: any) => {
                          getdocumentbydownloadbtn(obj.documentKey);
                          setDownloadloader(obj.documentKey)
                        }
                        }>
                        Download
                      </RACButton> :
                    <CircularProgress className={`${progressclasses.newcircularprogress}`}></CircularProgress>
                }
              </RACTableCell>
            </RACTableRow>
          </React.Fragment>

        );
      })
    }
  }

  const getdocumentbykeyname = async (documentKey: any) => {
    setPopup('');
    setMasterLoader(true);

    console.log("documentKey inside  getdocumentbykeyname", documentKey);
    const key = {
      "key": documentKey,
      "type": "GET"
    }
    const pricingDocumentResponse = await getXlFile(key)
    console.log("RESPOSNE FROM PRICING DOC", pricingDocumentResponse);
    if (pricingDocumentResponse.status == 200) {
      console.log('pricingDocumentResponse', pricingDocumentResponse);
      console.log('pricingDocumentResponse link', pricingDocumentResponse.data.generatedUrl);
      downloadbyurl(pricingDocumentResponse.data.generatedUrl);
      setMasterLoader(false);
    }
    else {
      setMasterLoader(false);
      setPopup('downloadResponseFail');
    }


  }

  const getdocumentbydownloadbtn = async (documentKey: any) => {
    console.log("documentKey inside  getdocumentbykeyname", documentKey);
    const key = {
      "key": documentKey,
      "type": "GET"
    }
    const pricingDocumentResponse = await getXlFile(key);
    console.log("RESPOSNE FROM PRICING DOC", pricingDocumentResponse);
    const link = pricingDocumentResponse.data.generatedUrl;
    if (pricingDocumentResponse.status == 200) {
      const element = document.createElement('a');
      element.setAttribute('href', `${link}`);
      element.setAttribute('style', 'display:none')
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
    else {
      setPopup('nosuchkeyfound');
    }
    setDownloadloader('-1');
  }

  const applyDisabled = () => {
    console.log("filter object in apply btn", filterobject)
    if (filterobject.Status !== '' && (fromdt !== '' && filterobject.To !== '') && (errormsg === '') && (errormsgTo === '')) {
      return false;
    } else if (filterobject.Status == '' && (fromdt !== '' && filterobject.To !== '') && (errormsg === '') && (errormsgTo === '')) {
      return false;
    } else if (filterobject.Status !== '' && (fromdt == '' && filterobject.To == '')) {
      return false;
    }
    else {
      return true;
    }
  }

  const truncString = (str: any, max: number, add: any) => {
    add = add || '...';
    return typeof str === 'string' && str.length > max
      ? str.substring(0, max) + add
      : str;
  };
  const onUploadFileChange = (e: any) => {
    console.log('upload file type', typeof (e));
    // const namerexdocname = new RegExp(`^[A-Za-z0-9/-]+$`);
    let reqfilename = e.target.value.replace('C:\\fakepath\\', '');
    console.log('new reqfilename 1', reqfilename)

    reqfilename = reqfilename.includes('.xlsx') ? reqfilename.replace('.xlsx', '') :
      reqfilename = reqfilename.includes('.xls') ? reqfilename.replace('.xls', '') : ''
    console.log('new reqfilename 2', reqfilename)
    // if (reqfilename.match(namerexdocname) && reqfilename.length < 50) {
    if (reqfilename.length < 40) {
      setuploadPricingReq({ ...uploadPricingReq, documentName: reqfilename });
      setuploadfile(e.target.files[0]);
      console.log("target.vlaue", e.target.files[0]);
      e.target.value.replace('C:\\fakepath\\', '');
      const finalData = truncString(
        e.target.value.replace('C:\\fakepath\\', ''),
        20,
        '...'
      );
      console.log('finalData', finalData);
      setFilename(e.target.value.replace('C:\\fakepath\\', ''));
      setValidationErrorMsg('');
    }
    else {
      setFilename('');
      setValidationErrorMsg('invalidFileName');
      console.log('false');
    }
  };

  const subTypeCheck = (s1: string) => {
    let array = subtype;
    if (subtype.includes(s1)) {
      console.log(subtype);
      ValidationErrorMsg !== '' ? setValidationErrorMsg('') : '';
      array = subtype.filter(function (item: any) {
        return item !== s1
      })
      console.log(subtype)
      if (subtype.length == 1) {
        setFilename('');
      }
    }
    else {
      array = array.concat([s1])
    }
    if (newPendingcheckarray.length !== 0) {
      if (newPendingcheckarray.includes('Pricing')
        && (array.includes('Pricing') || array.includes('StoreZone'))) {
        setValidationErrorMsg('pricingispending');
      }
      if (newPendingcheckarray.includes('Autoterm')
        && (array.includes('Autoterm') || array.includes('StoreZone'))) {
        setValidationErrorMsg('autotermispending');
      }
      if (newPendingcheckarray.includes('Package')
        && (array.includes('StoreZone'))) {
        setValidationErrorMsg('packageispending')
      }
    }

    setSubtype(array)
    console.log(subtype)
  }

  const downloadbyurl = (link: any) => {
    var element = document.createElement('a');
    element.setAttribute('href', `${link}`);
    element.setAttribute('style', 'display:none')
    document.body.appendChild(element);
    console.log(element);
    element.click();
    document.body.removeChild(element);
  }


  const uploadpopup = () => {
    return (
      <>
        <Grid className={classes.mx2}>
          <Grid className={classes.m2}>
            <Typography variant="h6">Type</Typography>
          </Grid>
          <Grid container spacing={2} className={classes.mb3}>
            <Grid item xs={12} sm={4} md={4} lg={4} >
              <RACRadio value={"1"}
                label="Pricing"
                name="validationtype"
                checked={type == 'Pricing' ? true : false}
                onChange={() => {
                  setFilename('')
                  settype('Pricing');
                  ValidationErrorMsg !== '' ? setValidationErrorMsg('') : '';
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} >
              <RACRadio value={"1"}
                label="Package"
                name="validationtype"
                checked={type == 'Package' ? true : false}
                onChange={() => {
                  if (newPendingcheckarray.length !== 0) {
                    if (newPendingcheckarray.includes('Package')) {
                      setValidationErrorMsg('packageispending');
                    }
                    else {
                      ValidationErrorMsg !== '' ? setValidationErrorMsg('') : '';
                    }
                  }
                  else {
                    ValidationErrorMsg !== '' ? setValidationErrorMsg('') : '';
                  }
                  setFilename('');
                  settype('Package');
                  setSubtype([])
                }}

              />
            </Grid>
            {
              <Grid item xs={12} sm={4} md={4} lg={4} >
                <RACRadio
                  value={"Zone"}
                  label="Zone"
                  name="validationtype"
                  checked={type == 'Zone' ? true : false}
                  onChange={() => {
                    if (newPendingcheckarray.length !== 0) {
                      if (newPendingcheckarray.includes('Pricing') || newPendingcheckarray.includes('Autoterm') || newPendingcheckarray.includes('Package')) {
                        setValidationErrorMsg('commonfileispending');
                      }
                      else {
                        ValidationErrorMsg !== '' ? setValidationErrorMsg('') : '';
                      }
                    }
                    else {
                      ValidationErrorMsg !== '' ? setValidationErrorMsg('') : '';
                    }
                    setFilename('');
                    settype('Zone');
                    setSubtype([])
                  }}

                />
              </Grid>
              // : ''
            }

          </Grid>
          {type == 'Pricing' ?
            <Grid>
              <Grid className={classes.m2}>
                <Typography variant="h6">Sub Type</Typography>
              </Grid>
              <Grid container spacing={2} className={classes.mb3}>
                <Grid item xs={12} sm={4} md={4} lg={4} >
                  <RACCheckBox
                    className="form-check-input float-start text-center "
                    onClick={() => {
                      ValidationErrorMsg !== '' ? setValidationErrorMsg('') : '';
                      subTypeCheck("StoreZone");
                    }}
                    id="c1"
                    name='pricingtype'
                    checked={subtype.includes('StoreZone') ? true : false}
                    label="StoreZone"
                    value="StoreZone"
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} >
                  <RACCheckBox
                    className="form-check-input float-start text-center "
                    onClick={() => {
                      ValidationErrorMsg !== '' ? setValidationErrorMsg('') : '';
                      subTypeCheck("Pricing");
                    }}
                    id="c2"
                    name='pricingtype'
                    value="Pricing"
                    checked={subtype.includes('Pricing') ? true : false}
                    label="Pricing"
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} >
                  <RACCheckBox
                    className="form-check-input float-start text-center "
                    id="c3"
                    value="Autoterm"
                    onClick={() => {
                      ValidationErrorMsg !== '' ? setValidationErrorMsg('') : '';
                      subTypeCheck("Autoterm");
                    }}
                    checked={subtype.includes('Autoterm') ? true : false}
                    label="Autoterm"
                  />
                </Grid>
              </Grid>
            </Grid>
            : ''}

          <Grid className={classes.m2}>
            <Typography variant="h6">Upload File</Typography>
          </Grid>
          <Grid container className={`${classes.dflex} ${classes.alignCenter} ${classes.m2}`}>


            <Grid item xs={12} sm={4} md={4} lg={12} className={`${classes.dflex} ${classes.alignCenter} ${classes.m2}`}>
              <ExcelImg className={classes.mr2}></ExcelImg>
              {type != 'Package' ?
                <Link className={progressclasses.cursorType} onClick={() => getdocumentbykeyname('upload-template/PricingUploadTemplate.xlsx')}>  <Typography > Download Template </Typography></Link>
                :
                <Link className={progressclasses.cursorType} onClick={() => getdocumentbykeyname('upload-template/PackageTemplate.xlsx')}>  <Typography > Download Template </Typography></Link>
              }
            </Grid>


          </Grid>
          <Grid className={`${classes.dflex} ${classes.alignCenter} ${classes.m2}`}>
            <RACButton className={`${classes.bgLightBlue} ${classes.px4} ${classes.py2} ${classes.mx2} ${progressclasses.uploadBtnWrapper}  `} variant='outlined' color='primary'
              disabled={type == 'Pricing' ?
                (subtype.length == 0)
                  ? true
                  : false : false
              } >
              <input type="file"
                id='fileinput'
                onChange=
                {(e: any) => {
                  console.log(e);
                  ValidationErrorMsg !== '' ? setValidationErrorMsg('') : '';
                  onUploadFileChange(e);
                  filename == '' ? e.target.value = '' : undefined;
                }}

                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />Upload
            </RACButton>
            <Typography className={classes.textGrey}>{filename == '' ? "No File Choosen" :
              filename.length > 20 ?
                <RACTooltip title={filename} placement='top'>
                  <Typography >
                    {filename.slice(0, 20)}...
                  </Typography>
                </RACTooltip>
                :
                filename}</Typography>




            {filename !== '' ?
              <UploadClose
                className={classes.ml2}
                onClick={() => {
                  console.log("file name", filename);
                  setuploadfile();
                  setFilename('');
                  ValidationErrorMsg !== '' ? setValidationErrorMsg('') : '';
                  console.log("file name 2", filename)
                }}></UploadClose>
              : ''}
          </Grid>
          <Typography className={`${classes.m3}`}>
            {ValidationErrorMsg == 'invalidTemplate' ? <span style={{ fontFamily: 'OpenSans-regular', color: 'red' }}>Invalid Template</span> :
              ValidationErrorMsg == 'invalidFileName' ? <span style={{ fontFamily: 'OpenSans-regular', color: 'red' }}>Invalid File Name</span> :
                ValidationErrorMsg == 'pricingispending' ? <span style={{ fontFamily: 'OpenSans-regular', color: 'red' }}>Please wait until Pricing upload is completed</span> :
                  ValidationErrorMsg == 'autotermispending' ? <span style={{ fontFamily: 'OpenSans-regular', color: 'red' }}>Please wait until Autoterm upload is completed</span> :
                    ValidationErrorMsg == 'packageispending' ? <span style={{ fontFamily: 'OpenSans-regular', color: 'red' }}>Please wait until Package upload is completed</span> :
                      ValidationErrorMsg == 'commonfileispending' ? <span style={{ fontFamily: 'OpenSans-regular', color: 'red' }}>Please wait while the previous file is being uploaded</span> :
                        ''}
          </Typography>
          <Grid item xs={12} sm={12} md={12} lg={12} className={` ${classes.m2} ${classes.p2} ${classes.componentRight}`}>
            <RACButton variant="outlined" color="default"
              onClick={() => {
                setPopup('');
                setFilename('');
                setSubtype([]);
                setValidationErrorMsg('');
                settype('');

              }}
              className={classes.mr2}
            >Cancel</RACButton>
            <RACButton variant="contained" color="primary"
              onClick={() => {
                saveclick();
              }}
              disabled={filename !== '' && ValidationErrorMsg == '' ? false : true}>Save</RACButton>
          </Grid>

        </Grid>
      </>
    );
  };
  const downloadResponse = () => {
    return (
      <>
        <Grid >
          {

            popup == 'downloadResponseFail' ?
              <Grid>
                <Grid className={progressclasses.successPopup}>
                  < SomethingWrong className={progressclasses.racErrorIcon} /></Grid>
                <Grid className={`${progressclasses.textcenterImage} ${classes.fontBold}`}>Something Went Wrong </Grid>
                <Grid className={`${progressclasses.loader} ${classes.mt2}`}>
                  <RACButton variant="contained" color="primary" onClick={() => { setPopup('') }}>OK</RACButton>
                </Grid>
              </Grid>
              : ''
          }
        </Grid>
      </>
    )
  };
  const uploadResponse = () => {
    return (
      <>
        <Grid >
          {
            popup == 'curentUserresponsefail' ?
              < Grid >
                <Grid className={progressclasses.successPopup}>
                  < SomethingWrong className={progressclasses.racErrorIcon} /></Grid>
                <Grid className={`${progressclasses.textcenterImage} ${classes.fontBold}`}>Something Went Wrong</Grid>
                <Grid className={`${progressclasses.loader} ${classes.mt2}`}>
                  <RACButton variant="contained" color="primary" onClick={() => { window.location.reload(); }
                  }>OK</RACButton>
                </Grid>
              </Grid>
              :
              popup == 'uploadResponseSuccess' ?
                uploadPricingReq.status == 'Validation Failed' ?
                  < Grid >
                    <Grid className={progressclasses.successPopup}>
                      < SomethingWrong className={progressclasses.racErrorIcon} /></Grid>
                    <Grid className={`${progressclasses.textcenterImage} ${classes.fontBold}`}>Validation Failed</Grid>
                    <Grid className={`${progressclasses.loader} ${classes.mt2}`}>
                      <RACButton variant="contained" color="primary" onClick={() => {

                        setPopup('');
                        setMasterLoader(true);
                        gridloadapi();
                      }
                      }>OK</RACButton>
                    </Grid>
                  </Grid>
                  :
                  uploadPricingReq.status == consts.SCHEDULED_STATUS ?
                    <Grid>
                      <Grid className={progressclasses.successPopup}>
                        < Success className={progressclasses.racErrorIcon} /></Grid>
                      <Grid className={`${progressclasses.textcenterImage} ${classes.fontBold}`}>Your file is set to execute overnight</Grid>
                    <Grid className={`${progressclasses.loader} ${classes.mt2}`}>
                      <RACButton variant="contained" color="primary" onClick={() => {

                        setPopup('');
                        setMasterLoader(true);
                        gridloadapi();
                      }
                      }>OK</RACButton>
                    </Grid>
                  </Grid>
                  :
                  uploadPricingReq.status == 'Pending' ?
                    <Grid>
                      <Grid className={progressclasses.successPopup}>
                        < Success className={progressclasses.racErrorIcon} /></Grid>
                      <Grid className={`${progressclasses.textcenterImage} ${classes.fontBold}`}>Your file is being uploaded</Grid>
                      <Grid className={`${progressclasses.loader} ${classes.mt2}`}>
                        <RACButton variant="contained" color="primary" onClick={() => {

                          setPopup('');
                          setMasterLoader(true);
                          gridloadapi();
                        }
                        }>OK</RACButton>
                      </Grid>
                    </Grid> : ''
                :
                popup == 'uploadResponseFail' ?
                  <Grid>
                    <Grid className={progressclasses.successPopup}>
                      < SomethingWrong className={progressclasses.racErrorIcon} /></Grid>
                    <Grid className={`${progressclasses.textcenterImage} ${classes.fontBold}`}>Something Went Wrong </Grid>
                    <Grid className={`${progressclasses.loader} ${classes.mt2}`}>
                      <RACButton variant="contained" color="primary" onClick={() => {
                        setPopup('');
                        setMasterLoader(true);
                        gridloadapi();
                      }
                      }>OK</RACButton>
                    </Grid>
                  </Grid>
                  : popup == 'zoneispending' ?
                    <Grid>
                      <Grid className={progressclasses.successPopup}>
                        < SomethingWrong className={progressclasses.racErrorIcon} /></Grid>
                      <Grid className={`${progressclasses.textcenterImage} ${classes.fontBold}`}>Please wait while the previous file is being uploaded</Grid>
                      <Grid className={`${progressclasses.loader} ${classes.mt2}`}>
                        <RACButton variant="contained" color="primary" onClick={() => {
                          setPopup('');
                          setMasterLoader(true);
                          gridloadapi();
                        }
                        }>OK</RACButton>
                      </Grid>
                    </Grid>
                    :
                    popup == 'uploadResponse400' ?
                      <Grid>
                        <Grid className={progressclasses.successPopup}>
                          < SomethingWrong className={progressclasses.racErrorIcon} /></Grid>
                        <Grid className={`${progressclasses.textcenterImage} ${classes.fontBold}`}>
                          {errormsg == '' ? 'Please wait while the previous file is being uploaded ' : errormsg}
                        </Grid>
                        <Grid className={`${progressclasses.loader} ${classes.mt2}`}>
                          <RACButton variant="contained" color="primary" onClick={() => {
                            setPopup('');
                            setMasterLoader(true);
                            gridloadapi();
                          }
                          }>OK</RACButton>
                        </Grid>
                      </Grid>
                      : popup == 'storezoneispending' ?
                        <Grid>
                          <Grid className={progressclasses.successPopup}>
                            < SomethingWrong className={progressclasses.racErrorIcon} /></Grid>
                          <Grid className={`${progressclasses.textcenterImage} ${classes.fontBold}`}>Please wait while the previous file is being uploaded</Grid>
                          <Grid className={`${progressclasses.loader} ${classes.mt2}`}>
                            <RACButton variant="contained" color="primary" onClick={() => {
                              setPopup('');
                              setMasterLoader(true);
                              gridloadapi();
                            }
                            }>OK</RACButton>
                          </Grid>
                        </Grid>
                        : popup == 'filevalidation' ?
                          <Grid>
                            <Grid className={`${progressclasses.successPopup} ${progressclasses.progressbar}`}>
                              <Grid className={`${progressclasses.animatedLoader}`}>
                              </Grid>
                            </Grid>
                            <Grid>
                              <Typography className={`${progressclasses.textcenterImage} ${classes.fontBold}`}>Your file is being validated</Typography>
                              <Typography className={`${progressclasses.pleasewaittextcenter} ${classes.textGrey} `}>Please wait</Typography>
                            </Grid>
                          </Grid>
                          : popup == 'nosuchkeyfound' ?
                            <Grid>
                              <Grid className={progressclasses.successPopup}>
                                < WaitingIcon /></Grid>
                              <Grid className={`${progressclasses.textcenterImage} ${classes.fontBold}`}>File does not exists</Grid>
                              <Grid className={`${progressclasses.loader} ${classes.mt2}`}>
                                <RACButton variant="contained" color="primary" onClick={() => {
                                  setPopup('');
                                }
                                }>OK</RACButton>
                              </Grid>
                            </Grid>
                            : ''
          }
        </Grid>
      </>
    )
  };

  const saveclick = async () => {

    console.log('validations before')
    console.log('start time', new Date())
    await validations();
    console.log('validations after')

  }

  const validations = async () => {
    let validationerrormessage = '';
    console.log('validations function');
    console.log('target in validations', uploadfile);
    console.log('target file in validations', uploadfile.name);
    const file = uploadfile;
    const newexcel = XLSX.utils.book_new();
    console.log("1. New Excel declaration ",newexcel);
    if (type == 'Pricing' || type == 'Zone') {
      newexcel.SheetNames.push('Zone');
      newexcel.SheetNames.push('StoreZone');
      newexcel.SheetNames.push('Pricing');
      newexcel.SheetNames.push('Autoterm');
    }
    if (type == 'Package') {
      newexcel.SheetNames.push('Package');      
    }
    console.log("2. New Excel sheets",newexcel.SheetNames,newexcel);
    //check file format
    if (uploadfile.name.slice(uploadfile.name.indexOf(".") + 1, uploadfile.name.length) === "xlsx") {
      console.log("file format check")
      console.log("file size check",uploadfile.size)
      // check file size
      if (uploadfile.size <= 15000000) {

        const reader = new FileReader();
        reader.onloadstart = function (evt: any) {
          setPopup('filevalidation');
        }
        reader.onload = async (evt: any) => {
          console.log("enters to reader.onload")
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          console.log("type subtype sheet_names", type,' ',subtype,' ',wb.SheetNames);
          // check the sheet order
          if (type == 'Pricing' || type == 'Zone') {
            if (wb.SheetNames.length == 4 && wb.SheetNames[0] == 'Zone' && wb.SheetNames[1] == 'StoreZone' && wb.SheetNames[2] == 'Pricing' && wb.SheetNames[3] == 'Autoterm') {
              //validate excel for Pricing
              if (type == 'Pricing') {
                if (subtype.includes('StoreZone')) {
                  // const StoreZone
                  const wsname = wb.SheetNames[1];
                  const ws = wb.Sheets[wsname];
                  let data: any = XLSX.utils.sheet_to_json(ws, { header: 1 });
                  console.log('store zone data', data)
                  if (data.length == 0 || data.length == 1) {
                    console.log('data.length == 0 || data.length == 1');
                    setValidationErrorMsg('invalidTemplate');
                    validationerrormessage = 'invalidTemplate';
                  }
                  else if (data.length > 1) {
                    if (data[0].length == 11) {
                      if (
                        data[0][0] == 'Store',
                        data[0][1] == 'Zone',
                        data[0][2] == 'Company',
                        data[0][3] == 'Store Type',
                        data[0][4] == 'State',
                        data[0][5] == 'Company Code',
                        data[0][6] == 'Start Date',
                        data[0][7] == 'Validation Status',
                        data[0][8] == 'Validation Comments',
                        data[0][9] == 'Upload Status',
                        data[0][10] == 'Upload Comments'
                      ) {
                        if (data[1].length == 0) {
                          console.log('data[1].length == 0')
                          setValidationErrorMsg('invalidTemplate');
                          validationerrormessage = 'invalidTemplate';
                        }
                        else {
                          console.log('all template validations passed')
                          console.log('Store Zone sheet check', subtype);
                          data = await validateStoreZone(data);
                          const storezonesheet = XLSX.utils.aoa_to_sheet(data);//xlsx.utils.aoa_to_sheet ==>for array of arrays
                          newexcel.Sheets.StoreZone = storezonesheet;

                        }
                      }
                      else {
                        setValidationErrorMsg('invalidTemplate');
                        validationerrormessage = 'invalidTemplate';
                      }
                    }
                    else {
                      console.log('data[0].length != 9')
                      setValidationErrorMsg('invalidTemplate');
                      validationerrormessage = 'invalidTemplate';
                    }
                  }
                }
                if (subtype.includes('Pricing')) {
                  console.log('Pricing sheet check', subtype)
                  const wsname = wb.SheetNames[2];
                  const ws = wb.Sheets[wsname];
                  let data: any = XLSX.utils.sheet_to_json(ws, { header: 1 });
                  console.log("data Pricing ", data)
                  if (data.length == 0 || data.length == 1) {
                    console.log('data.length == 0 || data.length == 1')
                    setValidationErrorMsg('invalidTemplate');
                    validationerrormessage = 'invalidTemplate';
                  }
                  else if (data.length > 1) {
                    console.log('data.length > 2')
                    console.log('data[0].length', data[0].length);
                    if (data[0].length == 19) {
                      if (
                        data[0][ZONE_INDEX] == ZONE,
                    data[0][TYPE_INDEX] == TYPE,
                    data[0][DEPARTMENT_INDEX] == DEPARTMENT,
                    data[0][SUB_DEPARTMENT_INDEX] == SUB_DEPARTMENT,
                    data[0][BRACKET_INDEX]== BRACKET,
                    data[0][RMS_ITEM_NUMBER_INDEX] == RMS_ITEM_NUMBER,
                    data[0][WEEKLY_RATE_NEW_INDEX] == WEEKLY_RATE_NEW,
                    data[0][WEEKLY_RATE_USED_INDEX] == WEEKLY_RATE_USED,
                    data[0][ADDON_WEEKLY_RATE_INDEX] == ADDON_WEEKLY_RATE,
                    data[0][TERM_INDEX] == TERM,
                    data[0][CASH_PRICE_FACTOR_INDEX] ==CASH_PRICE_FACTOR ,
                    data[0][FORCED_CASH_PRICE_INDEX] == FORCED_CASH_PRICE,
                    data[0][MANUALPO_TURNS_INDEX] == MANUAL_PO_TURNS,
                    data[0][START_DATE_INDEX] == START_DATE,
                    data[0][END_DATE_INDEX] == END_DATE,
                    data[0][VALIDATION_STATUS_INDEX] == VALIDATION_STATUS,
                    data[0][VALIDATION_COMMENTS_INDEX] == VALIDATION_COMMENTS,
                    data[0][UPLOAD_STATUS_INDEX] == UPLOAD_STATUS,
                    data[0][UPLOAD_COMMENTS_INDEX] == UPLOAD_COMMENTS
                        ) {
                        if (data[1].length == 0) {
                          console.log('data[1].length == 0')
                          setValidationErrorMsg('invalidTemplate');
                          validationerrormessage = 'invalidTemplate';
                        }
                        else {
                          console.log('all template validations passed');
                          console.log('Pricing sheet check', subtype);
                          data = await validatePricing(data);
                          const pricingsheet = XLSX.utils.aoa_to_sheet(data);//xlsx.utils.aoa_to_sheet ==>for array of arrays                          
                          newexcel.Sheets.Pricing = pricingsheet;
                          console.log("3. pricingsheet added in new excel",newexcel);
                        }
                      }
                      else {
                        setValidationErrorMsg('invalidTemplate');
                        validationerrormessage = 'invalidTemplate';
                      }
                    }
                    else {
                      console.log('data[0].length != 14')
                      setValidationErrorMsg('invalidTemplate');
                      validationerrormessage = 'invalidTemplate';
                    }
                  }
                }
                if (subtype.includes('Autoterm')) {
                  console.log('Autoterm');
                  const wsname = wb.SheetNames[3];
                  const ws = wb.Sheets[wsname];
                  let data: any = XLSX.utils.sheet_to_json(ws, { header: 1 });
                  console.log('autoterm data', data)
                  if (data.length == 0 || data.length == 1) {
                    console.log('data.length == 0 || data.length == 1')
                    setValidationErrorMsg('invalidTemplate');
                    validationerrormessage = 'invalidTemplate';
                  }
                  else if (data.length > 1) {
                    console.log('data.length > 2')
                    if (data[0].length == 14) {
                      if (
                        data[0][0] == 'Zone',
                        data[0][1] == 'Department',
                        data[0][2] == 'Tier',
                        data[0][3] == 'Days on Rent Start',
                        data[0][4] == 'Days on Rent End',
                        data[0][5] == 'Percent Discount',
                        data[0][6] == 'Subdepartment',
                        data[0][7] == 'Bracket',
                        data[0][8] == 'RMS Number',
                        data[0][9] == 'Start Date',
                        data[0][10] == 'Validation Status',
                        data[0][11] == 'Validation Comments',
                        data[0][12] == 'Upload Status',
                        data[0][13] == 'Upload Comments'
                      ) {
                        if (data[1].length == 0) {
                          console.log('data[1].length == 0')
                          setValidationErrorMsg('invalidTemplate');
                          validationerrormessage = 'invalidTemplate';
                        }
                        else {
                          console.log('all template validations passed')
                          console.log('Autoterm sheet check', subtype)
                          data = await validateAutoterm(data)
                          const autotermsheet = XLSX.utils.aoa_to_sheet(data);//xlsx.utils.aoa_to_sheet ==>for array of arrays
                          newexcel.Sheets.Autoterm = autotermsheet;
                        }
                      }
                      else {
                        setValidationErrorMsg('invalidTemplate');
                        validationerrormessage = 'invalidTemplate';
                      }
                    }
                    else {
                      console.log('data[0].length != 9')
                      setValidationErrorMsg('invalidTemplate');
                      validationerrormessage = 'invalidTemplate';
                    }
                  }
                }
              }
              if (type == 'Zone') {
                console.log('Zone')
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                let data: any = XLSX.utils.sheet_to_json(ws, { header: 1 });
                console.log('zone data', data)
                console.log('zone data [0]', data[0]);
                console.log('zone data [1]', data[1]);
                if (data.length == 0 || data.length == 1) {
                  console.log('data.length == 0 || data.length == 1')
                  setValidationErrorMsg('invalidTemplate');
                  validationerrormessage = 'invalidTemplate';
                }
                else if (data.length > 1) {
                  console.log('data.length > 2');
                  if (data[0].length == 11) {
                    if (
                      data[0][0] == 'Zone',
                      data[0][1] == 'Zone Name',
                      data[0][2] == 'Company Code',
                      data[0][3] == 'Company Name',
                      data[0][4] == 'Start Date',
                      data[0][5] == 'End Date',
                      data[0][6] == 'Comments',
                      data[0][7] == 'Validation Status',
                      data[0][8] == 'Validation Comments',
                      data[0][9] == 'Upload Status',
                      data[0][10] == 'Upload Comments'
                    ) {
                      if (data[1].length == 0) {
                        console.log('data[1].length == 0')
                        setValidationErrorMsg('invalidTemplate');
                        validationerrormessage = 'invalidTemplate';
                      }
                      else {
                        console.log('all template validations passed')
                        data = await validateZone(data)
                        const zonesheet = XLSX.utils.aoa_to_sheet(data);//xlsx.utils.aoa_to_sheet ==>for array of arrays
                        // const wscols = [
                        //   { wch: 10 },
                        //   { wch: 25 },
                        //   { wch: 15 },
                        //   { wch: 25 },
                        //   { wch: 15 },
                        //   { wch: 15 },
                        //   { wch: 15 },
                        //   { wch: 20 },
                        //   { wch: 30 },
                        //   { wch: 20 },
                        //   { wch: 20 }
                        // ];
                        // zonesheet['!cols'] = wscols;
                        newexcel.Sheets.Zone = zonesheet;

                      }
                    }
                    else {
                      setValidationErrorMsg('invalidTemplate');
                      validationerrormessage = 'invalidTemplate';
                    }
                  }
                  else {
                    console.log('data[0].length != 9')
                    setValidationErrorMsg('invalidTemplate');
                    validationerrormessage = 'invalidTemplate';
                  }
                }
              }
            }
            else {
              setSubtype([]);
              setFilename('');
              setMasterLoader(false);
              setValidationErrorMsg('invalidTemplate');
              validationerrormessage = 'invalidTemplate';

              console.log("invalid sheet order", wb.SheetNames[0])
              console.log("invalid sheet order", wb.SheetNames[1])
              console.log("invalid sheet order", wb.SheetNames[2])
              console.log("invalid sheet order", wb.SheetNames[3])
            }
          }
          //validate excel for Package
          if (type == 'Package') {

            if (wb.SheetNames.length == 1 && wb.SheetNames[0] == 'Package') {
              const wsname = wb.SheetNames[0];
              const ws = wb.Sheets[wsname];
              let data: any = XLSX.utils.sheet_to_json(ws, { header: 1 });

              if (data.length == 0 || data.length == 1) {
                console.log('data.length == 0 || data.length == 1')
                setValidationErrorMsg('invalidTemplate');
                validationerrormessage = 'invalidTemplate';

              }
              else if (data.length > 1) {
                console.log('data.length > 2')
                if (data[0].length == 16) {
                  if (
                    data[0][0] == 'Zone',
                    data[0][1] == 'Package Name',
                    data[0][2] == 'Discount Type',
                    data[0][3] == 'Discount Amount',
                    data[0][4] == 'Department',
                    data[0][5] == 'Subdepartment',
                    data[0][6] == 'Bracket',
                    data[0][7] == 'RMS Number',
                    data[0][8] == 'Minimum Quantity',
                    data[0][9] == 'Maximum Quantity',
                    data[0][10] == 'Start Date',
                    data[0][11] == 'End Date',
                    data[0][12] == 'Validation Status',
                    data[0][13] == 'Validation Comments',
                    data[0][14] == 'Upload Status',
                    data[0][15] == 'Upload Comments'
                  ) {
                    if (data[1].length == 0) {
                      console.log('data[1].length == 0')
                      setValidationErrorMsg('invalidTemplate');
                      validationerrormessage = 'invalidTemplate';
                    }
                    else {
                      console.log('all template validations passed');
                      console.log('Package sheet check', subtype);
                      data = validatePackage(data);
                      console.log('Package data', data)
                      const packagesheet = XLSX.utils.aoa_to_sheet(data);
                      newexcel.Sheets.Package = packagesheet;
                    }
                  }
                  else {
                    setValidationErrorMsg('invalidTemplate');
                    validationerrormessage = 'invalidTemplate';
                  }
                }
                else {
                  console.log('data[0].length !=14')
                  setValidationErrorMsg('invalidTemplate');
                  validationerrormessage = 'invalidTemplate';
                }
              }
              console.log("Package type", type)
            }
            else {
              setMasterLoader(false);
              setValidationErrorMsg('invalidTemplate');
              validationerrormessage = 'invalidTemplate';
              console.log('invalidsheet Package Template')
            }
          }

          console.log('ValidationErrorMsg', ValidationErrorMsg)
          console.log('new excel', newexcel);
          let newarrayexcel: any = XLSX.write(newexcel, { bookType: 'xlsx', type: 'array' });
          console.log("4. newarrayexcel after write ", newarrayexcel);
          console.log('validationStatusFlags', validationStatusFlag);
          let validationsStatus, newkey;
          //CHG_001 - This is where the folder and status is decided based on the flag.        
          if (validationStatusFlag !== 'Validation Failed') {//Positive flow

            let folderName = consts.PROCESS_FOLDER_NAME;
            let status = consts.PENDING_STATUS;
            let payloadCount = 0;

            if(subtype.includes('Pricing') && zoneRecordsCountArray.length > 0){
              const ZoneStoresData = await getZoneDD();
              console.log('ZoneStore service resp:: ', ZoneStoresData);
              console.log('zoneRecordsCountArray:: ', zoneRecordsCountArray);
  
              const storeCountMap: Map<number, number> = new Map();            
              if (ZoneStoresData.status == 200){
              const dataObject : ZoneStoreResponse = ZoneStoresData.data;
  
              dataObject.result.forEach((obj: zoneStoreData) => {
                  storeCountMap.set(Number(obj.zone), obj.store.length);
              });
              }
  
              console.log("storeCountMap:: ",storeCountMap);
  
              for(let ind = 0; ind < zoneRecordsCountArray.length; ind++){
  
                let storeCount = storeCountMap.get(zoneRecordsCountArray[ind].zone);
                console.log("storeCount :: ",storeCount);
                payloadCount += zoneRecordsCountArray[ind].recordsCount * ( storeCount ? storeCount : 0);
              }

              const payloadLimit = payloadCountLimit ? payloadCountLimit : consts.ONE_MILLION;
              console.log(`payloadCount ${payloadCount} && payloadLimit ${payloadLimit}`);
              if(payloadCount > payloadLimit){
                folderName = consts.OVERNIGHT_FOLDER_NAME;
                status = consts.SCHEDULED_STATUS;
              }
            }
            newkey = `${folderName}/` + uploadPricingReq.documentName + Date.now() + '.xlsx';
            validationsStatus = status;
          }
          else {
            //Negative flow - validation failed
            newkey = "upload-validation-failed/" + uploadPricingReq.documentName + Date.now() + '.xlsx';
            validationsStatus = 'Validation Failed';
          }

          if (validationerrormessage !== 'invalidTemplate') {
            console.log(`inside validationerrormessage !== 'invalidTemplate'`);
            const s3uploadkey = {
              "key": newkey,
              "type": "POST"
            }
            console.log('s3uploadkey', s3uploadkey);
            uploadDocumentAPI(newkey, validationsStatus, s3uploadkey, newarrayexcel);

          }
          else {
            setMasterLoader(false);
            setPopup('upload');
          }
          console.log('end time', new Date());
        }
        // }
        reader.readAsBinaryString(file);
        console.log("docuent download")
      }
      else {
        setMasterLoader(false);
        setValidationErrorMsg('invalidTemplate');
        validationerrormessage = 'invalidTemplate';
      }
    }
    else {
      setMasterLoader(false);

      setValidationErrorMsg('invalidTemplate');
      validationerrormessage = 'invalidTemplate';
    }
    console.log("validations ends")
  };
  const validateZone = (data: any) => {
    console.log("Zone type", type)

    console.log('zone data', data)
    var newData: any = [];
    for (let i = 1; i < data.length; i++) {
      if (data[i].length == 0)
        break
      if (data[i][0] !== undefined)
        newData.push((String(data[i][0])));
      else
        newData.push('');
    }
    var map: any = {};
    var duplicates = [];
    for (var i = 0; i < newData.length; i++) {
      var element = newData[i];
      if (!map[element]) {
        map[element] = [i];
      }
      else {
        map[element].push(i);
        duplicates.push(i + 1)
      }
    }
    console.log('newData', newData);
    console.log('map', map);
    console.log('duplicates', duplicates);
    for (let rowindex = 1; rowindex < data.length; rowindex++) {
      console.log('inside Zone for')
      if (data[rowindex].length == 0)
        return data;
      if (data[rowindex][0] == undefined) {
        data[rowindex][7] = 'Failed';
        validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
        data[rowindex][8] = (data[rowindex][8] == undefined ? "Zone Number is required" : data[rowindex][8] + ", Zone Number is required");
        data[rowindex][9] = undefined;
        data[rowindex][10] = undefined;
        if (data[rowindex][4] !== undefined) {
          if (!(isNaN(data[rowindex][4]))) {
            let excelstartdate = new Date(((data[rowindex][4] - (25567 + 1)) * 86400 * 1000));
            data[rowindex][4] = new Date(excelstartdate.getFullYear(), excelstartdate.getMonth(), excelstartdate.getDate(), 0, 0, 0);
          }
        }
        if (data[rowindex][5] !== undefined) {
          if (!(isNaN(data[rowindex][5]))) {
            let excelenddate = new Date(((data[rowindex][5] - (25567 + 1)) * 86400 * 1000));
            data[rowindex][5] = new Date(excelenddate.getFullYear(), excelenddate.getMonth(), excelenddate.getDate(), 0, 0, 0);

          }
        }
      }
      else if (duplicates.includes(rowindex)) {
        data[rowindex][7] = 'Failed';
        validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
        data[rowindex][8] = "Zone Number is repeated";
        data[rowindex][9] = undefined;
        data[rowindex][10] = undefined;
        if (data[rowindex][4] !== undefined) {
          if (!(isNaN(data[rowindex][4]))) {
            let excelstartdate = new Date(((data[rowindex][4] - (25567 + 1)) * 86400 * 1000));
            data[rowindex][4] = new Date(excelstartdate.getFullYear(), excelstartdate.getMonth(), excelstartdate.getDate(), 0, 0, 0);
          }
        }
        if (data[rowindex][5] !== undefined) {
          if (!(isNaN(data[rowindex][5]))) {
            let excelenddate = new Date(((data[rowindex][5] - (25567 + 1)) * 86400 * 1000));
            data[rowindex][5] = new Date(excelenddate.getFullYear(), excelenddate.getMonth(), excelenddate.getDate(), 0, 0, 0);
          }
        }

      }
      else {
        // check Zone  number column values
        if (data[rowindex][0] === undefined || isNaN(data[rowindex][0]) || data[rowindex][0] < 0 || data[rowindex][0].toString().includes('.') || data[rowindex][0].toString().length > 5 || data[rowindex][0].toString().trim() === '') {
          data[rowindex][7] = 'Failed';
          data[rowindex][9] = undefined;
          data[rowindex][10] = undefined;
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          if (data[rowindex][0] !== undefined) {
            if (data[rowindex][0].toString().trim() === '') {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Zone Number is empty" : data[rowindex][8] + ", Zone Number is empty");
            }
            if (isNaN(data[rowindex][0])) {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Invalid zone number" : data[rowindex][8] + ", Invalid zone number");
            }
            else if (data[rowindex][0].toString().length > 5) {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Zone number should not exceed 5 digits" : data[rowindex][8] + ", Zone number should not exceed 5 digits")
            }
            if (data[rowindex][0] < 0) {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Zone Number should not be less than Zero" : data[rowindex][8] + ", Zone Number should not be less than Zero")
            }
            if (data[rowindex][0].toString().includes('.')) {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Zone Number should not contain be a decimal number" : data[rowindex][8] + ", Zone Number should not contain be a decimal number")
            }
          }
          else
            data[rowindex][8] = (data[rowindex][8] == undefined ? "Zone Number is required" : data[rowindex][8] + ", Zone Number is required");
        }
        else {
          data[rowindex][7] = 'Passed';
          data[rowindex][8] = undefined;
          data[rowindex][9] = undefined;
          data[rowindex][10] = undefined;
        }

        // check Zone Name column values
        if (data[rowindex][1] === undefined || (data[rowindex][1].length > 60) || !isNaN(data[rowindex][1]) || data[rowindex][1].toString().trim() === '') {
          data[rowindex][7] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';

          if (data[rowindex][1] != undefined) {
            if (data[rowindex][1].toString().trim() === '') {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Zone Name is empty" : data[rowindex][8] + ",  Zone Name is empty");
            }
            else if (!(isNaN(data[rowindex][1]))) {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Zone Name should not be a number" : data[rowindex][8] + ",  Zone Name should not be a number");
            }
            if (data[rowindex][1].length > 60) {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Zone name should not exceed 60 characters" : data[rowindex][8] + ",  Zone name should not exceed 60 characters");
            }

          }
          else {
            data[rowindex][8] = (data[rowindex][8] == undefined ? "Zone name is required" : data[rowindex][8] + ", Zone name is required");
          }
        }

        // check company code column values
        if (data[rowindex][2] === undefined || isNaN(data[rowindex][2]) || data[rowindex][2] < 0 || data[rowindex][2].toString().includes('.') || data[rowindex][2].toString().length > 4 || data[rowindex][2].toString().trim() === '') {
          data[rowindex][7] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          if (data[rowindex][2] !== undefined) {
            if (data[rowindex][2].toString().trim() === '') {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Company Code is empty" : data[rowindex][8] + ", Company Code is empty");
            }
            if (isNaN(data[rowindex][2])) {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Invalid company code" : data[rowindex][8] + ", Invalid company code");
            }
            else if (data[rowindex][2].toString().length > 4) {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Company code should not exceed 4 digits" : data[rowindex][8] + ", Company code should not exceed 4 digits")
            }
            if (data[rowindex][2] < 0) {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Company Code should not be less than Zero" : data[rowindex][8] + ", Company Code should not be less than Zero")
            }
            if (data[rowindex][2].toString().includes('.')) {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Company Code should not contain be a decimal number" : data[rowindex][8] + ", Company Code should not contain be a decimal number")
            }
          }
          else
            data[rowindex][8] = (data[rowindex][8] == undefined ? "Company code is required" : data[rowindex][8] + ", Company code is required");
        }
        if (data[rowindex][3] === undefined || (data[rowindex][3].length > 100) || !isNaN(data[rowindex][3]) || data[rowindex][3].toString().trim() === '') {
          data[rowindex][7] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          if (data[rowindex][3] != undefined) {
            if (data[rowindex][3].toString().trim() === '') {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Company name is empty" : data[rowindex][8] + ",  Company name is empty");
            }
            else if (!(isNaN(data[rowindex][3]))) {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Company name should not be a number" : data[rowindex][8] + ",  Company name should not be a number");
            }
            if (data[rowindex][3].length > 100) {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Company name should not exceed 100 characters" : data[rowindex][8] + ",  Company name should not exceed 100 characters");
            }
          }
          else {
            data[rowindex][8] = (data[rowindex][8] == undefined ? "Company Name is required" : data[rowindex][8] + ", Company Name is required");
          }
        }
        if ((data[rowindex][4] == undefined) && (data[rowindex][5] == undefined)) {
          console.log('1st if');
          data[rowindex][7] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          data[rowindex][8] = (data[rowindex][8] == undefined ? "Start date or End date is required" : data[rowindex][8] + ", Start date or End date is required");
        }
        else if ((data[rowindex][4] !== undefined) && (data[rowindex][5] !== undefined)) {
          console.log('2nd if');
          data[rowindex][7] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          data[rowindex][8] = (data[rowindex][8] == undefined ? "Zone should not contain both start date and end date" : data[rowindex][8] + ", Zone should not contain both start date and end date");
          if ((!(isNaN(data[rowindex][4]))) && data[rowindex][4].toString().trim() != '') {
            let excelstartdate = new Date(((data[rowindex][4] - (25567 + 1)) * 86400 * 1000));
            data[rowindex][4] = new Date(excelstartdate.getFullYear(), excelstartdate.getMonth(), excelstartdate.getDate(), 0, 0, 0);
          }
          if ((!(isNaN(data[rowindex][5]))) && data[rowindex][5].toString().trim() != '') {
            let excelenddate = new Date(((data[rowindex][5] - (25567 + 1)) * 86400 * 1000));
            data[rowindex][5] = new Date(excelenddate.getFullYear(), excelenddate.getMonth(), excelenddate.getDate(), 0, 0, 0);

          }
        }
        else if (data[rowindex][4] !== undefined) {
          console.log('3rd if');
          if (isNaN(data[rowindex][4]) || data[rowindex][4] < 0 || data[rowindex][4].toString().trim() === '') {
            data[rowindex][7] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            if (data[rowindex][4] !== undefined) {
              if (data[rowindex][4].toString().trim() === '') {
                data[rowindex][8] = (data[rowindex][8] == undefined ? "Start Date is empty" : data[rowindex][8] + ", Start Date is empty");
              }
              if (isNaN(data[rowindex][4])) {
                data[rowindex][8] = (data[rowindex][8] == undefined ? "Start Date is invalid" : data[rowindex][8] + ", Start Date is invalid");
              }
              if (data[rowindex][4] < 0) {
                data[rowindex][8] = (data[rowindex][8] == undefined ? "Start Date is invalid" : data[rowindex][8] + ", Start Date is invalid")
              }
            }
          }
          else if (!(isNaN(data[rowindex][4]))) {
            console.log('4th if');
            const date = data[rowindex][4];
            const exceldate = new Date(((date - (25567 + 1)) * 86400 * 1000));
            const uscurrentdate = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' }));
            let newdate1 = new Date(exceldate.getFullYear(), exceldate.getMonth(), exceldate.getDate());
            let newdate2 = new Date(exceldate.getFullYear(), exceldate.getMonth(), exceldate.getDate(), 0, 0, 0);
            let newdate3 = new Date(uscurrentdate.getFullYear(), uscurrentdate.getMonth(), uscurrentdate.getDate(), 0, 0, 0);
            console.log("newdate-zone", newdate1);
            console.log("newdate-zone", newdate2);
            console.log("exceldate-zone", exceldate);
            console.log("uscurrentdate-zone", uscurrentdate);
            console.log("uscurrentdate-newdate3-zone", newdate3);
            const excelDateString = exceldate.toDateString();
            data[rowindex][4] = (new Date(excelDateString));
            if (newdate3 > newdate2) {
              data[rowindex][7] = 'Failed';
              validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Start date should not contain past dates" : data[rowindex][8] + ", Start date should not contain past dates")
            }
          }
        }
        else if (data[rowindex][5] !== undefined || data[rowindex][5].toString().trim() != '') {
          console.log('5th if');
          //enddate
          if (!(data[rowindex][5] === undefined || data[rowindex][5].toString().trim() === '')) {
            if (isNaN(data[rowindex][5]) || data[rowindex][5] < 0) {
              data[rowindex][7] = 'Failed';
              validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
              data[rowindex][8] = (data[rowindex][8] == undefined ? "End date is Invalid" : data[rowindex][8] + ", End date is Invalid");
            }
            else {
              const excelenddate = new Date(((data[rowindex][5] - (25567 + 1)) * 86400 * 1000))
              const uscurrentdate = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' }));
              console.log('excelenddate', excelenddate)
              console.log('uscurrentdate', uscurrentdate)
              console.log('uscurrentdate', uscurrentdate)
              let newdate1 = new Date(excelenddate.getFullYear(), excelenddate.getMonth(), excelenddate.getDate(), 0, 0, 0);
              let newdate3 = new Date(uscurrentdate.getFullYear(), uscurrentdate.getMonth(), uscurrentdate.getDate(), 0, 0, 0);
              const excelDateString = excelenddate.toDateString();
              data[rowindex][5] = (new Date(excelDateString));
              if (newdate3 > newdate1) {
                data[rowindex][7] = 'Failed';
                validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
                data[rowindex][8] = (data[rowindex][8] == undefined ? "End date should not contain past date" : data[rowindex][8] + ", End date should not contain past date")
              }
            }
          }
        }

        // check comments
        if (data[rowindex][6] !== undefined) {
          if (data[rowindex][6].length > 20) {
            data[rowindex][7] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            data[rowindex][8] = (data[rowindex][8] == undefined ? "Comments should not exceed 20 characters" : data[rowindex][8] + ", Comments should not exceed 20 characters");
          }
        }
      }
    }
    return data;
  }

  // validatestorezone storezone sheet
  const validateStoreZone = (data: any) => {
    console.log("inside validateStoreZone()-() ", data);
    const stateregex = /^[A-Z]{2}?$/;
    const storetyperegex = /^[A-Z]{3}?$/;
    var newData: any = [];
    var newData: any = [];
    for (let i = 1; i < data.length; i++) {
      if (data[i].length == 0)
        break
      if (data[i][0] != undefined
      ) {
        newData.push(
          (
            String(data[i][0])
          ));
      }
      else {
        newData.push(
          (
            ''
          ));
      }
    }
    console.log('newData', newData)

    var map: any = {};
    var duplicates = [];
    for (var i = 0; i < newData.length; i++) {
      var element = newData[i];
      if (!map[element]) {
        map[element] = [i];
      }
      else {
        map[element].push(i);
        duplicates.push(i + 1)
      }
    }

    console.log('newData', newData);
    console.log('map', map);
    console.log('duplicates', duplicates);

    for (let rowindex = 1; rowindex < data.length; rowindex++) {
      if (data[rowindex].length == 0)
        return data;
      console.log('rowindex', rowindex);
      console.log('rowindex', duplicates.includes(rowindex));
      if (data[rowindex][0] == undefined
      ) {
        data[rowindex][7] = 'Failed';
        validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
        data[rowindex][8] = "Store Number is required";
        data[rowindex][9] = undefined;
        data[rowindex][10] = undefined;
        if (data[rowindex][6] != undefined) {
          if (!(isNaN(data[rowindex][6]))) {
            let exceldate = new Date(((data[rowindex][6] - (25567 + 1)) * 86400 * 1000));
            const excelDateString = exceldate.toDateString();
            data[rowindex][6] = new Date(excelDateString);
          }
        }

      }
      else if (duplicates.includes(rowindex)) {
        data[rowindex][7] = 'Failed';
        validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
        data[rowindex][8] = "Store Number is Repeated";
        data[rowindex][9] = undefined;
        data[rowindex][10] = undefined;
        if (data[rowindex][6] != undefined) {
          if (!(isNaN(data[rowindex][6]))) {
            let exceldate = new Date(((data[rowindex][6] - (25567 + 1)) * 86400 * 1000));
            const excelDateString = exceldate.toDateString();
            data[rowindex][6] = new Date(excelDateString);
          }
        }
      }
      else {


        // check store  number column values
        if (data[rowindex][0] === undefined || isNaN(data[rowindex][0]) || data[rowindex][0].toString().length > 5 || data[rowindex][0] < 0 || data[rowindex][0].toString().includes('.') || data[rowindex][0].toString().trim() === '') {

          data[rowindex][7] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          data[rowindex][9] = undefined;
          data[rowindex][10] = undefined;
          if (data[rowindex][0] !== undefined) {
            if (data[rowindex][0].toString().trim() === '') {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Store Number is empty" : data[rowindex][8] + ", Store Number is empty");
            }
            if (isNaN(data[rowindex][0])) {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Invalid Store number" : data[rowindex][8] + ", Invalid Store number");
            }
            else if (data[rowindex][0].toString().length > 5) {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Store number should not exceed 5 digits" : data[rowindex][8] + ", Store number should not exceed 5 digits")
            }
            if (data[rowindex][0] < 0) {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Store Number should not be less than Zero" : data[rowindex][8] + ", Store Number should not be less than Zero")
            }
            if (data[rowindex][0].toString().includes('.')) {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Store Number should not be a decimal number" : data[rowindex][8] + ", Store Number should not be a decimal number")
            }
          }
          else
            data[rowindex][8] = (data[rowindex][8] == undefined ? "Store number is required" : data[rowindex][8] + ", Store number is required");

        }
        else {
          data[rowindex][7] = 'Passed';
          data[rowindex][8] = undefined;
          data[rowindex][9] = undefined;
          data[rowindex][10] = undefined;

        }
        // check Zone  number column values
        if (data[rowindex][1] === undefined || isNaN(data[rowindex][1]) || data[rowindex][1] < 0 || data[rowindex][1].toString().includes('.') || data[rowindex][1].toString().length > 5 || data[rowindex][1].toString().trim() === '') {
          data[rowindex][7] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          if (data[rowindex][1] !== undefined) {


            if (data[rowindex][1].toString().trim() === '') {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Zone Number is empty" : data[rowindex][8] + ", Zone Number is empty");
            }
            if (isNaN(data[rowindex][1])) {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Invalid zone number" : data[rowindex][8] + ", Invalid zone number");
            }
            else if (data[rowindex][1].toString().length > 5) {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Zone number should not exceed 5 digits" : data[rowindex][8] + ", Zone number should not exceed 5 digits")
            }
            if (data[rowindex][1] < 0) {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Zone Number should not be less than Zero" : data[rowindex][8] + ", Zone Number should not be less than Zero")
            }
            if (data[rowindex][1].toString().includes('.')) {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Zone Number should not be a decimal number" : data[rowindex][8] + ", Zone Number should not be a decimal number")
            }
          }
          else
            data[rowindex][8] = (data[rowindex][8] == undefined ? "Zone Number is required" : data[rowindex][8] + ", Zone Number is required");
        }

        // check Company name column values
        if (data[rowindex][2] === undefined || (data[rowindex][2].length > 100) || !isNaN(data[rowindex][2]) || data[rowindex][2].toString().trim() === '') {
          data[rowindex][7] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';

          if (data[rowindex][2] != undefined) {
            if (data[rowindex][2].toString().trim() === '') {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Company name is empty" : data[rowindex][8] + ", Company name is empty");
            }
            else if (!(isNaN(data[rowindex][2]))) {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Company name should not be a number" : data[rowindex][8] + ", Company name should not be a number");
            }
            if (data[rowindex][2].length > 100) {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Company name should not exceed 100 characters" : data[rowindex][8] + ", Company name should not exceed 100 characters");
            }

          }
          else {
            data[rowindex][8] = (data[rowindex][8] == undefined ? "Company Name is required" : data[rowindex][8] + ", Company Name is required");
          }
        }

        // store type
        if (data[rowindex][3] == undefined) {
          data[rowindex][7] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          data[rowindex][8] = (data[rowindex][8] == undefined ? "Store Type is required" : data[rowindex][8] + ", Store Type is required");
        }
        else if (!(data[rowindex][3].toString().match(storetyperegex))) {
          console.log('Store Type else', data[rowindex][3])
          data[rowindex][7] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          if (!isNaN(data[rowindex][3])) {
            data[rowindex][8] = (data[rowindex][8] == undefined ? "Store Type should not be a number" : data[rowindex][8] + ", Store Type should not be a number");
          }
          else {
            data[rowindex][8] = (data[rowindex][8] == undefined ? "Store Type is Invalid" : data[rowindex][8] + ", Store Type is Invalid");
          }
        }

        // check state column values
        if (data[rowindex][4] === undefined || data[rowindex][4].length > 2 || !isNaN(data[rowindex][4]) || data[rowindex][4] !== data[rowindex][4].toUpperCase() || data[rowindex][4].toString().trim() === '') {//                           console.log("check D",rowindex+1,j+1,data[rowindex][j])
          console.log('state if', data[rowindex][4])

          data[rowindex][7] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          if (data[rowindex][4] != undefined) {
            if (data[rowindex][4].toString().trim() === '') {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "State is empty" : data[rowindex][8] + ", State is empty");
            }
            data[rowindex][7] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            if (data[rowindex][4].length < 3 && isNaN(data[rowindex][4])) {
              console.log('inside')
              if (data[rowindex][4] !== data[rowindex][4].toUpperCase()) {
                data[rowindex][8] = (data[rowindex][8] == undefined ? "State should be in uppercase only" : data[rowindex][8] + ", State should be in uppercase only");
              }
            }
            if (data[rowindex][4].length > 2) {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "State should not exceed 2 characters" : data[rowindex][8] + ", State should not exceed 2 characters");
            }

          }
          else {
            data[rowindex][8] = (data[rowindex][8] == undefined ? "State is required" : data[rowindex][8] + ", State is required");
          }

        }
        else if (!(data[rowindex][4].toString().match(stateregex))) {
          console.log('state else', data[rowindex][4])
          data[rowindex][7] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          data[rowindex][8] = (data[rowindex][8] == undefined ? "State is Invalid" : data[rowindex][8] + ", State is Invalid")
        }

        //compay code
        if (data[rowindex][5] === undefined || isNaN(data[rowindex][5]) || data[rowindex][5] < 0 || data[rowindex][5].toString().includes('.') || data[rowindex][5].toString().length > 4 || data[rowindex][5].toString().trim() === '') {
          data[rowindex][7] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          if (data[rowindex][5] !== undefined) {
            if (data[rowindex][5].toString().trim() === '') {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Company Code is empty" : data[rowindex][8] + ", Company Code is empty");
            }
            if (isNaN(data[rowindex][5])) {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Invalid company code" : data[rowindex][8] + ", Invalid company code");
            }
            else if (data[rowindex][5].toString().length > 4) {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Company code should not exceed 4 digits" : data[rowindex][8] + ", Company code should not exceed 4 digits")
            }
            if (data[rowindex][5] < 0) {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Company Code should not be less than Zero" : data[rowindex][8] + ", Company Code should not be less than Zero")
            }
            if (data[rowindex][5].toString().includes('.')) {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Company Code should not be a decimal number" : data[rowindex][8] + ", Company Code should not be a decimal number")
            }
          }
          else
            data[rowindex][8] = (data[rowindex][8] == undefined ? "Company code is required" : data[rowindex][8] + ", Company code is required");
        }
        console.log('before typeof', typeof (data[rowindex][6]));

        // start date
        if (data[rowindex][6] === undefined || isNaN(data[rowindex][6]) || data[rowindex][6] < 0 || data[rowindex][6].toString().trim() === '') {
          data[rowindex][7] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          if (data[rowindex][6] !== undefined) {
            if (data[rowindex][6].toString().trim() === '') {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Start Date is empty" : data[rowindex][8] + ", Start Date is empty");
            }
            if (isNaN(data[rowindex][6])) {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Start Date is invalid" : data[rowindex][8] + ", Start Date is invalid");
            }
            if (data[rowindex][6] < 0) {
              data[rowindex][8] = (data[rowindex][8] == undefined ? "Start Date is invalid" : data[rowindex][8] + ", Start Date is invalid")
            }


          }
          else
            data[rowindex][8] = (data[rowindex][8] == undefined ? "Start date is required" : data[rowindex][8] + ", Start date is required");
        }
        else if (!(isNaN(data[rowindex][6]))) {
          const date = data[rowindex][6];
          const exceldate = new Date(((date - (25567 + 1)) * 86400 * 1000));
          console.log('exceldate', new Date(((date - (25567 + 1)) * 86400 * 1000)));
          console.log('exceldate', exceldate);
          console.log('typeof exceldate', typeof exceldate);
          const uscurrentdate = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' }));
          console.log('before', data[rowindex][6])
          let newdate1 = new Date(exceldate.getFullYear(), exceldate.getMonth(), exceldate.getDate(), 0, 0, 0);
          let newdate2 = new Date(uscurrentdate.getFullYear(), uscurrentdate.getMonth(), uscurrentdate.getDate(), 0, 0, 0);
          console.log('exceldate', exceldate);
          const excelDateString = exceldate.toDateString();
          data[rowindex][6] = new Date(excelDateString);
          console.log('exceldate', data[rowindex][6]);
          console.log('after', data[rowindex][6]);
          console.log('after new date', new Date(data[rowindex][6]));

          console.log('after typeof', typeof (data[rowindex][6]));
          if (newdate2 > newdate1) {
            data[rowindex][7] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            data[rowindex][8] = (data[rowindex][8] == undefined ? "Start date should not contain past dates" : data[rowindex][8] + ", Start date should not contain past dates");
          }
        }
        console.log('after typeof after', typeof (data[rowindex][6]));

      }
    }


    return data;
  }
  const tranformDate = (date: any) => {
    const exceldate = new Date(((date - (25567 + 2)) * 86400 * 1000))
    const result = new Date(exceldate.toISOString().slice(0, -1));
    return result;
  }
  function isNotValidInput(input : any) {
    // Check if the input is not undefined and not an empty string
    return input == undefined || input.toString().trim() == '';
}
function isValidInput(input : any){
  return input != undefined && input.toString().trim() != '';
}
  // //validatePricing validates Pricing sheet
  const validatePricing = (data: any) => {    /* 
    Column no - Column Name
    0  Zone 
    1  Type
    2  Department
    3  Sub Department 
    4  Bracket
    5  Rms item number
    6  weekly rate new 
    7  weekly rate used
    8  Add on weekly rate
    9  Term
    10 Cash price Factor
    11 Forced cash price
    12 Manual PO turns
    13 Start Date
    14 End Date
    15 Validation status
    16 Validation comments 
    17 Upload status
    18 Upload comments
    */
    const rateregexp = /^([0-9]{1,5})?(\.[0-9]{0,2})?$/;
    const cashpriceregexp = /^([0]{1})?(\.[0-9]{0,3})?$/;
    const manualturnsregexp = /^([1-9]{1})?(\.[0-9]{0,3})?$/;
    const forcedcashpriceregexp = /^([0-9]{1,7})?(\.[0-9]{0,3})?$/;
    var newData: any = [];
    for (let i = 1; i < data.length; i++) {
      if (data[i].length == 0)
        break
      if (isValidInput(data[i][ZONE_INDEX]) && isValidInput(data[i][TYPE_INDEX]) && (isValidInput(data[i][DEPARTMENT_INDEX]) || isValidInput(data[i][SUB_DEPARTMENT_INDEX]) || isValidInput(data[i][BRACKET_INDEX]) || isValidInput(data[i][RMS_ITEM_NUMBER_INDEX]))) {
        newData.push((
          String(data[i][ZONE_INDEX]) +
          String(data[i][TYPE_INDEX]) +
          String(data[i][DEPARTMENT_INDEX] ?? '') +
          String(data[i][SUB_DEPARTMENT_INDEX] ?? '') +
          String(data[i][BRACKET_INDEX] ?? '') +
          String(data[i][RMS_ITEM_NUMBER_INDEX] ?? '') +
          String(data[i][WEEKLY_RATE_NEW_INDEX] ?? '') +
          String(data[i][WEEKLY_RATE_USED_INDEX] ?? '') +
          String(data[i][ADDON_WEEKLY_RATE_INDEX] ?? '') +
          String(data[i][TERM_INDEX] ?? '') +
          String(data[i][CASH_PRICE_FACTOR_INDEX] ?? '') +
          String(data[i][FORCED_CASH_PRICE_INDEX] ?? '') +
          String(data[i][MANUALPO_TURNS_INDEX] ?? '') +
          String(data[i][START_DATE_INDEX] ?? '')
        ));
      }
      else {
        newData.push('');
      }
    }

    var map: any = {};
    var duplicates = [];
    for (var i = 0; i < newData.length; i++) {
      var element = newData[i];
      if (!map[element]) {
        map[element] = [i];
      }
      else {
        map[element].push(i);
        duplicates.push(i + 1)
      }
    }
    /* Loops through the each rows for validation */
    for (let rowindex = 1; rowindex < data.length; rowindex++) {
      console.log('The data in the row'+ rowindex , data[i])
      if (data[rowindex].length == 0)
        return data;
      /* Validates the Zone, Type and hierarchy levels */
      if ((isNotValidInput(data[rowindex][ZONE_INDEX]) && isNotValidInput(data[rowindex][TYPE_INDEX])) && (isNotValidInput(data[rowindex][DEPARTMENT_INDEX]) && isNotValidInput(data[rowindex][SUB_DEPARTMENT_INDEX]) && isNotValidInput(data[rowindex][BRACKET_INDEX])&& isNotValidInput(data[rowindex][RMS_ITEM_NUMBER_INDEX]))) {
        data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
        validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
        data[rowindex][VALIDATION_COMMENTS_INDEX] = " Zone, Type, and at least one of the following: Department, Sub-Department, Bracket or RMS Number is Required ";
        data[rowindex][UPLOAD_STATUS_INDEX] = undefined;
        data[rowindex][UPLOAD_COMMENTS_INDEX] = undefined;

        if (data[rowindex][START_DATE_INDEX] != undefined) {
          if (!(isNaN(data[rowindex][START_DATE_INDEX]))) {
            data[rowindex][START_DATE_INDEX] = tranformDate(data[rowindex][START_DATE_INDEX]);
          }
        }
        if (data[rowindex][END_DATE_INDEX] != undefined) {
          if (!(isNaN(data[rowindex][END_DATE_INDEX]))) {
            const date = data[rowindex][END_DATE_INDEX];
            const exceldate = new Date(((date - (25567 + 2)) * 86400 * 1000))
            const result = new Date(exceldate.toISOString().slice(0, -1));
            console.log("end date result", result);
            data[rowindex][END_DATE_INDEX] = result;
          }
        }

      }
      else if(isValidInput(data[rowindex][RMS_ITEM_NUMBER_INDEX]) && (isValidInput(data[rowindex][BRACKET_INDEX]) || isValidInput(data[rowindex][SUB_DEPARTMENT_INDEX] ) || isValidInput(DEPARTMENT_INDEX))){
        data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
        validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
        data[rowindex][VALIDATION_COMMENTS_INDEX] = "RMS Number does not require other hierarchy level information.";
        data[rowindex][UPLOAD_STATUS_INDEX] = undefined;
        data[rowindex][UPLOAD_COMMENTS_INDEX] = undefined;
        if (data[rowindex][START_DATE_INDEX] != undefined) {
          if (!(isNaN(data[rowindex][START_DATE_INDEX]))) {
            data[rowindex][START_DATE_INDEX] = tranformDate(data[rowindex][START_DATE_INDEX]);
          }
        }
        if (data[rowindex][END_DATE_INDEX] != undefined) {
          if (!(isNaN(data[rowindex][END_DATE_INDEX]))) {
            data[rowindex][END_DATE_INDEX] = tranformDate(data[rowindex][END_DATE_INDEX]);
          }
        }
      }
      else if (isNotValidInput(data[rowindex][DEPARTMENT_INDEX]) && isNotValidInput(data[rowindex][SUB_DEPARTMENT_INDEX]) && isNotValidInput( data[rowindex][BRACKET_INDEX]) && isNotValidInput(data[rowindex][RMS_ITEM_NUMBER_INDEX])) {
        data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
        validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
        data[rowindex][VALIDATION_COMMENTS_INDEX] = "At least one of the following: Department, Sub-Department, Bracket or RMS Number is Required ";
        data[rowindex][UPLOAD_STATUS_INDEX] = undefined;
        data[rowindex][UPLOAD_COMMENTS_INDEX] = undefined;
        if (data[rowindex][START_DATE_INDEX] != undefined) {
          if (!(isNaN(data[rowindex][START_DATE_INDEX]))) {
            data[rowindex][START_DATE_INDEX] = tranformDate(data[rowindex][START_DATE_INDEX]);
          }
        }
        if (data[rowindex][END_DATE_INDEX] != undefined) {
          if (!(isNaN(data[rowindex][END_DATE_INDEX]))) {
            data[rowindex][END_DATE_INDEX] = tranformDate(data[rowindex][END_DATE_INDEX]);
          }
        }
      }
      else if (isNotValidInput(data[rowindex][DEPARTMENT_INDEX] )) {
        if (isValidInput(data[rowindex][BRACKET_INDEX]) ) {
          data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          data[rowindex][VALIDATION_COMMENTS_INDEX] = "Department and Sub-department are required when a Bracket is provided.";
          data[rowindex][UPLOAD_STATUS_INDEX] = undefined;
          data[rowindex][UPLOAD_COMMENTS_INDEX] = undefined;
        }
        else if (isValidInput(data[rowindex][SUB_DEPARTMENT_INDEX])) {
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          data[rowindex][VALIDATION_COMMENTS_INDEX] = " Department is required when Sub Department is provided ";
          data[rowindex][UPLOAD_STATUS_INDEX] = undefined;
          data[rowindex][UPLOAD_COMMENTS_INDEX] = undefined;
        }
        if ((data[rowindex][START_DATE_INDEX]) != undefined) {
          if (!(isNaN(data[rowindex][START_DATE_INDEX]))) {
            data[rowindex][START_DATE_INDEX] = tranformDate(data[rowindex][START_DATE_INDEX]);
          }
        }
        if (data[rowindex][END_DATE_INDEX] != undefined) {
          if (!(isNaN(data[rowindex][END_DATE_INDEX]))) {
            data[rowindex][END_DATE_INDEX] = tranformDate(data[rowindex][END_DATE_INDEX]);
          }
        }
      }
      else if (isNotValidInput(data[rowindex][SUB_DEPARTMENT_INDEX] ) && isValidInput(data[rowindex][BRACKET_INDEX] )) {
        validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
        data[rowindex][VALIDATION_COMMENTS_INDEX] = "Department and Sub-department are required when a Bracket is provided.";
        data[rowindex][UPLOAD_STATUS_INDEX] = undefined;
        data[rowindex][UPLOAD_COMMENTS_INDEX] = undefined;
        if (data[rowindex][START_DATE_INDEX] != undefined) {
          if (!(isNaN(data[rowindex][START_DATE_INDEX]))) {
            data[rowindex][START_DATE_INDEX] = tranformDate(data[rowindex][START_DATE_INDEX]);
          }
        }
        if (data[rowindex][END_DATE_INDEX] != undefined) {
          if (!(isNaN(data[rowindex][END_DATE_INDEX]))) {
            data[rowindex][END_DATE_INDEX] = tranformDate(data[rowindex][END_DATE_INDEX]);
          }
        }
      }
      else if (duplicates.includes(rowindex)) {
        data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
        validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
        data[rowindex][VALIDATION_COMMENTS_INDEX] = "RMS number is Repeated";
        data[rowindex][UPLOAD_COMMENTS_INDEX] = undefined;
        data[rowindex][UPLOAD_STATUS_INDEX] = undefined;
        if (data[rowindex][START_DATE_INDEX] != undefined) {
          if (!(isNaN(data[rowindex][START_DATE_INDEX]))) {
            data[rowindex][START_DATE_INDEX] = tranformDate(data[rowindex][START_DATE_INDEX]);
          }
        }
        if (data[rowindex][END_DATE_INDEX] != undefined) {
          if (!(isNaN(data[rowindex][END_DATE_INDEX]))) {
            data[rowindex][END_DATE_INDEX] = tranformDate(data[rowindex][END_DATE_INDEX]);
          }
        }
      }
      else {
        let validationFlag : boolean = false;
        let newterm = -1, newforcedcashprice = -1, newweeklyratenew = -1, newweeklyrateused = -1;
        /* zone & other validations */
        if (data[rowindex][ZONE_INDEX] === undefined || isNaN(data[rowindex][ZONE_INDEX]) || data[rowindex][ZONE_INDEX] < 0 || data[rowindex][ZONE_INDEX]?.toString().includes('.') || data[rowindex][ZONE_INDEX]?.toString().length > 5 || data[rowindex][ZONE_INDEX]?.toString()=== '') {
          data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
          // data[rowindex][16] = "check1";
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          data[rowindex][UPLOAD_COMMENTS_INDEX] = undefined;
          data[rowindex][UPLOAD_STATUS_INDEX] = undefined;
          if (data[rowindex][ZONE_INDEX] !== undefined) {

            if (data[rowindex][ZONE_INDEX]?.toString().trim() === '') {
              data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Zone Number is empty" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Zone Number is empty");
            }
            if (isNaN(data[rowindex][ZONE_INDEX])) {
              data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Invalid zone number" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Invalid zone number");
            }
            else if (data[rowindex][ZONE_INDEX]?.toString().length > 5) {
              data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Zone number should not exceed 5 digits" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Zone number should not exceed 5 digits")
            }
            if (data[rowindex][ZONE_INDEX] < 0) {
              data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Zone Number should not be less than Zero" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Zone Number should not be less than Zero")
            }

            if (data[rowindex][ZONE_INDEX]?.toString().includes('.')) {
              data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Zone Number should not be a decimal number" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Zone Number should not be a decimal number")
            }
          }
          else
            data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Zone Number is required" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Zone Number is required");
        }
        else {//Flow where the zone validation passed.
          // Check if zone exists in the array
          const index = zoneRecordsCountArray.findIndex((item: ZoneRecordsCount) => item.zone === Number(data[rowindex][ZONE_INDEX]));

          if (index !== -1) {
            // If zone exists, increment recordsCount by 1
            zoneRecordsCountArray[index].recordsCount++;
          } else {
            // If zone doesn't exist, create a new object and add it to the array
            zoneRecordsCountArray.push({
              zone: Number(data[rowindex][ZONE_INDEX]),
              recordsCount: 1
            });
          }
          data[rowindex][VALIDATION_STATUS_INDEX] = 'Passed';
          data[rowindex][VALIDATION_COMMENTS_INDEX] = undefined;
          data[rowindex][UPLOAD_COMMENTS_INDEX] = undefined;
          data[rowindex][UPLOAD_STATUS_INDEX] = undefined;
        }
        /* pricing type validation */
        // eslint-disable-next-line sonarjs/no-collapsible-if
        if (data[rowindex][TYPE_INDEX] !== undefined) {
          const type = ["PERMANENT", "TEMPORARY", "MANUAL"]
          if (!(type.includes(data[rowindex][TYPE_INDEX]))) {
            data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Type should be PERMANENT, TEMPORARY or MANUAL " : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Type should be PERMANENT, TEMPORARY or MANUAL");
          }
        }
        else if (data[rowindex][TYPE_INDEX] == undefined) {
          data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Type is required" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Type is required");

        }
        /* validate weekly rate new */
        if(isValidInput(data[rowindex][WEEKLY_RATE_NEW_INDEX])){
          if(isNaN(data[rowindex][WEEKLY_RATE_NEW_INDEX])){
            data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Weekly rate new is invalid" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Weekly rate new is invalid ");

          }
          else if (!(isNaN(data[rowindex][WEEKLY_RATE_NEW_INDEX])) && isValidInput(data[rowindex][WEEKLY_RATE_NEW_INDEX])) {
            data[rowindex][WEEKLY_RATE_NEW_INDEX]=parseFloat(Number(data[rowindex][WEEKLY_RATE_NEW_INDEX]).toFixed(2));
            if (!(data[rowindex][WEEKLY_RATE_NEW_INDEX]?.toString()?.match(rateregexp))) {
            data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Weekly rate new length exceeded" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Weekly rate new length exceeded")
          }
          else {
          validationFlag = true
          newweeklyratenew = data[rowindex][WEEKLY_RATE_NEW_INDEX];
        }
        }
         
        }
        /* validate weekly rate used  */
        if(isValidInput(data[rowindex][WEEKLY_RATE_USED_INDEX])){
          if(isNaN(data[rowindex][WEEKLY_RATE_USED_INDEX])){
            data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Weekly rate used is invalid" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Weekly rate used is invalid ");

          }
          else if (!(isNaN(data[rowindex][WEEKLY_RATE_USED_INDEX]))) {
            data[rowindex][WEEKLY_RATE_USED_INDEX]=parseFloat((data[rowindex][WEEKLY_RATE_USED_INDEX]).toFixed(2));
            if (!(data[rowindex][WEEKLY_RATE_USED_INDEX]?.toString()?.match(rateregexp))) {
            data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Weekly rate used length exceeded" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Weekly rate used length exceeded")
          }
          else {
            newweeklyrateused=  data[rowindex][WEEKLY_RATE_USED_INDEX]
            validationFlag = true
        }
          }
        }
        /* validate term */
        if(isValidInput(data[rowindex][TERM_INDEX])){
          if(isNaN(data[rowindex][TERM_INDEX])){
            data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Term is invalid" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Term is invalid ");
          }
          else if(!(isNaN(data[rowindex][TERM_INDEX])) ){
            if( data[rowindex][TERM_INDEX].toString().includes('.') || data[rowindex][TERM_INDEX] < 0 ){
            if (data[rowindex][TERM_INDEX].toString().includes('.')) {
              data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Term should not contain be a decimal number" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Term should not contain be a decimal number")
            }
            if (data[rowindex][TERM_INDEX] < 0) {
              data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Term should not be less than Zero" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Term should not be less than Zero")
            }
          }
          else{ 
          validationFlag = true
          newterm = Number(data[rowindex][TERM_INDEX])
          }}
        }
        /* validate Forced cash price & cash price factor */
        if(isValidInput( data[rowindex][CASH_PRICE_FACTOR_INDEX] ) && isValidInput(data[rowindex][FORCED_CASH_PRICE_INDEX] )){
          data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Either Cash Price Factor or Forced Cash price should exist" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Either Cash Price Factor or Forced Cash price should exist")
        }
        else if(isValidInput(data[rowindex][CASH_PRICE_FACTOR_INDEX] ) || isValidInput(data[rowindex][FORCED_CASH_PRICE_INDEX] )){

        if (data[rowindex][CASH_PRICE_FACTOR_INDEX] !== undefined) {
          if (isNaN(data[rowindex][CASH_PRICE_FACTOR_INDEX]) || data[rowindex][CASH_PRICE_FACTOR_INDEX].toString() === '') {

            if (data[rowindex][CASH_PRICE_FACTOR_INDEX] !== undefined) {
              if (isNaN(data[rowindex][CASH_PRICE_FACTOR_INDEX])) {
                data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
                validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';

                data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Invalid Cash Price Factor" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Invalid Cash Price Factor ");
              }
            }
            
          }
          else if (!(data[rowindex][CASH_PRICE_FACTOR_INDEX]?.toString()?.match(cashpriceregexp))) {
            data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Cash price factor length exceeded" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Cash price factor length exceeded")
          }
          else {
            validationFlag = true
          }

        }
        if (data[rowindex][FORCED_CASH_PRICE_INDEX] !== undefined) {

          if (isNaN(data[rowindex][FORCED_CASH_PRICE_INDEX]) || data[rowindex][FORCED_CASH_PRICE_INDEX].toString() === '') {
            if (isValidInput(data[rowindex][FORCED_CASH_PRICE_INDEX] )) {

              if (isNaN(data[rowindex][FORCED_CASH_PRICE_INDEX])) {
                data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
                validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';

                data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Invalid Forced cash price" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Invalid Forced cash price");
              }
            }
          }
          else if (!(data[rowindex][FORCED_CASH_PRICE_INDEX]?.toString()?.match(forcedcashpriceregexp))) {
            data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Forced cash price  length exceeded" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Forced cash price  length exceeded")
          }
          else {
            validationFlag = true
            newforcedcashprice = data[rowindex][FORCED_CASH_PRICE_INDEX]
          }
          
        }
        }
        /* Validate start date */
        let startdate: Date = new Date();
        if (data[rowindex][START_DATE_INDEX] === undefined || isNaN(data[rowindex][START_DATE_INDEX]) || data[rowindex][START_DATE_INDEX] < 0 || data[rowindex][START_DATE_INDEX].toString().trim() === '') {
          data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';

          if (data[rowindex][START_DATE_INDEX] !== undefined) {
            console.log('data[rowindex][10]', data[rowindex][START_DATE_INDEX])
            console.log('data[rowindex][10]', isNaN(data[rowindex][START_DATE_INDEX]))
            if (data[rowindex][START_DATE_INDEX].toString().trim() === '') {
              data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Start Date is empty" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Start Date is empty");
            }
            if (isNaN(data[rowindex][START_DATE_INDEX])) {
              data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Start Date is invalid" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Start Date is invalid");
            }
            if (data[rowindex][START_DATE_INDEX] < 0) {
              data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Start Date is invalid" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Start Date is invalid")
            }
          }
          else
            data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Start date is required" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Start date is required");
        }
        else if (!(isNaN(data[rowindex][START_DATE_INDEX]))) {
          startdate = data[rowindex][START_DATE_INDEX];

          const uscurrentdate = new Date(new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' })).setHours(0,0,0,0));
          const result = tranformDate(data[rowindex][START_DATE_INDEX]);
          data[rowindex][START_DATE_INDEX] = result
          console.log("us current date", uscurrentdate)
          if (new Date(uscurrentdate.toDateString()) > new Date(result.toDateString())) {
            data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Start date should not contain past dates" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Start date should not contain past dates")
          }
        }
        /* Validation over a pricing type */
        if(data[rowindex][TYPE_INDEX] == "PERMANENT" || data[rowindex][TYPE_INDEX] == "TEMPORARY"){

          if (data[rowindex][ADDON_WEEKLY_RATE_INDEX] == undefined) {
            data[rowindex][ADDON_WEEKLY_RATE_INDEX]="";
          }
          else if(data[rowindex][ADDON_WEEKLY_RATE_INDEX] !== undefined && data[rowindex][ADDON_WEEKLY_RATE_INDEX] !== ''){
            if (isNaN(data[rowindex][ADDON_WEEKLY_RATE_INDEX])) {
              data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
              data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Invalid Add-On weekly rate" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Invalid Add-On weekly rate");
            }
            else if (!isNaN(data[rowindex][ADDON_WEEKLY_RATE_INDEX]) && !(data[rowindex][ADDON_WEEKLY_RATE_INDEX].toString().trim() === '')) {
              data[rowindex][ADDON_WEEKLY_RATE_INDEX]=parseFloat((data[rowindex][ADDON_WEEKLY_RATE_INDEX]).toFixed(2));
              if (!(data[rowindex][ADDON_WEEKLY_RATE_INDEX].toString().match(rateregexp))) {
                data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
                validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
                data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Add-On weekly rate length exceeded" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Add-On weekly rate length exceeded")
              }
              else 
            validationFlag = true
            }
            

          }
          /* Check Manual PO turns */
          if (isValidInput(data[rowindex][MANUALPO_TURNS_INDEX])) {
            data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Manual PO Turns is not required when type is PERMANENT OR TEMPORARY" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Manual PO Turns is not required when type is PERMANENT OR TEMPORARY")
          }
          /* Validate Term and weekly rates are exist */
            if(
              ((data[rowindex][WEEKLY_RATE_NEW_INDEX]
            ) && (!data[rowindex][WEEKLY_RATE_USED_INDEX] ||
           !data[rowindex][TERM_INDEX]) )
            || 

           ((data[rowindex][WEEKLY_RATE_USED_INDEX]
           ) && (!data[rowindex][WEEKLY_RATE_NEW_INDEX] ||
          !data[rowindex][TERM_INDEX]))

          || 
          ((data[rowindex][TERM_INDEX]
          ) && (!data[rowindex][WEEKLY_RATE_USED_INDEX] ||
         !data[rowindex][WEEKLY_RATE_NEW_INDEX]))
          ){
            data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Weekly Rate New, Weekly Rate Used, and Term should be present." : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", weekly rate new, weekly rate Used and term should be present")
              }
              else if (newterm != -1 && newforcedcashprice != -1 && newweeklyratenew != -1 && newweeklyrateused !== -1) {
                if ((newterm * newweeklyratenew < newforcedcashprice)) {
                  data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
                  validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
                  data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Forced cash price should be lesser than [term* weekly rate new] value" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Forced cash price should be lesser than [term*weekly rate new] value")
                }
                if ((newterm * newweeklyrateused < newforcedcashprice)) {
                  data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
                  validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
                  data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Forced cash price should be lesser than [term*weekly rate used] value" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Forced cash price should be lesser than [term*weekly rate used] value")
                }
              }
          
        }
        else if(data[rowindex][TYPE_INDEX] == "MANUAL"){
          if(data[rowindex][MANUALPO_TURNS_INDEX]!= undefined && data[rowindex][MANUALPO_TURNS_INDEX] !=''){

          if ( isNaN(data[rowindex][MANUALPO_TURNS_INDEX]) ) {
            data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
              if (isNaN(data[rowindex][MANUALPO_TURNS_INDEX])) {
                data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "MANUAL PO turns is invalid" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", MANUAL PO turns is invalid");
              }
          }
          else if (!(data[rowindex][MANUALPO_TURNS_INDEX].toString().match(manualturnsregexp))) {
            validationFlag = true
            data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "MANUAL PO turns length exceeded" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", MANUAL PO turns length exceeded")
          }
          else{
            validationFlag = true
          }
        }
          if(
            ((data[rowindex][WEEKLY_RATE_NEW_INDEX]
          ) && !data[rowindex][WEEKLY_RATE_USED_INDEX] )
          || 

         ((data[rowindex][WEEKLY_RATE_USED_INDEX]
         ) && !data[rowindex][WEEKLY_RATE_NEW_INDEX])

        || 
        ((data[rowindex][TERM_INDEX]
        ) && (data[rowindex][WEEKLY_RATE_USED_INDEX] ||
       data[rowindex][WEEKLY_RATE_NEW_INDEX]))
        ){
          data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Either Weekly Rate New, Weekly Rate Used, or Term should be present for manual pricing type." : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", Either Weekly Rate New, Weekly Rate Used, or Term should be present for manual pricing type")
            }
        if(isValidInput(data[rowindex][ADDON_WEEKLY_RATE_INDEX]) ){
          data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "Add-On Weekly Rate is not required for MANUAL Pricing Type " : data[rowindex][VALIDATION_COMMENTS_INDEX] + ",Add-On Weekly Rate is not required for MANUAL Pricing Type")
        }
        }
        if(!validationFlag){
          data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "At least one valid pricing parameter is required." : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", At least one valid pricing parameter is required.")
        }
        if (data[rowindex][TYPE_INDEX] == "TEMPORARY") {
          if (!(data[rowindex][END_DATE_INDEX] === undefined || data[rowindex][END_DATE_INDEX].toString().trim() === '')) {

            if (isNaN(data[rowindex][END_DATE_INDEX]) || data[rowindex][END_DATE_INDEX] < 0) {
              data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
              validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
              data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "End date is Invalid" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", End date is Invalid");
            }
            else if (!(isNaN(data[rowindex][END_DATE_INDEX]))) {
              const endate = data[rowindex][END_DATE_INDEX];
              let date = data[rowindex][END_DATE_INDEX];
              const uscurrentdate = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' }));
              var utc_days = Math.floor(date - 25569);
              var utc_value = utc_days * 86400;
              var date_info = new Date(new Date(utc_value * 1000).toISOString().slice(0,-1));

              var fractional_day = date - Math.floor(date) + 0.0000001;

              var total_seconds = Math.floor(86400 * fractional_day);

              var seconds = (total_seconds % 60);

              total_seconds -= seconds;

              var hours = Math.floor(total_seconds / (60 * 60));
              var minutes = (Math.floor(total_seconds / 60) % 60);
              const newexceldate = new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate())

              let newdate = new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
              console.log(moment(newdate).format('DD-MMM-YY h:mm:ss a'));

              console.log(new Date(newdate.toDateString()))
              // data[rowindex][11] = new Date(newdate.toDateString());

              console.log('uscurrentdate', uscurrentdate)
              let newdate3 = new Date(uscurrentdate.getFullYear(), uscurrentdate.getMonth(), uscurrentdate.getDate(), 0, 0, 0);
              console.log('newdate3', newdate3)
              console.log('newexceldate', newexceldate)
              data[rowindex][END_DATE_INDEX] = tranformDate(endate);
              if (newdate3 <= newexceldate) {
                if (startdate > endate) {

                  data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
                  validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
                  data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "End date should be Greater than Start Date" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", End date should be Greater than Start Date")
                }
              }
              else {
                data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
                validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
                data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "End date should not contain past dates" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", End date should not contain past dates")
              }
            }
          }
          else {
            data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "End date must be there for TEMPORARY type" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", End date must be there for TEMPORARY type")
          }
        }
        else if(data[rowindex][TYPE_INDEX] == "MANUAL" || data[rowindex][TYPE_INDEX] == "PERMANENT"){
          if (isValidInput(data[rowindex][END_DATE_INDEX])) {
            data[rowindex][VALIDATION_STATUS_INDEX] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            data[rowindex][VALIDATION_COMMENTS_INDEX] = (data[rowindex][VALIDATION_COMMENTS_INDEX] == undefined ? "End date is not required when type is MANUAL or PERMANENT" : data[rowindex][VALIDATION_COMMENTS_INDEX] + ", End date is not required when type is MANUAL or PERMANENT")
            if (!(data[rowindex][END_DATE_INDEX] === undefined || data[rowindex][END_DATE_INDEX].toString().trim() === '')) {

              if (!(isNaN(data[rowindex][END_DATE_INDEX]))) {
                const date = data[rowindex][END_DATE_INDEX];
                const uscurrentdate = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' }));
                var utc_days = Math.floor(date - 25569);
                var utc_value = utc_days * 86400;
                var date_info = new Date(utc_value * 1000);

                var fractional_day = date - Math.floor(date) + 0.0000001;

                var total_seconds = Math.floor(86400 * fractional_day);

                var seconds = (total_seconds % 60);

                total_seconds -= seconds;

                var hours = Math.floor(total_seconds / (60 * 60));
                var minutes = (Math.floor(total_seconds / 60) % 60);
                const newexceldate = new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate())

                let newdate = new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
                console.log(moment(newdate).format('DD-MMM-YY h:mm:ss a'));

                data[rowindex][END_DATE_INDEX] = moment(newdate).format('MM/DD/YY H:mm');

                console.log('uscurrentdate', uscurrentdate)
                console.log('newexceldate', newexceldate)
              }
            }
          }
        }
      
      }
    }
    return data;
  }

  // //validateAutoterm validates Autoterm sheet
  const validateAutoterm = (data: any) => {
    const percentdiscountregexp = /^([0-9]{0,2})?(\.[0-9]{0,2})?$/
    var newData: any = [];
    for (let i = 1; i < data.length; i++) {
      if (data[i].length == 0 || data[i].length < 6)
        break
      let newdatapush = '';
      data[i][0] !== undefined ? newdatapush = newdatapush + String(data[i][0]) : newdatapush = newdatapush + '0';
      data[i][1] !== undefined ? newdatapush = newdatapush + String(data[i][1]) : newdatapush = newdatapush + '0';
      data[i][2] !== undefined ? newdatapush = newdatapush + String(data[i][2]) : newdatapush = newdatapush + '0';
      data[i][3] !== undefined ? newdatapush = newdatapush + String(data[i][3]) : newdatapush = newdatapush + '0';
      data[i][4] !== undefined ? newdatapush = newdatapush + String(data[i][4]) : newdatapush = newdatapush + '0';
      data[i][5] !== undefined ? newdatapush = newdatapush + String(data[i][5]) : newdatapush = newdatapush + '0';
      data[i][6] !== undefined ? newdatapush = newdatapush + String(data[i][6]) : newdatapush = newdatapush + '0';
      data[i][7] !== undefined ? newdatapush = newdatapush + String(data[i][7]) : newdatapush = newdatapush + '0';
      data[i][8] !== undefined ? newdatapush = newdatapush + String(data[i][8]) : newdatapush = newdatapush + '0';
      data[i][9] !== undefined ? newdatapush = newdatapush + String(data[i][9]) : newdatapush = newdatapush + '0';
      console.log('newdatapush', newdatapush)
      newdatapush != '' ? newData.push(newdatapush) : newData.push('');
    }

    var map: any = {};
    var duplicates = [];
    for (var i = 0; i < newData.length; i++) {
      var element = newData[i];
      if (!map[element]) {
        map[element] = [i];
      }
      else {
        map[element].push(i);
        duplicates.push(i + 1)
      }
    }

    console.log('newData', newData);
    console.log('map', map);
    console.log('duplicates', duplicates);


    let array = []
    for (let i = 0; i < data.length; i++) {
      if (data[i].length == 0 || data[i].length < 6)
        break
      if (data[i][1] !== undefined) {
        array.push(data[i][1]);
      }
      else {
        array.push('');
      }
    }
    console.log("new formed Array = ", array);
    var uniqueArray = [...new Set(array)];
    console.log("Array with unique values = ", uniqueArray);
    var itemCount: any = {};

    array.forEach(function (value: any) {
      if (value in itemCount) {
        itemCount[value] = itemCount[value] + 1;
      }
      else {
        itemCount[value] = 1;
      }
    });
    console.log(itemCount);
    let newduplicates: any= [];

    //This is area is commented to address "Department Range should not exceed 30" issue
    // for (let i = 0; i < uniqueArray.length; i++) {
    //   if (itemCount[uniqueArray[i]] > 30) {
    //     // newduplicates.push(array.lastIndexOf(uniqueArray[i]));
    //     newduplicates.push(array.indexOf(uniqueArray[i]));
    //     // newduplicates.push(uniqueArray[i]);
    //   }
    // }
    console.log('newduplicates', newduplicates)




    for (let rowindex = 1; rowindex < data.length; rowindex++) {
      // console.log('inside validations for loop');
      if (data[rowindex].length == 0)
        return data;
      if (data[rowindex][0] == undefined || data[rowindex][1] == undefined || data[rowindex][3] == undefined || data[rowindex][4] == undefined || data[rowindex][5] == undefined) {
        data[rowindex][10] = 'Failed';
        validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
        data[rowindex][12] = undefined;
        data[rowindex][13] = undefined;
        if (data[rowindex][0] == undefined) {
          data[rowindex][11] = (data[rowindex][11] == undefined ? "Zone Number is required" : data[rowindex][11] + ", Zone Number is required");
          // data[rowindex][11] = "Zone Number is required";
        }
        if (data[rowindex][1] == undefined) {
          data[rowindex][11] = (data[rowindex][11] == undefined ? "Department is required" : data[rowindex][11] + ", Department is required");
          // data[rowindex][11] = (data[rowindex][11] == undefined ? "" : data[rowindex][11] + ", ");
          // data[rowindex][11] = "Department is required";
        }
        if (data[rowindex][3] == undefined) {
          data[rowindex][11] = (data[rowindex][11] == undefined ? "Days on Rent Start is required" : data[rowindex][11] + ", Days on Rent Start is required");
          // data[rowindex][11] = "Days on Rent Start is required";
        }
        if (data[rowindex][4] == undefined) {
          data[rowindex][11] = (data[rowindex][11] == undefined ? "Days on Rent End is required" : data[rowindex][11] + ", Days on Rent End is required");
          // data[rowindex][11] = "Days on Rent End is required";
        }
        if (data[rowindex][5] == undefined) {
          data[rowindex][11] = (data[rowindex][11] == undefined ? "Percent Discount is required" : data[rowindex][11] + ", Percent Discount is required");
          // data[rowindex][11] = "Percent Discount is required";
        }
        if (data[rowindex][9] === undefined || isNaN(data[rowindex][9]) || data[rowindex][9] < 0 || data[rowindex][9].toString().trim() === '') {
          data[rowindex][10] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
        }
        else if (!(isNaN(data[rowindex][9]))) {
          const date = data[rowindex][9];
          const exceldate = new Date(((date - (25567 + 1)) * 86400 * 1000))
          let newdate2 = new Date(exceldate.getFullYear(), exceldate.getMonth(), exceldate.getDate(), 0, 0, 0);
          data[rowindex][9] = newdate2;
        }

      }
      else if (duplicates.includes(rowindex)) {
        console.log('duplicates.includes(rowindex)');

        // change startdate number to date format
        if (data[rowindex][9] === undefined || isNaN(data[rowindex][9]) || data[rowindex][9] < 0 || data[rowindex][9].toString().trim() === '') {
          data[rowindex][10] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
        }
        else if (!(isNaN(data[rowindex][9]))) {
          const date = data[rowindex][9];
          const exceldate = new Date(((date - (25567 + 1)) * 86400 * 1000))
          let newdate2 = new Date(exceldate.getFullYear(), exceldate.getMonth(), exceldate.getDate(), 0, 0, 0);
          data[rowindex][9] = newdate2;
        }
        data[rowindex][10] = 'Failed';
        validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
        data[rowindex][11] = "Row is Repeated";
        data[rowindex][12] = undefined;
        data[rowindex][13] = undefined;


      }
      else {
        // Zone Number
        if (data[rowindex][0] === undefined || isNaN(data[rowindex][0]) || data[rowindex][0] < 0 || data[rowindex][0].toString().includes('.') || data[rowindex][0].toString().length > 5 || data[rowindex][0].toString().trim() === '') {
          data[rowindex][10] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          data[rowindex][12] = undefined;
          data[rowindex][13] = undefined;
          if (data[rowindex][0] !== undefined) {
            if (data[rowindex][0].toString().trim() === '') {
              data[rowindex][11] = (data[rowindex][11] == undefined ? "Zone Number is empty" : data[rowindex][11] + ", Zone Number is empty");
            }
            if (isNaN(data[rowindex][0])) {
              data[rowindex][11] = (data[rowindex][11] == undefined ? "Invalid zone number" : data[rowindex][11] + ", Invalid zone number");
            }
            else if (data[rowindex][0].toString().length > 5) {
              data[rowindex][11] = (data[rowindex][11] == undefined ? "Zone number should not exceed 5 digits" : data[rowindex][11] + ", Zone number should not exceed 5 digits")
            }
            if (data[rowindex][0] < 0) {
              data[rowindex][11] = (data[rowindex][11] == undefined ? "Zone Number should not be less than Zero" : data[rowindex][11] + ", Zone Number should not be less than Zero")
            }
            if (data[rowindex][0].toString().includes('.')) {
              data[rowindex][11] = (data[rowindex][11] == undefined ? "Zone Number should not be a decimal number" : data[rowindex][11] + ", Zone Number should not be a decimal number")
            }
          }
          else
            data[rowindex][11] = (data[rowindex][11] == undefined ? "Zone Number is required" : data[rowindex][11] + ", Zone Number is required");
        }
        else {
          data[rowindex][10] = 'Passed';
          data[rowindex][11] = undefined;
          data[rowindex][12] = undefined;
          data[rowindex][13] = undefined;
        }
        // Rms Number, Bracket, Subdepartment, Department, RMS number 

        if (data[rowindex][1] == undefined && data[rowindex][6] == undefined && data[rowindex][7] == undefined && data[rowindex][8] == undefined) {
          console.log(rowindex, ', all are undefined')
          data[rowindex][10] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          data[rowindex][11] = (data[rowindex][11] == undefined ? "Department is required" : data[rowindex][11] + ", Department is required");
        }
        //RMS Number
        else if (data[rowindex][8] !== undefined) {
          console.log(rowindex, ', rms number is  undefined')

          if (data[rowindex][8] === undefined || isNaN(data[rowindex][8]) || data[rowindex][8] < 0 || data[rowindex][8].toString().includes('.') ||
            data[rowindex][8].toString().trim() === '') {
            data[rowindex][10] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            if (data[rowindex][8] !== undefined) {
              if (data[rowindex][8].toString().trim() === '') {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "RMS Number is empty" : data[rowindex][11] + ", RMS Number is empty");
              }
              if (isNaN(data[rowindex][8])) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Invalid RMS number" : data[rowindex][11] + ", Invalid RMS number");
              }
              if (data[rowindex][8] < 0) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "RMS Number should not be less than Zero" : data[rowindex][11] + ", RMS Number should not be less than Zero")
              }
              if (data[rowindex][8].toString().includes('.')) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "RMS Number should not be a decimal number" : data[rowindex][11] + ", RMS Number should not be a decimal number")
              }
            }
            else
              data[rowindex][11] = (data[rowindex][11] == undefined ? "RMS number is required" : data[rowindex][11] + ", RMS number is required");
          }
          if (data[rowindex][7] === undefined || isNaN(data[rowindex][7]) || data[rowindex][7] < 0 || data[rowindex][7].toString().includes('.') || data[rowindex][7].toString().trim() === '') {
            data[rowindex][10] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            if (data[rowindex][7] !== undefined) {
              if (data[rowindex][7].toString().trim() === '') {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Bracket is empty" : data[rowindex][11] + ", Bracket is empty");
              }
              if (isNaN(data[rowindex][7])) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Invalid Bracket" : data[rowindex][11] + ", Invalid Bracket");
              }
              if (data[rowindex][7] < 0) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Bracket should not be less than Zero" : data[rowindex][11] + ", Bracket should not be less than Zero")
              }

              if (data[rowindex][7].toString().includes('.')) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Bracket should not be a decimal number" : data[rowindex][11] + ", Bracket should not be a decimal number")
              }
            }
            else
              data[rowindex][11] = (data[rowindex][11] == undefined ? "Bracket is required" : data[rowindex][11] + ", Bracket is required");
          }

          if (data[rowindex][6] === undefined || isNaN(data[rowindex][6]) || data[rowindex][6] < 0 || data[rowindex][6].toString().includes('.') || data[rowindex][6].toString().trim() === '') {
            data[rowindex][10] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            if (data[rowindex][6] !== undefined) {
              if (data[rowindex][6].toString().trim() === '') {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Subdepartment is empty" : data[rowindex][11] + ", Subdepartment is empty");
              }
              if (isNaN(data[rowindex][6])) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Invalid Subdepartment" : data[rowindex][11] + ", Invalid Subdepartment");
              }
              if (data[rowindex][6] < 0) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Subdepartment should not be less than Zero" : data[rowindex][11] + ", Subdepartment should not be less than Zero")
              }

              if (data[rowindex][6].toString().includes('.')) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Subdepartment should not be a decimal number" : data[rowindex][11] + ", Subdepartment should not be a decimal number")
              }
            }
            else
              data[rowindex][11] = (data[rowindex][11] == undefined ? "Subdepartment is required" : data[rowindex][11] + ", Subdepartment is required");
          }

          if (data[rowindex][1] === undefined || isNaN(data[rowindex][1]) || data[rowindex][1] < 0 || data[rowindex][1].toString().includes('.') || data[rowindex][1].toString().trim() === '') {
            data[rowindex][10] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';

            if (data[rowindex][1] !== undefined) {
              if (data[rowindex][1].toString().trim() === '') {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Department is empty" : data[rowindex][11] + ", Department is empty");
              }
              if (isNaN(data[rowindex][1])) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Invalid Department" : data[rowindex][11] + ", Invalid Department");
              }
              if (data[rowindex][1] < 0) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Department should not be less than Zero" : data[rowindex][11] + ", Department should not be less than Zero")
              }

              if (data[rowindex][1].toString().includes('.')) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Department should not be a decimal number" : data[rowindex][11] + ", Department should not be a decimal number")
              }

            }
            else
              data[rowindex][11] = (data[rowindex][11] == undefined ? "Department is required" : data[rowindex][11] + ", Department is required");
          }
          else if (newduplicates.includes(rowindex)) {
            data[rowindex][10] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            console.log('inside department ')
            data[rowindex][11] = "Department Range should not exceed 30";
          }

        }
        //Bracket
        else if (data[rowindex][7] !== undefined) {
          console.log(rowindex, ', Bracket is  undefined')

          if (data[rowindex][7] === undefined || isNaN(data[rowindex][7]) || data[rowindex][7] < 0 || data[rowindex][7].toString().includes('.') || data[rowindex][7].toString().trim() === '') {
            data[rowindex][10] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            if (data[rowindex][7] !== undefined) {
              if (data[rowindex][7].toString().trim() === '') {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Bracket is empty" : data[rowindex][11] + ", Bracket is empty");
              }
              if (isNaN(data[rowindex][7])) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Invalid Bracket" : data[rowindex][11] + ", Invalid Bracket");
              }
              if (data[rowindex][7] < 0) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Bracket should not be less than Zero" : data[rowindex][11] + ", Bracket should not be less than Zero")
              }

              if (data[rowindex][7].toString().includes('.')) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Bracket should not be a decimal number" : data[rowindex][11] + ", Bracket should not be a decimal number")
              }
            }
            else
              data[rowindex][11] = (data[rowindex][11] == undefined ? "Bracket is required" : data[rowindex][11] + ", Bracket is required");
          }

          if (data[rowindex][6] === undefined || isNaN(data[rowindex][6]) || data[rowindex][6] < 0 || data[rowindex][6].toString().includes('.') || data[rowindex][6].toString().trim() === '') {
            data[rowindex][10] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            if (data[rowindex][6] !== undefined) {
              if (data[rowindex][6].toString().trim() === '') {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Subdepartment is empty" : data[rowindex][11] + ", Subdepartment is empty");
              }
              if (isNaN(data[rowindex][6])) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Invalid Subdepartment" : data[rowindex][11] + ", Invalid Subdepartment");
              }
              if (data[rowindex][6] < 0) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Subdepartment should not be less than Zero" : data[rowindex][11] + ", Subdepartment should not be less than Zero")
              }

              if (data[rowindex][6].toString().includes('.')) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Subdepartment should not be a decimal number" : data[rowindex][11] + ", Subdepartment should not be a decimal number")
              }
            }
            else
              data[rowindex][11] = (data[rowindex][11] == undefined ? "Subdepartment is required" : data[rowindex][11] + ", Subdepartment is required");
          }
          if (data[rowindex][1] === undefined || isNaN(data[rowindex][1]) || data[rowindex][1] < 0 || data[rowindex][1].toString().includes('.') || data[rowindex][1].toString().trim() === '') {
            data[rowindex][10] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            if (data[rowindex][1] !== undefined) {
              if (data[rowindex][1].toString().trim() === '') {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Department is empty" : data[rowindex][11] + ", Department is empty");
              }
              if (isNaN(data[rowindex][1])) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Invalid Department" : data[rowindex][11] + ", Invalid Department");
              }
              if (data[rowindex][1] < 0) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Department should not be less than Zero" : data[rowindex][11] + ", Department should not be less than Zero")
              }

              if (data[rowindex][1].toString().includes('.')) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Department should not be a decimal number" : data[rowindex][11] + ", Department should not be a decimal number")
              }

            }
            else
              data[rowindex][11] = (data[rowindex][11] == undefined ? "Department is required" : data[rowindex][11] + ", Department is required");
          }
          else if (newduplicates.includes(rowindex)) {
            data[rowindex][10] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            console.log('inside department ')
            data[rowindex][11] = "Department Range should not exceed 30";
          }
        }

        //Subdepartment
        else if (data[rowindex][6] !== undefined) {

          if (data[rowindex][6] === undefined || isNaN(data[rowindex][6]) || data[rowindex][6] < 0 || data[rowindex][6].toString().includes('.') || data[rowindex][6].toString().trim() === '') {
            data[rowindex][10] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            if (data[rowindex][6] !== undefined) {
              // data[rowindex][11] = (data[rowindex][11] == undefined ? "Store number is required" : data[rowindex][11] + "Store number is required");

              if (data[rowindex][6].toString().trim() === '') {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Subdepartment is empty" : data[rowindex][11] + ", Subdepartment is empty");
              }
              if (isNaN(data[rowindex][6])) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Invalid Subdepartment" : data[rowindex][11] + ", Invalid Subdepartment");
              }
              if (data[rowindex][6] < 0) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Subdepartment should not be less than Zero" : data[rowindex][11] + ", Subdepartment should not be less than Zero")
              }

              if (data[rowindex][6].toString().includes('.')) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Subdepartment should not be a decimal number" : data[rowindex][11] + ", Subdepartment should not be a decimal number")
              }
            }
            else
              data[rowindex][11] = (data[rowindex][11] == undefined ? "Subdepartment is required" : data[rowindex][11] + ", Subdepartment is required");
          }
          if (data[rowindex][1] === undefined || isNaN(data[rowindex][1]) || data[rowindex][1] < 0 || data[rowindex][1].toString().includes('.') || data[rowindex][1].toString().trim() === '') {
            data[rowindex][10] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            if (data[rowindex][1] !== undefined) {
              if (data[rowindex][1].toString().trim() === '') {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Department is empty" : data[rowindex][11] + ", Department is empty");
              }
              if (isNaN(data[rowindex][1])) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Invalid Department" : data[rowindex][11] + ", Invalid Department");
              }
              if (data[rowindex][1] < 0) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Department should not be less than Zero" : data[rowindex][11] + ", Department should not be less than Zero")
              }

              if (data[rowindex][1].toString().includes('.')) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Department should not be a decimal number" : data[rowindex][11] + ", Department should not be a decimal number")
              }

            }
            else
              data[rowindex][11] = (data[rowindex][11] == undefined ? "Department is required" : data[rowindex][11] + ", Department is required");
          }
          else if (newduplicates.includes(rowindex)) {

            data[rowindex][10] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            console.log('inside department ')
            data[rowindex][11] = "Department Range should not exceed 30";
          }
        }
        //department 1  
        else if (data[rowindex][1] !== undefined) {
          if (data[rowindex][1] === undefined || isNaN(data[rowindex][1]) || data[rowindex][1] < 0 || data[rowindex][1].toString().includes('.') || data[rowindex][1].toString().trim() === '') {
            data[rowindex][10] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            if (data[rowindex][1] !== undefined) {
              if (data[rowindex][1].toString().trim() === '') {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Department is empty" : data[rowindex][11] + ", Department is empty");
              }
              if (isNaN(data[rowindex][1])) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Invalid Department" : data[rowindex][11] + ", Invalid Department");
              }
              if (data[rowindex][1] < 0) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Department should not be less than Zero" : data[rowindex][11] + ", Department should not be less than Zero")
              }

              if (data[rowindex][1].toString().includes('.')) {
                data[rowindex][11] = (data[rowindex][11] == undefined ? "Department should not be a decimal number" : data[rowindex][11] + ", Department should not be a decimal number")
              }
            }
            else
              data[rowindex][11] = (data[rowindex][11] == undefined ? "Department is required" : data[rowindex][11] + ", Department is required");
          }
          else if (newduplicates.includes(rowindex)) {
            data[rowindex][10] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            console.log('inside department ')
            data[rowindex][11] = "Department Range should not exceed 30";
          }
        }

        //Tier
        if (data[rowindex][2] === undefined || (data[rowindex][2].length > 60) || !isNaN(data[rowindex][2]) || data[rowindex][2].toString().trim() === '') {
          data[rowindex][10] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';

          if (data[rowindex][2] != undefined) {
            if (data[rowindex][2].toString().trim() === '') {
              data[rowindex][11] = (data[rowindex][11] == undefined ? "Tier is empty" : data[rowindex][11] + ", Tier is empty");
            }
            else if (!(isNaN(data[rowindex][2]))) {
              data[rowindex][11] = (data[rowindex][11] == undefined ? "Tier should not be a number" : data[rowindex][11] + ", Tier should not be a number");
            }
            if (data[rowindex][2].length > 60) {
              data[rowindex][11] = (data[rowindex][11] == undefined ? "Tier should not exceed 60 characters" : data[rowindex][11] + ", Tier should not exceed 60 characters");
            }

          }
          else {
            data[rowindex][11] = (data[rowindex][11] == undefined ? "Tier is required" : data[rowindex][11] + ", Tier is required");
          }
        }
        let daysonrentstart = -1, daysonrentend = -1;


        //Days on Rent Start
        if (data[rowindex][3] === undefined || isNaN(data[rowindex][3]) || data[rowindex][3] < 0 || data[rowindex][3].toString().includes('.') ||
          // data[rowindex][3].toString().length >= 10 ||
          data[rowindex][3].toString().trim() === '') {
          data[rowindex][10] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          if (data[rowindex][3] !== undefined) {
            if (data[rowindex][3].toString().trim() === '') {
              data[rowindex][11] = (data[rowindex][11] == undefined ? "Days on Rent Start is empty" : data[rowindex][11] + ", Days on Rent Start is empty");
            }
            if (isNaN(data[rowindex][3])) {
              data[rowindex][11] = (data[rowindex][11] == undefined ? "Invalid Days on rent start" : data[rowindex][11] + ", Invalid Days on rent start");
            }
            if (data[rowindex][3] < 0) {
              data[rowindex][11] = (data[rowindex][11] == undefined ? "Days on Rent Start should not be less than Zero" : data[rowindex][11] + ", Days on Rent Start should not be less than Zero")
            }
            if (data[rowindex][3].toString().includes('.')) {
              data[rowindex][11] = (data[rowindex][11] == undefined ? "Days on Rent Start should not be a decimal number" : data[rowindex][11] + ", Days on Rent Start should not be a decimal number")
            }
          }
          else
            data[rowindex][11] = (data[rowindex][11] == undefined ? "Days on rent start is required" : data[rowindex][11] + ", Days on rent start is required");
        }
        else {
          daysonrentstart = data[rowindex][3];
        }

        //Days on Rent End
        if (data[rowindex][4] === undefined || isNaN(data[rowindex][4]) || data[rowindex][4] < 0 || data[rowindex][4].toString().includes('.') ||
          data[rowindex][4].toString().trim() === '') {
          data[rowindex][10] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          if (data[rowindex][4] !== undefined) {
            if (data[rowindex][4].toString().trim() === '') {
              data[rowindex][11] = (data[rowindex][11] == undefined ? "Days on Rent End is empty" : data[rowindex][11] + ", Days on Rent End is empty");
            }
            if (isNaN(data[rowindex][4])) {
              data[rowindex][11] = (data[rowindex][11] == undefined ? "Invalid Days on Rent End" : data[rowindex][11] + ", Invalid Days on Rent End");
            }
            if (data[rowindex][4] < 0) {
              data[rowindex][11] = (data[rowindex][11] == undefined ? "Days on Rent End should not be less than Zero" : data[rowindex][11] + ", Days on Rent End should not be less than Zero")
            }
            if (data[rowindex][4].toString().includes('.')) {
              data[rowindex][11] = (data[rowindex][11] == undefined ? "Days on Rent End should not be a decimal number" : data[rowindex][11] + ", Days on Rent End should not be a decimal number")
            }
          }
          else
            data[rowindex][11] = (data[rowindex][11] == undefined ? "Days on Rent End is required" : data[rowindex][11] + ", Days on Rent End is required");
        }
        else {
          daysonrentend = data[rowindex][4];
        }
        if (daysonrentstart != -1 && daysonrentend != -1) {
          if (daysonrentstart > daysonrentend) {
            data[rowindex][10] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            data[rowindex][11] = (data[rowindex][11] == undefined ? "Days on rent end should be greater than days on rent start " : data[rowindex][11] + ", Days on rent end should be greater than days on rent start ");
          }
        }



        //Percent Discount

        if (data[rowindex][5] == undefined || isNaN(data[rowindex][5]) || data[rowindex][5].toString().trim() === '') {

          if (data[rowindex][5] !== undefined) {
            if (data[rowindex][5].toString().trim() === '') {
              data[rowindex][10] = 'Failed';
              validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';

              data[rowindex][11] = (data[rowindex][11] == undefined ? "Percent Discount is empty" : data[rowindex][11] + ", Percent Discount is empty");
            }
            if (isNaN(data[rowindex][5])) {
              data[rowindex][10] = 'Failed';
              validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';

              data[rowindex][11] = (data[rowindex][11] == undefined ? "Invalid Percent Discount" : data[rowindex][11] + ", Invalid Percent Discount");
            }
          }
          else {
            data[rowindex][11] = (data[rowindex][11] == undefined ? "Percentage discount is required" : data[rowindex][11] + ", Percentage discount is required");

          }
        }
        else if (!(data[rowindex][5].toString().match(percentdiscountregexp))) {
          data[rowindex][10] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          data[rowindex][11] = (data[rowindex][11] == undefined ? "Percentage discount limit exceeded" : data[rowindex][11] + ", Percentage discount limit exceeded")
        }

        //Start Date
        if (data[rowindex][9] === undefined || isNaN(data[rowindex][9]) || data[rowindex][9] < 0 || data[rowindex][9].toString().trim() === '') {
          data[rowindex][10] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          if (data[rowindex][9] !== undefined) {

            if (data[rowindex][9].toString().trim() === '') {
              data[rowindex][11] = (data[rowindex][11] == undefined ? "Start Date is empty" : data[rowindex][11] + ", Start Date is empty");
            }
            if (isNaN(data[rowindex][9])) {
              data[rowindex][11] = (data[rowindex][11] == undefined ? "Start Date is invalid" : data[rowindex][11] + ", Start Date is invalid");
            }
            if (data[rowindex][9] < 0) {
              data[rowindex][11] = (data[rowindex][11] == undefined ? "Start Date is invalid" : data[rowindex][11] + ", Start Date is invalid")
            }


          }
          else
            data[rowindex][11] = (data[rowindex][11] == undefined ? "Start date is required" : data[rowindex][11] + ", Start date is required");
        }
        else if (!(isNaN(data[rowindex][9]))) {
          const date = data[rowindex][9];
          const exceldate = new Date(((date - (25567 + 1)) * 86400 * 1000))
          const ctConvertedDate = new Date(new Date(exceldate).toLocaleString('en-US', { timeZone: 'America/Chicago' }));
          // let newdate2 = new Date(ctConvertedDate.getFullYear(), ctConvertedDate.getMonth(), ctConvertedDate.getDate(), 0, 0, 0);
          let newdate2 = moment(ctConvertedDate).format('YYYY-MM-DD');
          data[rowindex][9] = ctConvertedDate;
          const uscurrentdate = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' }));
          // let newdate3 = new Date(uscurrentdate.getFullYear(), uscurrentdate.getMonth(), uscurrentdate.getDate(), 0, 0, 0);
          let newdate3 = moment(uscurrentdate).format('YYYY-MM-DD');

          if (newdate3 > newdate2) {
            data[rowindex][10] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            data[rowindex][11] = (data[rowindex][11] == undefined ? "Start date should not contain past dates" : data[rowindex][11] + ", Start date should not contain past dates")
          }


        }
      }


    }
    // }
    return data;
  }

  // validatePackage validates Package sheet
  const validatePackage = (data: any) => {
    const discountamountregexp = /^([0-9]{1,2})?(\.[0-9]{0,2})?$/;
    console.log(data);
    var newData: any = [];

    for (let i = 1; i < data.length; i++) {
      if (data[i].length == 0 || data[i].length < 6)
        break
      let newdatapush = '';
      data[i][0] !== undefined ? newdatapush = newdatapush + String(data[i][0]) : newdatapush = newdatapush + '0';
      data[i][1] !== undefined ? newdatapush = newdatapush + String(data[i][1]) : newdatapush = newdatapush + '0';
      data[i][2] !== undefined ? newdatapush = newdatapush + String(data[i][2]) : newdatapush = newdatapush + '0';
      data[i][3] !== undefined ? newdatapush = newdatapush + String(data[i][3]) : newdatapush = newdatapush + '0';
      data[i][4] !== undefined ? newdatapush = newdatapush + String(data[i][4]) : newdatapush = newdatapush + '0';
      data[i][5] !== undefined ? newdatapush = newdatapush + String(data[i][5]) : newdatapush = newdatapush + '0';
      data[i][6] !== undefined ? newdatapush = newdatapush + String(data[i][6]) : newdatapush = newdatapush + '0';
      data[i][7] !== undefined ? newdatapush = newdatapush + String(data[i][7]) : newdatapush = newdatapush + '0';
      data[i][8] !== undefined ? newdatapush = newdatapush + String(data[i][8]) : newdatapush = newdatapush + '0';
      data[i][9] !== undefined ? newdatapush = newdatapush + String(data[i][9]) : newdatapush = newdatapush + '0';
      data[i][10] !== undefined ? newdatapush = newdatapush + String(data[i][10]) : newdatapush = newdatapush + '0';
      data[i][11] !== undefined ? newdatapush = newdatapush + String(data[i][11]) : newdatapush = newdatapush + '0';
      console.log('newdatapush', newdatapush)
      newdatapush != '' ? newData.push(newdatapush) : newData.push('');




    }

    var map: any = {};
    var duplicates = [];
    for (var i = 0; i < newData.length; i++) {
      var element = newData[i];
      if (!map[element]) {
        map[element] = [i];
      }
      else {
        map[element].push(i);
        duplicates.push(i + 1)
      }
    }

    console.log('newData', newData);
    console.log('map', map);
    console.log('duplicates', duplicates);

    for (let rowindex = 1; rowindex < data.length; rowindex++) {
      if (data[rowindex].length == 0)
        return data;
      console.log('newdata', data)
      if (data[rowindex][0] == undefined && data[rowindex][1] == undefined && data[rowindex][2] == undefined && data[rowindex][4] == undefined && data[rowindex][10] == undefined) {
        data[rowindex][12] = 'Failed';
        validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
        data[rowindex][13] = (data[rowindex][13] == undefined ? "Zone, Package Name, Discount Type, Department, Start Date are empty" : data[rowindex][13] + ", Zone, Package Name, Discount Type, Department, Start Date are empty");
        data[rowindex][14] = undefined;
        data[rowindex][15] = undefined;

        // change startdate number to date format
        if (data[rowindex][10] === undefined || isNaN(data[rowindex][10]) || data[rowindex][10] < 0 || data[rowindex][10].toString().trim() === '') {
          data[rowindex][12] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
        }
        else if (!(isNaN(data[rowindex][10]))) {
          const date = data[rowindex][10];
          let exceldate = new Date(((date - (25567 + 1)) * 86400 * 1000))
          let newdate1 = new Date(exceldate.getFullYear(), exceldate.getMonth(), exceldate.getDate(), 0, 0, 0);
          data[rowindex][10] = newdate1;
        }

        // change end date number to date format
        if (!(data[rowindex][11] === undefined || data[rowindex][11].toString().trim() === '')) {
          if (isNaN(data[rowindex][11]) || data[rowindex][11] < 0) {
            data[rowindex][12] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          }
          else {
            const date = data[rowindex][11];
            let exceldate = new Date(((date - (25567 + 1)) * 86400 * 1000))
            let newdate1 = new Date(exceldate.getFullYear(), exceldate.getMonth(), exceldate.getDate(), 0, 0, 0);
            data[rowindex][11] = newdate1;
          }
        }




      }
      else if (duplicates.includes(rowindex)) {

        // change startdate number to date format
        if (data[rowindex][10] === undefined || isNaN(data[rowindex][10]) || data[rowindex][10] < 0 || data[rowindex][10].toString().trim() === '') {
          data[rowindex][12] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
        }
        else if (!(isNaN(data[rowindex][10]))) {
          const date = data[rowindex][10];
          let exceldate = new Date(((date - (25567 + 1)) * 86400 * 1000))
          let newdate1 = new Date(exceldate.getFullYear(), exceldate.getMonth(), exceldate.getDate(), 0, 0, 0);
          data[rowindex][10] = newdate1;
        }

        // change end date number to date format
        if (!(data[rowindex][11] === undefined || data[rowindex][11].toString().trim() === '')) {


          if (isNaN(data[rowindex][11]) || data[rowindex][11] < 0) {
            data[rowindex][12] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          }
          else {
            const date = data[rowindex][11];
            let exceldate = new Date(((date - (25567 + 1)) * 86400 * 1000))
            let newdate1 = new Date(exceldate.getFullYear(), exceldate.getMonth(), exceldate.getDate(), 0, 0, 0);
            data[rowindex][11] = newdate1;
          }
        }
        data[rowindex][12] = 'Failed';
        validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
        data[rowindex][13] = "Row is Repeated";
        data[rowindex][14] = undefined;
        data[rowindex][15] = undefined;
      }
      else {
        //Zone Number
        if (data[rowindex][0] === undefined || isNaN(data[rowindex][0]) || data[rowindex][0] < 0 || data[rowindex][0].toString().length > 5 || data[rowindex][0].toString().trim() === '') {
          data[rowindex][12] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          data[rowindex][14] = undefined;
          data[rowindex][15] = undefined;
          if (data[rowindex][0] !== undefined) {
            if (data[rowindex][0].toString().trim() === '') {
              data[rowindex][13] = (data[rowindex][13] == undefined ? "Zone Number is empty" : data[rowindex][13] + ", Zone Number is empty");
            }
            if (isNaN(data[rowindex][0])) {
              data[rowindex][13] = (data[rowindex][13] == undefined ? "Invalid zone number" : data[rowindex][13] + ", Invalid zone number");
            }
            else if (data[rowindex][0].toString().length > 5) {
              data[rowindex][13] = (data[rowindex][13] == undefined ? "Zone number should not exceed 5 digits" : data[rowindex][13] + ", Zone number should not exceed 5 digits")
            }
            if (data[rowindex][0] < 0) {
              data[rowindex][13] = (data[rowindex][13] == undefined ? "Zone Number should not be less than Zero" : data[rowindex][13] + ", Zone Number should not be less than Zero")
            }

            if (data[rowindex][0].toString().includes('.')) {
              data[rowindex][13] = (data[rowindex][13] == undefined ? "Zone Number should not be a decimal number" : data[rowindex][13] + ", Zone Number should not be a decimal number")
            }
          }
          else
            data[rowindex][13] = (data[rowindex][13] == undefined ? "Zone Number is required" : data[rowindex][13] + ", Zone Number is required");
        }
        else {
          data[rowindex][12] = 'Passed';
          data[rowindex][13] = undefined;
          data[rowindex][14] = undefined;
          data[rowindex][15] = undefined;
        }

        //Package name
        if (data[rowindex][1] === undefined || (data[rowindex][1].length > 60) || !isNaN(data[rowindex][1]) || data[rowindex][1].toString().trim() === '') {
          data[rowindex][12] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          if (data[rowindex][1] != undefined) {
            if (data[rowindex][1].toString().trim() === '') {
              data[rowindex][13] = (data[rowindex][13] == undefined ? "Package name is empty" : data[rowindex][13] + ", Package name is empty");
            }
            else if (!(isNaN(data[rowindex][1]))) {
              data[rowindex][13] = (data[rowindex][13] == undefined ? "Package name should not be a number" : data[rowindex][13] + ", Package name should not be a number");
            }
            if (data[rowindex][1].length > 60) {
              data[rowindex][13] = (data[rowindex][13] == undefined ? "Package Name should not exceed 60 characters" : data[rowindex][13] + ", Package Name should not exceed 60 characters");
            }
          }
          else {
            data[rowindex][13] = (data[rowindex][13] == undefined ? "Package Name is required" : data[rowindex][13] + ", Package Name is required");
          }
        }

        //discount type
        let minimumQuantity = -1, maximumQuantity = -1;

        if (data[rowindex][2] === undefined || isNaN(data[rowindex][2]) || data[rowindex][2].toString().trim() === '') {
          if (data[rowindex][2] !== '% Off' && data[rowindex][2] !== '$ Off' && data[rowindex][2] !== 'Add-On') {
            data[rowindex][12] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            if (data[rowindex][2] == undefined || data[rowindex][2].toString().trim() === '') {
              data[rowindex][13] = (data[rowindex][13] == undefined ? "Discount type is required" : data[rowindex][13] + ", Discount type is required");
            }
            else {
              data[rowindex][13] = (data[rowindex][13] == undefined ? "Discount Type can be either % Off, $ Off, Add-On" : data[rowindex][13] + ",  Discount Type can be either % Off, $ Off, Add-On");
            }
          }
          else if (data[rowindex][2] == '% Off' || data[rowindex][2] == '$ Off') {

            //Minimum Quantity
            if (data[rowindex][8] === undefined || isNaN(data[rowindex][8]) || data[rowindex][8] < 0 || data[rowindex][8].toString().includes('.') || data[rowindex][8].toString().trim() === '') {
              data[rowindex][12] = 'Failed';
              validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
              if (data[rowindex][8] !== undefined) {

                if (data[rowindex][8].toString().trim() === '') {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Minimum Quantity is empty" : data[rowindex][13] + ", Minimum Quantity is empty");
                }
                if (isNaN(data[rowindex][8])) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Invalid Minimum Quantity" : data[rowindex][13] + ", Invalid Minimum Quantity");
                }
                if (data[rowindex][8] < 8) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Minimum Quantity should not be less than Zero" : data[rowindex][13] + ", Minimum Quantity should not be less than Zero")
                }

                if (data[rowindex][8].toString().includes('.')) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Minimum Quantity should not be a decimal number" : data[rowindex][13] + ", Minimum Quantity should not be a decimal number")
                }
              }
              else
                data[rowindex][13] = (data[rowindex][13] == undefined ? "Minimum quantity is empty" : data[rowindex][13] + ", Minimum quantity is empty");
            }
            else if (!(data[rowindex][8] >= 0 && data[rowindex][8] < 100)) {
              data[rowindex][12] = 'Failed';
              validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
              data[rowindex][13] = (data[rowindex][13] == undefined ? "Minimum Quantity should be 0-99" : data[rowindex][13] + ", Minimum Quantity should be 0-99");
            }
            else {
              minimumQuantity = data[rowindex][8];
            }


            //Maximum Quantity
            if (data[rowindex][9] === undefined || isNaN(data[rowindex][9]) || data[rowindex][9] < 0 || data[rowindex][9].toString().includes('.') || data[rowindex][9].toString().trim() === '') {
              data[rowindex][12] = 'Failed';
              validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
              if (data[rowindex][9] !== undefined) {

                if (data[rowindex][9].toString().trim() === '') {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Maximum Quantity is empty" : data[rowindex][13] + ", Maximum Quantity is empty");
                }
                if (isNaN(data[rowindex][9])) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Invalid Maximum Quantity" : data[rowindex][13] + ", Invalid Maximum Quantity");
                }
                if (data[rowindex][9] < 9) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Maximum Quantity should not be less than Zero" : data[rowindex][13] + ", Maximum Quantity should not be less than Zero")
                }

                if (data[rowindex][9].toString().includes('.')) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Maximum Quantity should not be a decimal number" : data[rowindex][13] + ", Maximum Quantity should not be a decimal number")
                }
              }
              else
                data[rowindex][13] = (data[rowindex][13] == undefined ? "Please enter the Maximum Quantity" : data[rowindex][13] + ", Please enter the Maximum Quantity");
            }
            else if (!(data[rowindex][9] > 0 && data[rowindex][9] < 100)) {
              data[rowindex][12] = 'Failed';
              validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
              data[rowindex][13] = (data[rowindex][13] == undefined ? "Maximum Quantity should be 1-99" : data[rowindex][13] + ", Maximum Quantity should be 1-99");
            }
            else {
              maximumQuantity = data[rowindex][9];
            }

            if (minimumQuantity != -1 && maximumQuantity != -1) {
              if (minimumQuantity > maximumQuantity) {
                data[rowindex][12] = 'Failed';
                validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
                data[rowindex][13] = (data[rowindex][13] == undefined ? "Maximum Quantity must be Greater than Minimum Quantity" : data[rowindex][13] + ", Maximum Quantity must be Greater than Minimum Quantity");

              }
            }

            //discount amount
            if (data[rowindex][3] == undefined || isNaN(data[rowindex][3]) || data[rowindex][3].toString().trim() === '') {
              data[rowindex][12] = 'Failed';
              validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
              if (data[rowindex][3] !== undefined) {
                if (data[rowindex][3].toString().trim() === '') {

                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Discount Amount is empty" : data[rowindex][13] + ", Discount Amount is empty");
                }
                if (isNaN(data[rowindex][3])) {
                  data[rowindex][12] = 'Failed';
                  validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';

                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Invalid Discount Amount" : data[rowindex][13] + ", Invalid Discount Amount");
                }
              }
              else {
                data[rowindex][13] = (data[rowindex][13] == undefined ? "Discount Amount is required" : data[rowindex][13] + ", Discount Amount is required");

              }
            }
            else if (!(data[rowindex][3].toString().match(discountamountregexp)) || data[rowindex][3] > 100) {
              data[rowindex][12] = 'Failed';
              validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
              data[rowindex][13] = (data[rowindex][13] == undefined ? "Discount amount limit exceeded" : data[rowindex][13] + ", Discount amount limit exceeded")
            }


            console.log('//check discount number        //check min quantiry        //check max quatity')
          }

        }
        else
        // if (!isNaN(data[rowindex][2])) 
        {
          data[rowindex][12] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
          data[rowindex][13] = (data[rowindex][13] == undefined ? "Discount Type can be either % Off, $ Off" : data[rowindex][13] + ", Discount Type can be either % Off, $ Off");
        }

        if (data[rowindex][2] == '% Off' || data[rowindex][2] == '$ Off') {


          if (data[rowindex][4] == undefined && data[rowindex][5] == undefined && data[rowindex][6] == undefined && data[rowindex][7] == undefined) {
            console.log(rowindex, ', all are undefined')

            data[rowindex][12] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            data[rowindex][13] = (data[rowindex][13] == undefined ? "Department, Subdepartment, Bracket, RMS number should not be empty " : data[rowindex][13] + ", Department, Subdepartment, Bracket, RMS number should not be empty");


          }

          //RMS Number
          else if (data[rowindex][7] !== undefined) {
            console.log(rowindex, ', rms number is  undefined')

            if (data[rowindex][7] === undefined || isNaN(data[rowindex][7]) || data[rowindex][7] < 0 || data[rowindex][7].toString().includes('.') || data[rowindex][7].toString().trim() === '') {
              data[rowindex][12] = 'Failed';
              validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
              if (data[rowindex][7] !== undefined) {

                if (data[rowindex][7].toString().trim() === '') {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "RMS Number is empty" : data[rowindex][13] + ", RMS Number is empty");
                }
                if (isNaN(data[rowindex][7])) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Invalid RMS number" : data[rowindex][13] + ", Invalid RMS number");
                }
                if (data[rowindex][7] < 7) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "RMS Number should not be less than Zero" : data[rowindex][13] + ", RMS Number should not be less than Zero")
                }

                if (data[rowindex][7].toString().includes('.')) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "RMS Number should not be a decimal number" : data[rowindex][13] + ", RMS Number should not be a decimal number")
                }
              }
              else
                data[rowindex][13] = (data[rowindex][13] == undefined ? "RMS number is required" : data[rowindex][13] + ", RMS number is required");
            }
            if (data[rowindex][6] === undefined || isNaN(data[rowindex][6]) || data[rowindex][6] < 0 || data[rowindex][6].toString().includes('.') || data[rowindex][6].toString().trim() === '') {
              data[rowindex][12] = 'Failed';
              validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
              if (data[rowindex][6] !== undefined) {

                if (data[rowindex][6].toString().trim() === '') {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Bracket is empty" : data[rowindex][13] + ", Bracket is empty");
                }
                if (isNaN(data[rowindex][6])) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Invalid Bracket" : data[rowindex][13] + ", Invalid Bracket");
                }
                if (data[rowindex][6] < 6) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Bracket should not be less than Zero" : data[rowindex][13] + ", Bracket should not be less than Zero")
                }

                if (data[rowindex][6].toString().includes('.')) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Bracket should not be a decimal number" : data[rowindex][13] + ", Bracket should not be a decimal number")
                }
              }
              else
                data[rowindex][13] = (data[rowindex][13] == undefined ? "Bracket is required" : data[rowindex][13] + ", Bracket is required");
            }

            if (data[rowindex][5] === undefined || isNaN(data[rowindex][5]) || data[rowindex][5] < 0 || data[rowindex][5].toString().includes('.') || data[rowindex][5].toString().trim() === '') {
              data[rowindex][12] = 'Failed';
              validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
              if (data[rowindex][5] !== undefined) {
                if (data[rowindex][5].toString().trim() === '') {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Subdepartment is empty" : data[rowindex][13] + ", Subdepartment is empty");
                }
                if (isNaN(data[rowindex][5])) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Invalid Subdepartment" : data[rowindex][13] + ", Invalid Subdepartment");
                }
                if (data[rowindex][5] < 5) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Subdepartment should not be less than Zero" : data[rowindex][13] + ", Subdepartment should not be less than Zero")
                }

                if (data[rowindex][5].toString().includes('.')) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Subdepartment should not be a decimal number" : data[rowindex][13] + ", Subdepartment should not be a decimal number")
                }
              }
              else
                data[rowindex][13] = (data[rowindex][13] == undefined ? "Subdepartment is required" : data[rowindex][13] + ", Subdepartment is required");
            }
            if (data[rowindex][4] === undefined || isNaN(data[rowindex][4]) || data[rowindex][4] < 0 || data[rowindex][4].toString().includes('.') || data[rowindex][4].toString().trim() === '') {
              data[rowindex][12] = 'Failed';
              validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
              if (data[rowindex][4] !== undefined) {

                if (data[rowindex][4].toString().trim() === '') {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Department is empty" : data[rowindex][13] + ", Department is empty");
                }
                if (isNaN(data[rowindex][4])) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Invalid Department" : data[rowindex][13] + ", Invalid Department");
                }
                if (data[rowindex][4] < 4) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Department should not be less than Zero" : data[rowindex][13] + ", Department should not be less than Zero")
                }

                if (data[rowindex][4].toString().includes('.')) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Department should not be a decimal number" : data[rowindex][13] + ", Department should not be a decimal number")
                }
              }
              else
                data[rowindex][13] = (data[rowindex][13] == undefined ? "Department is required" : data[rowindex][13] + ", Department is required");
            }

          }

          //Bracket
          else if (data[rowindex][6] !== undefined) {
            console.log(rowindex, ', Bracket is  undefined')

            if (data[rowindex][6] === undefined || isNaN(data[rowindex][6]) || data[rowindex][6] < 0 || data[rowindex][6].toString().includes('.') || data[rowindex][6].toString().trim() === '') {
              data[rowindex][12] = 'Failed';
              validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
              if (data[rowindex][6] !== undefined) {

                if (data[rowindex][6].toString().trim() === '') {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Bracket is empty" : data[rowindex][13] + ", Bracket is empty");
                }
                if (isNaN(data[rowindex][6])) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Invalid Bracket" : data[rowindex][13] + ", Invalid Bracket");
                }
                if (data[rowindex][6] < 6) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Bracket should not be less than Zero" : data[rowindex][13] + ", Bracket should not be less than Zero")
                }

                if (data[rowindex][6].toString().includes('.')) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Bracket should not be a decimal number" : data[rowindex][13] + ", Bracket should not be a decimal number")
                }
              }
              else
                data[rowindex][13] = (data[rowindex][13] == undefined ? "Bracket is required" : data[rowindex][13] + ", Bracket is required");
            }

            if (data[rowindex][5] === undefined || isNaN(data[rowindex][5]) || data[rowindex][5] < 0 || data[rowindex][5].toString().includes('.') || data[rowindex][5].toString().trim() === '') {
              data[rowindex][12] = 'Failed';
              validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
              if (data[rowindex][5] !== undefined) {

                if (data[rowindex][5].toString().trim() === '') {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Subdepartment is empty" : data[rowindex][13] + ", Subdepartment is empty");
                }
                if (isNaN(data[rowindex][5])) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Invalid Subdepartment" : data[rowindex][13] + ", Invalid Subdepartment");
                }
                if (data[rowindex][5] < 5) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Subdepartment should not be less than Zero" : data[rowindex][13] + ", Subdepartment should not be less than Zero")
                }

                if (data[rowindex][5].toString().includes('.')) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Subdepartment should not be a decimal number" : data[rowindex][13] + ", Subdepartment should not be a decimal number")
                }
              }
              else
                data[rowindex][13] = (data[rowindex][13] == undefined ? "Subdepartment is required" : data[rowindex][13] + ", Subdepartment is required");
            }
            if (data[rowindex][4] === undefined || isNaN(data[rowindex][4]) || data[rowindex][4] < 0 || data[rowindex][4].toString().includes('.') || data[rowindex][4].toString().trim() === '') {
              data[rowindex][12] = 'Failed';
              validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
              if (data[rowindex][4] !== undefined) {

                if (data[rowindex][4].toString().trim() === '') {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Department is empty" : data[rowindex][13] + ", Department is empty");
                }
                if (isNaN(data[rowindex][4])) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Invalid Department" : data[rowindex][13] + ", Invalid Department");
                }
                if (data[rowindex][4] < 4) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Department should not be less than Zero" : data[rowindex][13] + ", Department should not be less than Zero")
                }

                if (data[rowindex][4].toString().includes('.')) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Department should not be a decimal number" : data[rowindex][13] + ", Department should not be a decimal number")
                }
              }
              else
                data[rowindex][13] = (data[rowindex][13] == undefined ? "Department is required" : data[rowindex][13] + ", Department is required");
            }
          }

          //Subdepartment
          else if (data[rowindex][5] !== undefined) {

            if (data[rowindex][5] === undefined || isNaN(data[rowindex][5]) || data[rowindex][5] < 0 || data[rowindex][5].toString().includes('.') || data[rowindex][5].toString().trim() === '') {
              data[rowindex][12] = 'Failed';
              validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
              if (data[rowindex][5] !== undefined) {

                if (data[rowindex][5].toString().trim() === '') {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Subdepartment is empty" : data[rowindex][13] + ", Subdepartment is empty");
                }
                if (isNaN(data[rowindex][5])) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Invalid Subdepartment" : data[rowindex][13] + ", Invalid Subdepartment");
                }
                if (data[rowindex][5] < 5) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Subdepartment should not be less than Zero" : data[rowindex][13] + ", Subdepartment should not be less than Zero")
                }

                if (data[rowindex][5].toString().includes('.')) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Subdepartment should not be a decimal number" : data[rowindex][13] + ", Subdepartment should not be a decimal number")
                }
              }
              else
                data[rowindex][13] = (data[rowindex][13] == undefined ? "Subdepartment is required" : data[rowindex][13] + ", Subdepartment is required");
            }
            if (data[rowindex][4] === undefined || isNaN(data[rowindex][4]) || data[rowindex][4] < 0 || data[rowindex][4].toString().includes('.') || data[rowindex][4].toString().trim() === '') {
              data[rowindex][12] = 'Failed';
              validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
              if (data[rowindex][4] !== undefined) {

                if (data[rowindex][4].toString().trim() === '') {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Department is empty" : data[rowindex][13] + ", Department is empty");
                }
                if (isNaN(data[rowindex][4])) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Invalid Department" : data[rowindex][13] + ", Invalid Department");
                }
                if (data[rowindex][4] < 4) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Department should not be less than Zero" : data[rowindex][13] + ", Department should not be less than Zero")
                }

                if (data[rowindex][4].toString().includes('.')) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Department should not be a decimal number" : data[rowindex][13] + ", Department should not be a decimal number")
                }
              }
              else
                data[rowindex][13] = (data[rowindex][13] == undefined ? "Department is required" : data[rowindex][13] + ", Department is required");
            }

          }


          //department 4
          else if (data[rowindex][4] !== undefined) {
            console.log(rowindex, ', Department is  undefined')

            if (data[rowindex][4] === undefined || isNaN(data[rowindex][4]) || data[rowindex][4] < 0 || data[rowindex][4].toString().includes('.') || data[rowindex][4].toString().trim() === '') {
              data[rowindex][12] = 'Failed';
              validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
              if (data[rowindex][4] !== undefined) {
                if (data[rowindex][4].toString().trim() === '') {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Department is empty" : data[rowindex][13] + ", Department is empty");
                }
                if (isNaN(data[rowindex][4])) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Invalid Department" : data[rowindex][13] + ", Invalid Department");
                }
                if (data[rowindex][4] < 4) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Department should not be less than Zero" : data[rowindex][13] + ", Department should not be less than Zero")
                }

                if (data[rowindex][4].toString().includes('.')) {
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "Department should not be a decimal number" : data[rowindex][13] + ", Department should not be a decimal number")
                }
              }
              else
                data[rowindex][13] = (data[rowindex][13] == undefined ? "Department is required" : data[rowindex][13] + ", Department is required");
            }
          }
        }
        else if (data[rowindex][2] == 'Add-On') {
          if (data[rowindex][4] === undefined || isNaN(data[rowindex][4]) || data[rowindex][4] < 0 || data[rowindex][4].toString().includes('.') || data[rowindex][4].toString().trim() === '') {
            data[rowindex][12] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            if (data[rowindex][4] !== undefined) {
              if (data[rowindex][4].toString().trim() === '') {
                data[rowindex][13] = (data[rowindex][13] == undefined ? "Department is empty" : data[rowindex][13] + ", Department is empty");
              }
              if (isNaN(data[rowindex][4])) {
                data[rowindex][13] = (data[rowindex][13] == undefined ? "Invalid Department" : data[rowindex][13] + ", Invalid Department");
              }
              if (data[rowindex][4] < 4) {
                data[rowindex][13] = (data[rowindex][13] == undefined ? "Department should not be less than Zero" : data[rowindex][13] + ", Department should not be less than Zero")
              }

              if (data[rowindex][4].toString().includes('.')) {
                data[rowindex][13] = (data[rowindex][13] == undefined ? "Department should not be a decimal number" : data[rowindex][13] + ", Department should not be a decimal number")
              }
            }
            else
              data[rowindex][13] = (data[rowindex][13] == undefined ? "Department is required" : data[rowindex][13] + ", Department is required");
          }
          if (data[rowindex][3] != undefined || data[rowindex][5] != undefined || data[rowindex][6] != undefined || data[rowindex][7] != undefined) {
            if (data[rowindex][3] != undefined) {
              data[rowindex][12] = 'Failed';
              validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
              data[rowindex][13] = (data[rowindex][13] == undefined ? "Discount Amount is not required for Add-On discount type" : data[rowindex][13] + ", Discount Amount is not required for Add-On discount type")
            }
            if (data[rowindex][5] != undefined) {
              data[rowindex][12] = 'Failed';
              validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
              data[rowindex][13] = (data[rowindex][13] == undefined ? "Subdepartment is not required for Add-On discount type" : data[rowindex][13] + ", Subdepartment is not required for Add-On discount type")
            }
            if (data[rowindex][6] != undefined) {
              data[rowindex][12] = 'Failed';
              validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
              data[rowindex][13] = (data[rowindex][13] == undefined ? "Bracket is not required for Add-On discount type" : data[rowindex][13] + ", Bracket is not required for Add-On discount type")
            }
            if (data[rowindex][7] != undefined) {
              data[rowindex][12] = 'Failed';
              validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
              data[rowindex][13] = (data[rowindex][13] == undefined ? "RMS number is not required for Add-On discount type" : data[rowindex][13] + ", RMS number is not required for Add-On discount type")
            }
          }
        }

        // Minimum Quantity <Maximum Quantity
        let excelstartdate = undefined;
        //start date
        if (data[rowindex][10] === undefined || isNaN(data[rowindex][10]) || data[rowindex][10] < 0 || data[rowindex][10].toString().trim() === '') {
          data[rowindex][12] = 'Failed';
          validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';

          if (data[rowindex][10] !== undefined) {
            if (data[rowindex][10].toString().trim() === '') {
              data[rowindex][13] = (data[rowindex][13] == undefined ? "Start Date is empty" : data[rowindex][13] + ", Start Date is empty");
            }
            if (isNaN(data[rowindex][10])) {
              data[rowindex][13] = (data[rowindex][13] == undefined ? "Start Date is invalid" : data[rowindex][13] + ", Start Date is invalid");
            }
            if (data[rowindex][10] < 0) {
              data[rowindex][13] = (data[rowindex][13] == undefined ? "Start Date is invalid" : data[rowindex][13] + ", Start Date is invalid")
            }
          }
          else
            data[rowindex][13] = (data[rowindex][13] == undefined ? "Start date is required" : data[rowindex][13] + ", Start date is required");
        }
        else if (!(isNaN(data[rowindex][10]))) {
          const date = data[rowindex][10];
          excelstartdate = date;
          const exceldate = new Date(((date - (25567 + 1)) * 86400 * 1000))
          const uscurrentdate = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' }));
          let newdate1 = new Date(exceldate.getFullYear(), exceldate.getMonth(), exceldate.getDate(), 0, 0, 0);
          let newdate2 = new Date(uscurrentdate.getFullYear(), uscurrentdate.getMonth(), uscurrentdate.getDate(), 0, 0, 0);
          data[rowindex][10] = newdate1;

          if (newdate2 > newdate1) {
            data[rowindex][12] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            data[rowindex][13] = (data[rowindex][13] == undefined ? "Start date should not contain past dates" : data[rowindex][13] + ", Start date should not contain past dates")
          }

        }

        // //End date
        if (!(data[rowindex][11] === undefined || data[rowindex][11].toString().trim() === '')) {
          if (isNaN(data[rowindex][11]) || data[rowindex][11] < 0) {
            data[rowindex][12] = 'Failed';
            validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
            data[rowindex][13] = (data[rowindex][13] == undefined ? "End date is Invalid" : data[rowindex][13] + ", End date is Invalid");
          }
          else {
            const oldexcelenddate = data[rowindex][11];
            const excelenddate = new Date(((data[rowindex][11] - (25567 + 1)) * 86400 * 1000))
            const uscurrentdate = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' }));
            let newdate1 = new Date(excelenddate.getFullYear(), excelenddate.getMonth(), excelenddate.getDate(), 0, 0, 0);
            data[rowindex][11] = newdate1;
            if (uscurrentdate < excelenddate) {
              console.log('inside end date 1')
              console.log('excelstartdate', excelstartdate);
              console.log('excelenddate', oldexcelenddate);
              if (excelstartdate != undefined) {
                if (excelstartdate > oldexcelenddate) {
                  console.log('inside end date 2')
                  data[rowindex][12] = 'Failed';
                  validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
                  data[rowindex][13] = (data[rowindex][13] == undefined ? "End date should be Greater than Start Date" : data[rowindex][13] + ", End date should be Greater than Start Date")
                }
              }
            }
            else {
              data[rowindex][12] = 'Failed';
              validationStatusFlag != 'Validation Failed' ? validationStatusFlag = 'Validation Failed' : '';
              data[rowindex][13] = (data[rowindex][13] == undefined ? "End date should not contain past dates" : data[rowindex][13] + ", End date should not contain past dates")
            }
          }
        }

      }

    }
    return data;
  }

  const handleKeypress = (e: any) => {
    //it triggers by pressing the enter key
    console.log(e)
    if (e.charCode == 13) {
      setItemOffset(0);
      { pginitial != 0 ? setpginitial(0) : undefined }
      console.log("enter pressed")
      searchbydocumentname();
    }
  }

  const searchbydocumentname = () => {
    console.log("table data before filter", Tabledata);
    console.log("", Tabledata);
    console.log('document name', documentName)
    if (Griddata.length != 0) {
      const filterdata = Griddata.filter((obj: any) => {
        console.log("documentName", obj.documentName.startsWith(documentName));
        if (obj.documentName.toLowerCase().includes(documentName.toLowerCase())) {
          console.log('document name', documentName)
          console.log('obj.documentName ', obj.documentNamess)
          return obj.documentName
        }
      }
      );
      console.log("filterdata after filtering ", filterdata);
      setTabledata(filterdata)
    }
  }


  const handlePageClick = (event: any) => {
    scrollTop
    console.log('pagecount', pageCount);
    { event.selected === 0 ? setpginitial(0) : setpginitial(event.selected) }
    const newOffset = ((event.selected) * 25) % Tabledata.length;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    console.log('Current Items', currentItems);
    setItemOffset(newOffset);

  };

  const pendingcheckgridloadapi = async () => {

    let gridresponse = await getUploadGridData();
    if (gridresponse.status == 200) {
      setItemOffset(0);
      setpginitial(0);
      console.log("Grid Resposne of PricingUpload", gridresponse);
      console.log("Grid Resposne ", gridresponse.data);
      if (gridresponse.data != '') {
        setItemOffset(0);
        setpginitial(0);
        console.log("Grid Resposne ", gridresponse.data.result[0].documentName);
        let Griddataa = gridresponse.data.result;
        let pendingarray: any = []
        const datearray = Griddataa.filter((obj: any) => {
          console.log('inside datearray', obj.status);
          if (obj.status == 'Pending') {
            if (obj.subType.length == 0) {
              pendingarray.push(obj.type);
            }
            else {
              for (subtyperowindex = 0; subtyperowindex < obj.subType.length; subtyperowindex++) {
                pendingarray.push(obj.subType[subtyperowindex]);
              }
            }
          }
          return (new Date(obj.date).getMonth() + '/' + new Date(obj.date).getDate() + '/' + new Date(obj.date).getFullYear());
        })
        console.log('pendingcheckarray inside pendingcheckgridloadapi', pendingarray);
        setnewPendingcheckarray(pendingarray);

        datearray.sort((a: any, b: any) => a.dateandTime > b.dateandTime ? -1 : 1);
        setGriddata(datearray);
        setTabledata(datearray);
        setMasterLoader(false);
        if (pendingarray.includes('Zone')) {
          setPopup('zoneispending')
        }
        else if (pendingarray.includes('StoreZone')) {
          setPopup('storezoneispending')
        }
        else {
          settype('Pricing');
          setFilename('');
          setSubtype([]);
          setPopup('upload');
          console.log('datearray', datearray)
        }
      }
      else {
        setGriddata([]);
        setTabledata([]);
        settype('Pricing');
        setFilename('');
        setSubtype([]);
        // setMasterLoader(false);
        setPopup('upload');
        setMasterLoader(false);
      }
    }
    else {
      setMasterLoader(false);
      setItemOffset(0);
      setpginitial(0);
      setPopup('uploadResponseFail');
    }
  }

  const handleOnChangeFDate = (InputTxt: any) => {
    console.log('Fdate', InputTxt);
    if (InputTxt == '') {
      setfromdt('');
    }
    const stDatefrm = moment(InputTxt, 'YYYY-MM-DD');
    console.log('start date FDT', stDatefrm);

    const todatefrm = moment(filterobject.To, 'YYYY-MM-DD');
    console.log('To date moment FDT', todatefrm);

    const daysRem: any = stDatefrm.diff(todatefrm, 'days');
    console.log('days remaining from to', daysRem);

    const daysRemTo: any = todatefrm.diff(stDatefrm, 'days');
    console.log('Days remaning FDT', daysRemTo);

    const dtfrm = new Date();
    const timefrm = dtfrm.toLocaleString('en-US', { timeZone: 'America/Chicago' });
    const currentfrm = timefrm.split(',')[0];
    const formatedfrm: any = moment(currentfrm).format('YYYY-MM-DD');

    const curdtdiffFrom: any = stDatefrm.diff(formatedfrm, 'days');
    console.log('currentdiff FDT', curdtdiffFrom);

    const curdtdiffTO: any = todatefrm.diff(formatedfrm, 'days');
    console.log('currentdiff FDT', curdtdiffTO);

    if (curdtdiffFrom > 0) {
      seterrormsg('futureerror');
    }
    else {
      seterrormsg('');
    }
    if (curdtdiffTO > 0) {
      seterrormsgTo('futureerror');
    }
    else {
      seterrormsgTo('');
    }

    if (curdtdiffTO <= 0 && curdtdiffFrom <= 0) {
      if (daysRem > 0) {
        seterrormsg('dateerror');
        seterrormsgTo('');
      }
      else if (InputTxt == filterobject.To) {
        seterrormsgTo('');
        seterrormsg('');
      }
      else {
        seterrormsgTo('');
        seterrormsg('');
      }
    }

    const TempArr =
      InputTxt !== undefined && InputTxt !== '' && InputTxt != null
        ? InputTxt.split('-')
        : null;
    if (TempArr !== null) {
      const format: any = moment(InputTxt).format('MM/DD/YYYY');
      const temp = InputTxt.replace(format);
      setfromdt(temp);

    }
    if (InputTxt != '' && filterobject.To == '') {
      const dt = new Date();
      const time = dt.toLocaleString('en-US', { timeZone: 'America/Chicago' });
      console.log('time in fromdtchange', time);
      const current = time.split(',')[0];
      console.log('Current in fromdtchnage', current);
      const formated: any = moment(current).format('YYYY-MM-DD');
      console.log(' formated in fromdtchange', formated);
      setFilterObject({ ...filterobject, To: formated });
    }
  }

  const handleOnChangeTDate = (txt: any) => {
    console.log('To date txt', txt);
    console.log('from date fromdt', fromdt)
    console.log('from date fromdt ===', fromdt == txt)
    if (txt == '') {
      setFilterObject({ ...filterobject, To: '' });
    }
    const stDate = moment(fromdt, 'YYYY-MM-DD');
    console.log('start date', stDate);
    const todate = moment(txt, 'YYYY-MM-DD');
    console.log('To date moment', todate);
    const daysRem: any = stDate.diff(todate, 'days');
    console.log('Days reman', daysRem);
    const dt = new Date();
    const time = dt.toLocaleString('en-US', { timeZone: 'America/Chicago' });
    const current = time.split(',')[0];
    const formated: any = moment(current).format('YYYY-MM-DD');
    const curdtdiffTO: any = todate.diff(formated, 'days');
    console.log('currentdiff', curdtdiffTO);
    const curdtdiffFrom: any = stDate.diff(formated, 'days');
    console.log('currentdiff FDT', curdtdiffFrom);

    if (curdtdiffFrom > 0) {
      seterrormsg('futureerror');
    }
    else {
      seterrormsg('');

    }
    if (curdtdiffTO > 0) {
      seterrormsgTo('futureerror');
    }
    else {
      seterrormsgTo('');

    }
    if (curdtdiffFrom <= 0 && curdtdiffTO <= 0) {
      if (daysRem > 0) {
        seterrormsgTo('dateerror');
        seterrormsg('');
      }
      else if (fromdt == txt) {
        seterrormsgTo('');
        seterrormsg('');

      }
      else {
        seterrormsg('');
        seterrormsgTo('');
      }
    }
    const TempArr =
      txt !== undefined && txt !== '' && txt != null
        ? txt.split('-')
        : null;
    if (TempArr !== null) {
      const formin: any = moment(txt).format('MM/DD/YYYY');
      const temp = txt.replace(formin);
      setFilterObject({ ...filterobject, To: temp });
    }
  }

  const scrollTop = (ref: any) => {
    if (ref) {
      const element = ref;
      console.log('elementsss', element);
      const clientWidth = element.getBoundingClientRect().height;
      console.log('clientWidth refAssignCallback', clientWidth);
      const negCal = clientWidth * 2;
      const negVal = clientWidth - negCal;
      window.scrollBy(0, negVal);
    }
  };

  const openFilterpopup = () => {
    //To disable the future dates in RACdate Picker Using the us timezone
    const dt = new Date();
    const time = dt.toLocaleString('en-US', { timeZone: 'America/Chicago' });
    const current = time.split(',')[0];
    console.log('current', moment(current).format('YYYY-MM-DD'));
    const maxdt = moment(current).format('YYYY-MM-DD');
    console.log('maxdt', maxdt);
    return (
      <Card className={`${classes.positionAbsolute} ${classes.filterDropDown}`}>
        <CardContent>
          <Grid className={`${classes.componentSpaceBetween} ${classes.mb2}`}>
            <Typography variant='h5'>Advanced Filter</Typography>
            <FilterClose
              onClick={() => setPopup('')}
            ></FilterClose>
          </Grid>
          <Grid container spacing={4} className={classes.mb2}>
            <Grid item xs={12} sm={4} md={4} lg={6}>
              <RACSelect
                inputLabel='Status'
                options={statusobject}
                defaultValue={filterobject.Status}
                onChange={(e) => {
                  setValidationErrorMsg('')
                  console.log("e.target .value", e.target.value)
                  console.log(filterobject)
                  setFilterObject({ ...filterobject, Status: e.target.value })
                }}
              ></RACSelect>
            </Grid>

          </Grid>
          <Grid className={`${classes.mb2}`}>
            <Typography variant='h5'>Date Range</Typography>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <RACDatePicker
                label="From"
                value={fromdt}
                onChange={(e: any) => {
                  handleOnChangeFDate(e);
                }
                }
                inputProps={{
                  max: maxdt
                }}
              ></RACDatePicker>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <RACDatePicker
                label="To"
                value={filterobject.To}
                onChange={(e: any) => {
                  handleOnChangeTDate(e);
                }
                }
                inputProps={{
                  max: maxdt
                }}
              ></RACDatePicker>
            </Grid>
          </Grid>
          <Grid className={progressclasses.validerroralign}>
            {errormsg == 'dateerror' ? <span style={{ fontFamily: 'OpenSans-regular', color: 'red' }}>From date should be Lesser than To date</span>
              : errormsg == 'futureerror' ? <span style={{ fontFamily: 'OpenSans-regular', color: 'red' }}>Invalid From Date</span>
                : ''}
          </Grid>
          <Grid className={progressclasses.validerroralign}>
            {errormsgTo == 'dateerror' ? <span style={{ fontFamily: 'OpenSans-regular', color: 'red' }}>To date should be greater than from date</span>
              : errormsgTo == 'futureerror' ? <span style={{ fontFamily: 'OpenSans-regular', color: 'red' }}>Invalid To Date</span>
                : ''}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} className={` ${classes.mt4}  ${classes.componentRight}`}>
            <RACButton variant="outlined" color="primary" className={classes.mr2}
              onClick={() => {
                setFilterObject(filterobjectmodel);
                setfromdt('');
                seterrormsg('');
                seterrormsgTo('');
                setTabledata(Griddata.sort((a: any, b: any) => a.dateandTime > b.dateandTime ? -1 : 1));
                setItemOffset(0);
                { pginitial != 0 ? setpginitial(0) : undefined } //new logic for pagination

              }}>Clear</RACButton>
            <RACButton variant="contained" color="primary" className={classes.mr2}
              disabled={applyDisabled()}
              onClick={() => {
                setItemOffset(0);
                { pginitial != 0 ? setpginitial(0) : undefined }
                filterbyparams();
              }}>Apply</RACButton>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      <Grid container ref={scrollTop}>
        {masterLoader ? (
          <Grid className={progressclasses.masterLoader}>
            <Grid className={progressclasses.Loader}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : null}
        <Grid item md={12}>
          <Grid className={`${classes.alignCenter} ${classes.dflex} ${classes.m2}`}>
            <Typography variant="h5">Pricing Uploads</Typography>
            <RACInfoIcon
              infoTitle='This page enables the Pricing Department to upload any price changes of an inventory item 
              check the status of the uploaded file in the Pricing Uploads grid.'
              iconClassname={Pricingrep.infocss}
              placement={'right'}
              classes={{ tooltip: Pricingrep.tooltip, arrow: Pricingrep.Tooltiparrow }}
            >
            </RACInfoIcon>
          </Grid>

          <Card className={`${classes.m2} ${classes.racCard}  ${classes.overFlowVisible} ${classes.mbFooterSpacing} `}>
            <CardContent>
              <Grid item xs={12} sm={12} md={12} lg={12} className={`${classes.componentRight} ${classes.m3}   `}>
                <Grid className={`${classes.positionRelative}`} >
                  <RACButton
                    variant="outlined"
                    color="primary"
                    size="small"
                    id="filterIcon"
                    className={`${classes.mr2} ${classes.filterBtn}`}
                    onClick={() => { popup == 'filter' ? setPopup('') : setPopup('filter') }
                    }
                  >
                    <RacFilter></RacFilter>
                  </RACButton>
                  {popup == 'filter' ?
                    openFilterpopup()
                    : null}
                </Grid>
                <Grid>
                  <Grid className={`${classes.inputGroup} ${classes.searchBar}`}>
                    <RACTextbox
                      value={documentName}
                      placeHolder="Search"
                      OnChange={(e) => {
                        setDocumentName(e.target.value);
                      }}
                      className={`${classes.searchSpacing}`}
                      onKeyPress={handleKeypress}
                    ></RACTextbox>
                    <RACButton className={`${classes.px0} ${classes.searchBarIcon}`}
                      onClick={() => {
                        setItemOffset(0);
                        { pginitial != 0 ? setpginitial(0) : undefined }
                        searchbydocumentname();
                      }
                      }
                    >
                      <SearchbarIcon></SearchbarIcon>
                    </RACButton>
                  </Grid>
                </Grid>
              </Grid>
              {
                popup == 'loader' ?
                  <Grid
                    className={progressclasses.loader}
                  >
                    <CircularProgress />
                  </Grid>
                  :
                  popup == 'somethigwentwrong' ?
                    <Grid className={progressclasses.textSpaceNorecords}>
                      <Grid className={progressclasses.textcenterNorecords}>
                        < SomethingWrong className={progressclasses.racErrorIcon} /></Grid>
                      <Grid className={`${progressclasses.textcenterImage} ${classes.fontBold}`}>Something Went Wrong !</Grid>
                    </Grid> :

                    Tabledata != undefined ?
                      Tabledata.length > 0 ?
                        <Grid>

                          <RACTable
                            renderTableHead={() => buildGridHeader(headerdata, Tabledata)}
                            renderTableContent={renderTableContent}
                          />
                          <Grid className={`${classes.mb1} ${classes.textRight}`}>
                            <Grid className={`${progressclasses.totrec}`}>
                              <Typography># of Records: {pginitial != 0 ? outoffrec : Tabledata.slice(0, 25).length}/{totrecord}</Typography>
                            </Grid>
                            <ReactPaginate
                              nextLabel=">"
                              onPageChange={(num: any) => handlePageClick(num)}
                              pageCount={pageCount}
                              pageRangeDisplayed={2}
                              marginPagesDisplayed={0}
                              previousLabel="<"
                              pageClassName={progressclasses.pagination}
                              pageLinkClassName={progressclasses.pagelink}
                              previousLinkClassName={pginitial === 0 ? progressclasses.paginationprevnextdisable : progressclasses.paginationprevnext}
                              nextLinkClassName={pginitial === pageCount - 1 ? progressclasses.paginationprevnextdisable : progressclasses.paginationprevnext}
                              breakLabel=""
                              breakClassName={progressclasses.pagination}
                              breakLinkClassName={progressclasses.pagination}
                              containerClassName={progressclasses.pagination}
                              activeClassName={progressclasses.pageactive}
                              forcePage={pginitial === 0 ? 0 : undefined}
                            />
                          </Grid>
                        </Grid>
                        :
                        masterLoader == false ?
                          <Grid>
                            <RACTable
                              renderTableHead={rendernorecordsheader}
                            />
                            <Grid className={progressclasses.textSpaceNorecords}>
                              <Grid className={progressclasses.textcenterNorecords}>
                                <NorecordsIcon className={progressclasses.racErrorIcon} />
                              </Grid>
                              <Grid className={progressclasses.textcenterImage}>
                                No Records Found !
                              </Grid>
                            </Grid>
                          </Grid>
                          : ''
                      : null
              }

            </CardContent>
          </Card>
          <RACModalCard
            isOpen={popup == 'upload' ? true : false}
            maxWidth="xs"
            closeIcon={true}
            borderRadius="20px"
            onClose={() => {
              setPopup('');
              setFilename('');
              ValidationErrorMsg !== '' ? setValidationErrorMsg('') : '';
              settype('');
            }}
            // eslint-disable-next-line react/no-children-prop
            children={uploadpopup()}
            title="Upload File"
          />
          <RACModalCard
            isOpen={popup == 'uploadResponseSuccess' || popup == 'zoneispending' || popup == 'storezoneispending' || popup == 'filevalidation' || popup == 'nosuchkeyfound' || popup == 'uploadResponseFail' || popup == 'uploadResponse400' || popup == 'curentUserresponsefail' ? true : false}
            maxWidth="xs"
            borderRadius="20px"
            children={uploadResponse()}
            title=""
          />
          <RACModalCard
            isOpen={popup == 'downloadResponseFail' ? true : false}
            maxWidth="xs"
            closeIcon={true}
            borderRadius="20px"
            onClose={() => setPopup('')}
            children={downloadResponse()}
            title=""
          />
        </Grid>

      </Grid>

      <Grid container className={classes.fixedFooter}>
        <Grid>
          <RACButton variant="outlined" color="primary" className={`${classes.borderNone} ${classes.textBlack}`}
            onClick={() => history.push('/dashboard')}
          >Cancel</RACButton>
        </Grid>
        <Grid>
          <RACButton variant="contained" color="primary"
            onClick={() => {
              setMasterLoader(true)
              pendingcheckgridloadapi()
            }
            }
          >Upload Files</RACButton>
        </Grid>
      </Grid>
    </>
  );
}
