/* eslint-disable */
import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  RACTable,
  RACTableCell,
  RACTableRow,
  Typography,
  Tooltip,
  RACButton,
  CircularProgress,
  RACModalCard,
} from "@rentacenter/racstrap";
import { ReactComponent as SuccessIcon } from "../../../assets/images/success-icon.svg";
import { PricingQueueContext } from "./PricingQueueContext";
import moment from "moment";
import { ProgressBar } from "../Styles/ProgressStyles";
import { globalStyles } from "../Styles/globalstyles";
import { PricingReportStyle } from "../Styles/PricingReport";
import { ReactComponent as WarningIcon } from "../../../assets/images/no-records-found.svg";
import ReactPaginate from "react-paginate";
import PricingQueueFilter from "./PricingQueueFilter";
import { ReactComponent as NorecordsIcon } from "../../../assets/images/No-records.svg";
import { ReactComponent as SortdownIcon } from "../../../assets/images/sort-down-icon.svg";
import { ReactComponent as SortupIcon } from "../../../assets/images/sort-icon-up.svg";
import { ReactComponent as AlertIcon } from '../../../assets/images/Alert.svg'
import { getXlFile } from "../../../api/user";
import AutotermApproval from "./AutoTermApproval";
import { PackageApproval } from "./PackageApproval";
import { VERIFY_IN_PROG, VERIFYPRC } from "../../../constants/constants";

export default function PQGrid() {
  const {
    tableHeader,
    setTableHeader,
    tableBody,getReferenceandPricingQueueDetail,
    setTableBody,
    setLoader,
    loader,
    pageCount,comments, setComments,
    setPageCount,isApproved, setIsApproved,setAlterPopUp,
    totalRowCount,
    setTotalRowCount,filterReq,isapprovalPopup, setIsapprovalPopup,
    pageNumber,isFranchiseeUser,setIsFranchiseeUser,
    setPageNumber,companyCode, setCompanyCode,
    somethingWentWrong,IsPackage, setIsPackage,
    setSomethingWentWrong,hideMainPage,setHideMainPage,
    packageCriteria,setPackageCriteria,
    noRecords,isApprover,
    fetchPricingQueueDetail,
    sortDirection,
    approvalPopup,
    setApprovalPopup,setIsAlterOpen,setAutotermRange,
    indexValue,
    setIndexValue,isRejected,setIsRejected
  } = useContext(PricingQueueContext);
  let total: any, successPercent: any, failPercent: any;
  const classes = globalStyles();
  const pricing = PricingReportStyle();
  const [Downloadloader, setDownloadloader] = useState("-1");

  const progressclasses = ProgressBar();
  const formatObj = {
    IMMEDIATE: "Immediate",
    OVERNIGHT: "Overnight",
    PRICING: "Pricing",
    STOREZONE: "StoreZone",
    AUTOTERM: "Autoterm",
    WAITING: "Waiting for Approval",
    QUEUED: "Queued",
    FAILED: "Failed",
    CANCELLED: "Cancelled",
    PROCESSING: "Processing",
    COMPLETED: "Completed",
    REJECTED: "Rejected",
    PACKAGE:'Package',
    PARTIAL:' Partially Completed',
    VERIFYPRC: VERIFY_IN_PROG
  };
  const mapper = new Map(Object.entries(formatObj));
  useEffect(() => {
    getReferenceandPricingQueueDetail();
  }, []);
  function Mapper(item: any) {
    console.log(mapper, "the mapper in the function");

    return formatObj.hasOwnProperty(item) ? mapper.get(item) : item;
  }
  const getdocumentbydownloadbtn = async (documentKey: any) => {
    try {
      // setLoader("Y");
      console.log("documentKey inside  getdocumentbykeyname", documentKey);
      const key = {
        key: documentKey,
        type: "GET",
      };
      const pricingDocumentResponse = await getXlFile(key);
      console.log("RESPOSNE FROM PRICING DOC", pricingDocumentResponse);
      const link = pricingDocumentResponse.data.generatedUrl;
      if (pricingDocumentResponse.status == 200) {
        const element = document.createElement("a");
        element.setAttribute("href", `${link}`);
        element.setAttribute("style", "display:none");
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      } else {
        throw "err";
      }

      setDownloadloader("-1");
    } catch (err) {
      setSomethingWentWrong(true);
    } finally {
      setLoader(true);
    }
  };
  const sortArray = (
    columnId: string,
    index: any,
    datacolumn: any,
    dataValues: any,
    isDate: boolean = false
  ) => {
    try {
      let sortedData;
      if (isDate) {
        sortedData =
          datacolumn[index].sortOrder == sortDirection.DESC
            ? dataValues.sort((a: any, b: any) => {
                let date1 = moment(b[columnId]);
                let date2 = moment(a[columnId]);
                return date2.isBefore(date1) ? -1 : 1;
              })
            : dataValues.sort((a: any, b: any) => {
                let date1 = moment(b[columnId]);
                let date2 = moment(a[columnId]);
                return date1.isBefore(date2) ? -1 : 1;
              });
      } else {
        sortedData =
          datacolumn[index].sortOrder == sortDirection.DESC
            ? dataValues.sort((a: any, b: any) =>
                b[columnId] < a[columnId] ? -1 : 1
              )
            : dataValues.sort((a: any, b: any) =>
                a[columnId] < b[columnId] ? -1 : 1
              );
      }
      const reorderColumns = datacolumn;
      reorderColumns[index].sortOrder =
        reorderColumns[index].sortOrder == sortDirection.ASC
          ? sortDirection.DESC
          : sortDirection.ASC;
      reorderColumns.map((value: any, index1: any) => {
        reorderColumns[index1].IsSorted = index == index1 ? true : true;
      });
      console.log("sortedData", sortedData);
      setTableBody([...sortedData]);
      setTableHeader([...reorderColumns]);
    } catch (error) {
      console.log(error);
    }
  };

  const renderTableHeader = () => {
    return (
      <>
        {tableHeader.map((value: any, index: any) => {
          return value.columnTitle == "Action" ? (
            <RACTableCell className={`${classes.p2} ${classes.textCenter}`}>
              Action
            </RACTableCell>
          ) : (
            <RACTableCell key={index} className={classes.p2}>
              {value.IsSortable ? (
                <a
                  style={{
                    color: "black",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  id={value.columnId}
                  onClick={() =>
                    sortArray(
                      value.columnId,
                      index,
                      tableHeader,
                      tableBody,
                      value.columnId == "submittedDateTime" ? true : false
                    )
                  }
                >
                  {value.columnTitle + "  "}
                  {value.IsSorted === true ? (
                    value.sortOrder == sortDirection.DESC ? (
                      <SortupIcon className={classes.ml0} />
                    ) : (
                      <SortdownIcon className={classes.ml0} />
                    )
                  ) : null}
                </a>
              ) : (
                <a
                  style={{
                    color: "black",
                    textDecoration: "none",
                  }}
                  id={value.columnId}
                >
                  {value.columnTitle + "  "}
                </a>
              )}
            </RACTableCell>
          );
        })}
      </>
    );
  };
  const scrollTop = (ref: any) => {
    if (ref) {
      const element = ref;
      console.log("elementsss", element);
      const clientWidth = element.getBoundingClientRect().height;
      console.log("clientWidth refAssignCallback", clientWidth);
      const negCal = clientWidth * 2;
      const negVal = clientWidth - negCal;
      window.scrollBy(0, negVal);
    }
  };
  const handlePageClick = (event: any) => {
    console.log(event, "Tefnk");
    
    scrollTop;
    // fetchpricingGridData(pricingBodyData, event.selected);
    setPageNumber(event.selected);
    filterReq.offset = (event.selected + 1),
    filterReq.companyCode=isFranchiseeUser ? companyCode : undefined;
    fetchPricingQueueDetail(filterReq); //have to send page initial
  };

  function somethingWentWrongShow() {
    return (
      <>
        <Grid>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <WarningIcon width={"10%"} height={"10%"} />
          </Grid>
          <Grid>
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                padding: "5%",
              }}
            >
              Something went Wrong
            </Typography>
          </Grid>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <RACButton
              onClick={() => {
                // On getting something went wrong to pricing page refreshed to the initial page

                setSomethingWentWrong(false);
              }} //somethingWentWrong--->SWT
              color="primary"
              variant="contained"
            >
              OK
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  }
{
}
  const renderTableBody = () => {
    return (
      <>
        
        {loader == "Y" ? (
            <>
              <Grid  style={{position:'absolute',left:'45%',top:'40%'}}>
                <Grid  className={pricing?.textcenterImage}>
                  <CircularProgress />
                </Grid>
              </Grid>
            </>
          ) : noRecords ? (
          <RACTableRow className={`${classes.bgTransparent} `}>
            <RACTableCell></RACTableCell>
            <RACTableCell></RACTableCell>
            <RACTableCell></RACTableCell>
            <RACTableCell></RACTableCell>
            <RACTableCell></RACTableCell>
            <RACTableCell></RACTableCell>
            <Grid>
              <Grid
                style={{ position: "relative", right: "426px" }}
                className={pricing.textSpaceNorecords}
              >
                <Grid className={pricing.textcenterNorecords}>
                  <NorecordsIcon className={pricing.racErrorIcon} />
                </Grid>
                <Grid className={pricing.textcenterImage}>
                  No Records Found !
                </Grid>
              </Grid>
            </Grid>
          </RACTableRow>
        ) : (
          tableBody.map((item: any, index: any) => {
            // let objArr = Object.keys(item);
            if (item.status.toUpperCase() == "PARTIAL" ||( item.start.toUpperCase() == VERIFYPRC && Number(item.failureCount) > 0)) {
              total = parseInt(item.failureCount) + parseInt(item.successCount);
              successPercent = Math.trunc(
                (parseInt(item.successCount) / total) * 100
              );
              failPercent = Math.trunc(100 - successPercent);
              if (failPercent < 5) {
                failPercent = 5;
              }
              if (successPercent < 5) {
                successPercent = 5;
              }
              console.log("successcc", successPercent, failPercent);
            }
            let headersArr = [
              "type",
              "schedule",
              "submittedDateTime",
              "submittedBy",
              "approvedBy",
              "comments",
              "status" /*,'action' */,
            ];
            return (
              <>
                <RACTableRow key={index}>
                  {headersArr.map((iterator: any) => {
                    // let formatString = .toString();
                    return (
                      <>
                        <RACTableCell title= {item[iterator]?.length>20 ? item[iterator].slice(0,17)+'...' : ''}>
                          {iterator == "type" ? (
                            <Typography color="primary">
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setComments(item.comments);

                                  if (
                                    item[iterator].toLowerCase() == "pricing"
                                  ) {
                                    console.log(item.pricing, "the lord", item, item.comments);

                                    setIndexValue({
                                      pricing: item.pricing,
                                      schedule: item.schedule,
                                      status: item.status,
                                      queueId: item.pricingQueueId,
                                    });
                                    setApprovalPopup("pricing");
                                    setIsapprovalPopup(true);
                                  } else if (
                                    item[iterator].toLowerCase() == "storezone"
                                  ) {
                                    setApprovalPopup("StoreZone");
                                    setIndexValue({
                                      storeZone: item.storeZone,
                                      status: item.status,
                                      queueId: item.pricingQueueId,
                                      schedule: item.schedule,
                                    });
                                    setIsapprovalPopup(true);
                                  }
                                  else if(item[iterator].toLowerCase() == "autoterm"){
                                    setHideMainPage(true);
                                    setApprovalPopup("AutoTerm");
                                    setAutotermRange(item.autoterm[0].range?.sort((a: any, b: any) =>
                                    Number(a['startDay']) < Number(b['startDay']) ? -1 : 1
                                  ));
                                    setIndexValue({
                                      autoTerm: item.autoterm[0],
                                      status: item.status,
                                      queueId: item.pricingQueueId,
                                      schedule: item.schedule,
                                    });
                                    setIsPackage(false);
                                  }
                                  else if(item[iterator].toLowerCase() == "package"){
                                    setIndexValue({
                                      package: item.package[0],
                                      status: item.status,
                                      queueId: item.pricingQueueId,
                                      schedule: item.schedule,
                                    });
                                    setHideMainPage(true);
                                    setIsPackage(true);
                                    setPackageCriteria(item.package[0].criteria)
                                    setApprovalPopup("Package");
                                  }

                                  // setIndexValue({pricing :item.pricing, schedule : item.schedule});
                                }}
                              >
                                {formatObj.hasOwnProperty(item[iterator])
                                  ? Mapper(item[iterator])
                                  : item[iterator]} &nbsp;
                              </a>
                              {/* {item.missingRMSPricingCount =='0'? <AlertIcon width={"3%"} height={"3%"}/>: null} */}
                            </Typography>
                            
                          ) : item[iterator] ? (
                            iterator == "submittedDateTime" ? (
                              `${moment(new Date(item[iterator])).format(
                                "MM/DD/YYYY, hh:mm A"
                              )}`
                            ) : formatObj.hasOwnProperty(item[iterator]) ? (
                              Mapper(item[iterator])
                            ) : (
                              item[iterator]?.length>20 ? item[iterator].slice(0,17)+'...' : item[iterator]
                            )
                          ) : (
                            "-"
                          )}
                        </RACTableCell>
                      </>
                    );
                  })}
                  <RACTableCell className={classes.p2}>
                    {item.status.toUpperCase() == "PROCESSING" ? (
                      <Typography
                        variant="caption"
                        className={`${classes.textGrey} ${classes.fs6} ${classes.fontSemiBold}`}
                      >
                        <Grid
                          className={`${progressclasses.progress} ${progressclasses.bgStriped}`}
                          style={{ width: "100%" }}
                        ></Grid>
                      </Typography>
                    ) : (
                      ""
                    )}
                    {item.status.toUpperCase() == "VALLIDATION FAILED" ? (
                      <Typography
                        variant="caption"
                        className={`${classes.textGrey} ${classes.fs6} ${classes.fontSemiBold}`}
                      >
                        <Grid
                          className={`${progressclasses.progress}`}
                          style={{ width: "100%" }}
                        ></Grid>
                      </Typography>
                    ) : (
                      ""
                    )}
                    {item.status.toUpperCase() == "CANCELLED" ? (
                      <Typography
                        variant="caption"
                        className={`${classes.textGrey} ${classes.fs6} ${classes.fontSemiBold}`}
                      >
                        <Grid
                          className={`${progressclasses.progress}`}
                          style={{ width: "100%" }}
                        ></Grid>
                      </Typography>
                    ) : (
                      ""
                    )}
                    {item.status.toUpperCase() == "QUEUED" ? (
                      <Typography
                        variant="caption"
                        className={`${classes.textGrey} ${classes.fs6} ${classes.fontSemiBold}`}
                      >
                        <Grid
                          className={`${progressclasses.progress} ${classes.yellow}`}
                          style={{ width: "100%" }}
                        ></Grid>
                      </Typography>
                    ) : (
                      ""
                    )}
                    {item.status.toUpperCase() == "REJECTED" ? (
                      <Typography
                        variant="caption"
                        className={`${classes.textGrey} ${classes.fs6} ${classes.fontSemiBold}`}
                      >
                        <Grid
                          className={`${progressclasses.progress} ${classes.rejected}`}
                          style={{ width: "100%" }}
                        ></Grid>
                      </Typography>
                    ) : (
                      ""
                    )}
                    {item.status.toUpperCase() == "WAITING" ? (
                      <Typography
                        variant="caption"
                        className={`${classes.textGrey} ${classes.fs6} ${classes.fontSemiBold}`}
                      >
                        <Grid
                          className={`${progressclasses.progress} ${classes.darkGrey}`}
                          style={{ width: "100%" }}
                        ></Grid>
                      </Typography>
                    ) : (
                      ""
                    )}
                    {item.status.toUpperCase() == "COMPLETED" ? (
                      <Typography
                        variant="caption"
                        className={`${classes.textGrey} ${classes.fs6} ${classes.fontSemiBold}`}
                      >
                        <Grid className={progressclasses.progress}>
                          <Tooltip
                            title={`${item.successCount}`}
                            placement="top"
                          >
                            <Grid
                              className={`${progressclasses.progressBar} ${progressclasses.bgCompleted}`}
                              style={{ width: "100%" }}
                            ></Grid>
                          </Tooltip>
                        </Grid>
                      </Typography>
                    ) : (
                      ""
                    )}
                    {item.status.toUpperCase() == "FAILED" ? (
                      <Typography
                        variant="caption"
                        className={`${classes.textGrey} ${classes.fs6} ${classes.fontSemiBold}`}
                      >
                        <Grid className={progressclasses.progress}>
                          <Tooltip title={`${item.failureCount}`} placement="top">
                            <Grid
                              className={`${progressclasses.progressBar} ${progressclasses.bgFailed}`}
                              style={{ width: `100%` }}
                            ></Grid>
                          </Tooltip>
                        </Grid>
                      </Typography>
                    ) : (
                      ""
                    )}

                    {item.status.toUpperCase() == "PARTIAL" ? (
                      <Typography
                        variant="caption"
                        className={`${classes.textGrey} ${classes.fs6} ${classes.fontSemiBold}`}
                      >
                        <Grid
                          className={progressclasses.progress}
                          style={{ background: "red" }}
                        >
                          <Tooltip
                            title={`${item.successCount}`}
                            placement="top"
                          >
                            <Grid
                              className={`${progressclasses.progressBar} ${progressclasses.progressBarRightRadius0} ${progressclasses.bgCompleted}`}
                              style={{ width: `${successPercent}%` }}
                            ></Grid>
                          </Tooltip>
                          <Tooltip
                            title={`${item.failureCount}`}
                            placement="top"
                            style={{ background: "yellow" }}
                          >
                            <Grid
                              className={`${progressclasses.progressBar} ${progressclasses.progressBarLeftRadius0} ${progressclasses.bgFailed}`}
                              style={{ width: `${failPercent}%` }}
                            ></Grid>
                          </Tooltip>
                        </Grid>
                      </Typography>
                    ) : (
                      ""
                    )}
                                        {
                     item.status == VERIFYPRC ? 
                     ((item.failCount) > 0 ) ? 
                     <Typography variant="caption" className={`${classes.textGrey} ${classes.fs6} ${classes.fontSemiBold}`}>
                      <Grid className={progressclasses.progress}>
                        <Tooltip title={`${item.successCount}`} placement="top" >
                          <Grid className={`${progressclasses.progressBar} ${progressclasses.progressBarRightRadius0} ${progressclasses.bgCompleted}`} style={{ width: `${successPercent}%` }} ></Grid>
                        </Tooltip>
                        <Tooltip title={`${item.failCount}`} placement="top" >
                          <Grid className={`${progressclasses.progressBar} ${progressclasses.progressBarLeftRadius0} ${progressclasses.bgFailed}`} style={{ width: `${failPercent}%` }} ></Grid>
                        </Tooltip>
                      </Grid>
                    </Typography>
                     :
                     <Typography variant="caption" className={`${classes.textGrey} ${classes.fs6} ${classes.fontSemiBold}`}>
                      <Grid className={progressclasses.progress}>
                        <Tooltip title={`${item.successCount}`} placement="top" >
                          <Grid className={`${progressclasses.progressBar} ${progressclasses.bgCompleted}`} style={{ width: "100%" }} ></Grid>
                        </Tooltip>
                      </Grid>
                    </Typography>
                      : ''

                    }
                  </RACTableCell>
                  <RACTableCell
                    title={
                      Downloadloader != item.documentKey &&
                      Downloadloader != "-1"
                        ? "Please wait while downloading..."
                        : undefined
                    }
                    className={`${classes.p2} ${classes.textCenter} ${
                      Downloadloader != item.documentKey &&
                      Downloadloader != "-1"
                        ? `progressclasses.waitcursor`
                        : undefined
                    }`}
                  >
                    {item.status.toUpperCase() == "PENDING" ||
                    item.status.toUpperCase() == "WAITING" ||
                    item.status.toUpperCase() == "QUEUED" ||
                    item.status.toUpperCase() == "CANCELLED" ||
                    item.status.toUpperCase() == "PROCESSING"||
                    item.status.toUpperCase() == "REJECTED" ? (
                      <RACButton
                        disabled
                        color="primary"
                        className={classes.bgLightBlue}
                      >
                        Download
                      </RACButton>
                    ) : Downloadloader != item.documentKey ? (
                      Downloadloader != "-1" ? (
                        <RACButton
                          disabled
                          color="primary"
                          className={`${classes.bgLightBlue}`}
                          id={index}
                        >
                          Download
                        </RACButton>
                      ) : (
                        <RACButton
                          color="primary"
                          className={classes.bgLightBlue}
                          id={index}
                          onClick={(event: any) => {
                            setDownloadloader(item.documentKey);
                            getdocumentbydownloadbtn(item.documentKey);
                            //need to add the download function
                          }}
                        >
                          Download
                        </RACButton>
                      )
                    ) : (
                      <>
                        <CircularProgress
                          className={`${progressclasses.newcircularprogress}`}
                        ></CircularProgress>
                      </>
                    )}
                  </RACTableCell>
                </RACTableRow>
              </>
            );
          })
        )}
      </>
    );
  };
  const approvedFn= ()=>{
    return (
      <>
        <Grid>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <SuccessIcon width={"10%"} height={"10%"} />
          </Grid>
          <Grid>
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                padding: "5%",
              }}
            >
              Request has been {`${isRejected? isRejected == 'C' ? 'cancelled': 'rejected': 'approved'}`}
            </Typography>
          </Grid>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <RACButton
              onClick={() => {
                setLoader(true);
                setIsApproved(false);
              }}
              color="primary"
              variant="contained"
            >
              OK
            </RACButton>
          </Grid>
          
        </Grid>
      </>
    );
  }

  return (
    <>
    

{   hideMainPage    ? IsPackage ?  <PackageApproval/> : <AutotermApproval/> :<>
          <PricingQueueFilter />
          <Grid md={12}>
            <Card>
              <CardContent style={{ paddingBottom:'0px' }}>
                <Grid className={classes.fixTableHeadAgrTrsRec} style={{height:'74vh', position: 'relative'}}>
                  <RACTable
                    renderTableHead={renderTableHeader}
                    renderTableContent={renderTableBody}
                    
                  ></RACTable>
                </Grid>
                <Grid className={` ${classes.textRight}`}>
                  {totalRowCount ? (
                    <>
                      <Grid className={`${pricing.totrec}`}>
                        <Typography>
                          {" "}
                          # of Records: &nbsp;
                          {pageNumber == 0
                            ? totalRowCount < 25
                              ? totalRowCount
                              : 25 * 1
                            : (pageNumber + 1) == pageCount
                            ? totalRowCount
                            : (pageNumber + 1) * 25}
                          /{totalRowCount}
                        </Typography>
                      </Grid>
                      <ReactPaginate
                        nextLabel=">"
                        onPageChange={(num: any) => handlePageClick(num)}
                        pageCount={pageCount}
                        initialPage={pageNumber}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={0}
                        disableInitialCallback={true}
                        previousLabel="<"
                        breakLabel=""
                        pageClassName={pricing.pagination}
                        pageLinkClassName={pricing.pagelink}
                        previousLinkClassName={
                          pageNumber === 0
                            ? pricing.paginationprevnextdisable
                            : pricing.paginationprevnext
                        }
                        nextLinkClassName={
                          pageNumber === pageCount - 1
                            ? pricing.paginationprevnextdisable
                            : pricing.paginationprevnext
                        }
                        breakClassName={pricing.pagination}
                        breakLinkClassName={pricing.pagination}
                        containerClassName={pricing.pagination}
                        activeClassName={pricing.pageactive}
                        forcePage={pageNumber === 0 ? 0 : undefined} //new logic
                      />
                    </>
                  ) : null}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

        </>}


      {/* {approvalPopup == "StoreZone" ? (
        <StoreZoneApproval indexNo={indexValue} />
      ) : null} */}

      <RACModalCard
        isOpen={somethingWentWrong}
        maxWidth="xs"
        // closeIcon={true}
        borderRadius="20px"
        onClose={() => setSomethingWentWrong(false)}
        title=""
      >
        {somethingWentWrongShow()}
      </RACModalCard>
      <RACModalCard
        isOpen={isApproved}
        maxWidth="xs"
        borderRadius={10}
        closeIcon={true}
        onClose={()=>{
          setIsRejected(false)
          setIsApproved(false)}}
      >
        {approvedFn()}
      </RACModalCard>
      <Card style={{ width:'100%', position:'fixed', zIndex:'1',left:'0',bottom:'0'}}>
    <CardContent  style={{padding:'0px'}}>
        {/* Footer  */}
        {hideMainPage?
        <Grid
            style={{
              float: "right",
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              marginTop: "1%",
              height: "45px",
              marginBottom: '1%'
            }}
            md={12}
          >
            {(indexValue?.status?.toUpperCase() == "WAITING" ||
            indexValue?.status?.toUpperCase() == "PENDING") /* && isApprover */ ? (
              <>
                <RACButton
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={isApprover ? false : true}
                  style={{ float: "right", backgroundColor: "#2CD19D", marginRight:'1%' }}
                  onClick={() => {
                    setAlterPopUp({
                      text: `approve ${
                        approvalPopup.toLowerCase() == "autoterm"
                          ? "Autoterm"
                          : approvalPopup.toLowerCase() == "package"
                          ? "Package"
                          : ""
                      }`,
                      value: "A",
                    });
                    setIsRejected(false)
                    setIsAlterOpen(true);
                    setHideMainPage(false)
                    setIsPackage(false);
                    // setApprovalPopup("");
                    // if(addPricingReq.pricingType!= 'TEMPORARY' && addPricingReq.endDate =='');
                    //need to add start date & end date validation
                  }}
                >
                  Approve
                </RACButton>
                <Grid title={!comments && isApprover ?'Add comments to reject the request':''} style={{marginRight:'2%'}}>
                <RACButton
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ backgroundColor: "#E85C5C", marginRight: "2%", height:'46px', opacity:`${comments?'100%':'60%'}` }}
                  disabled={comments && isApprover ? false : true}
                  onClick={() => {
                    setAlterPopUp({
                      text: `reject ${
                        approvalPopup.toLowerCase() == "autoterm"
                          ? "Autoterm"
                          : approvalPopup.toLowerCase() == "package"
                          ? "Package"
                          : ""
                      }`,
                      value: "R",
                    });
                    setIsAlterOpen(true);
                    setIsRejected(true);
                    setHideMainPage(false);
                    setIsPackage(false);



                    // if(addPricingReq.pricingType!= 'TEMPORARY' && addPricingReq.endDate =='');
                    //need to add start date & end date validation
                  }}
                >
                  Reject
                </RACButton>
                </Grid>
               
              </>
            ) : (indexValue?.status?.toUpperCase() == "QUEUED" && indexValue.schedule.toUpperCase() == 'OVERNIGHT' /* && isApprover */ )?(
              <RACButton
                variant="contained"
                color="primary"
                size="small"
                style={{ backgroundColor: "#E85C5C", marginRight: "2%" }}
                disabled={isApprover? false : true}
                onClick={() => {
                  setAlterPopUp({
                    text: `cancel ${
                      approvalPopup.toLowerCase() == "autoterm"
                        ? "Autoterm"
                        : approvalPopup.toLowerCase() == "package"
                        ? "Package"
                        : ""
                    }`,
                    value: "C",
                  });
                  setIsRejected('C');
                  setIsAlterOpen(true);
                  setHideMainPage(false);
                  setIsPackage(false);



                  // if(addPricingReq.pricingType!= 'TEMPORARY' && addPricingReq.endDate =='');
                  //need to add start date & end date validation
                }}
              >
                Cancel Approval
              </RACButton> 
            )
              : 
              <>
              </>
            }
            <RACButton
              variant="outlined"
              color="primary"
              size="small"
              style={{ float: "right", marginRight: "2%" }}
              onClick={()=>{
                setHideMainPage(false);
                setComments('');
                setIsPackage(false);
              }}
            >
              Cancel
            </RACButton>
          </Grid>: <>
          <Card
          style={{
            width: "100%",
            position: "fixed",
            zIndex: "1",
            left: "0",
            bottom: "0",
          }}
        >
          <CardContent></CardContent>
        </Card>
          </>}
    </CardContent>
</Card>
    </>
  );
}
