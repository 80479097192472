/* eslint-disable */
import {
  Typography,
  Grid,
  RACButton,
  List,
  ListItem,
  Card,
  CardContent,
  RACInfoIcon,
  RACTextbox,
  RACSelect, RACModalCard,
} from "@rentacenter/racstrap";
import { ReactComponent as SuccessIcon } from "../../../assets/images/success-icon.svg";
import { ReactComponent as RacFilter } from "../../../assets/images/filter-white.svg";
import { ReactComponent as ExcelImg } from "../../../assets/images/excel.svg";
import { ReactComponent as AccordianOpen } from "../../../assets/images/up-arrow-filter.svg";
import { ReactComponent as AccordianClose } from "../../../assets/images/down-arrow-filter.svg";
import React, { useContext, useEffect, useState } from "react";
import { PricingDetailsProvider } from "./PricingContext";
import * as XLSX from "xlsx";
import { ContainerContext } from "../../../app/App";
import { globalStyles } from "../Styles/globalstyles";
import { MultiSelect } from "react-multi-select-component";
import { PricingReportStyle } from "../Styles/PricingReport";
import { getEmployeeInfoAndReferenceData, validateApprover } from "../../shared/getEmployeeInfoAndReferenceData";
import { CustomPropInterface } from "../../..";
import moment from "moment";
import { BracketDetails } from "../../shared/interface/getProfileBasedOnMenuInterface";
import { DropDownDetails } from "../../shared/handleFormData";
import { columnWidths, excelColumnOrder, headerMapping } from "../../../constants/constants";
export default function PricingFilter() {
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const pricing = PricingReportStyle();
  const Pricingrep = PricingReportStyle();
  const [zoneSelected, setZoneSelected] = useState([]);
  const {
    filterOptions,
    setFilterOptions, loader,
    setNoRecords,
    type, pricingGridData, searchBtnTrigger,
    category, isApproved, setIsApproved, setIsApprover,
    zoneOptions,
    setZoneOptions, companyCode, setCompanyCode, isFranchiseeUser, setIsFranchiseeUser,
    fetchpricingGridData, setSelectedItemsArray, updatePricingQueueStatus,
    setCurrentTableData,
    setPricingGridData,
    setTotalRowCount, totalRowCount,
    setPageNumber,
    setSomeThingWentWrng, setAuthOpen,
    filterObj,
    isFilterOpen,
    setLoader, setEmployeeId, setEmailId,
    setSearchBtnTrigger,
    setIsFilterOpen,
    currentTableData,
    isSLACompleted,
    setIsSLACompleted,
    isSLACancel, isApprovalOpen,
    setIsSLACancel, setIsApprovalOpen,bracketOptionsOrg, setbracketOptionsOrg,deptOptions, setDeptOptions,
    subDeptOptionsOrg, setsubDeptOptionsOrg
  } = useContext(PricingDetailsProvider);


  const [subDeptOptions, setsubDeptOptions] = useState<any>([{
    label: "Select",
    value: "",
    subdepartmentnumber: ''
  }]);
  const [bracketOptions, setbracketOptions] = useState<DropDownDetails[]>([{
    label: "Select",
    value: "",
    subdepartmentnumber: ''
  }]);

  const classes = globalStyles();
  useEffect(() => {
    debugger;
    if (isSLACancel == false && isSLACompleted == true) {
      console.log('The price queue Completed');
      setIsApprovalOpen(false);
      setAuthOpen(false);

      updatePricingQueueStatus('A'); //approved popup
    }
  }, [isSLACancel, isSLACompleted]);

  useEffect(() => {
    getCompanyCodeandDropdown();
  }, []);
  const getCompanyCodeandDropdown = async () => {
    debugger;
    console.log("welcome back");
    let req = {
      type: [
        "DEPARTMENT",
        "SUBDEPARTMENT",
        "BRACKET"
      ]
    }
    getEmployeeInfoAndReferenceData(containerData, req)
      .then((rep) => {
        setEmployeeId(rep.employeeId);
        setEmailId(rep.emailId);

        setIsApprover(validateApprover(rep.menuConfigArray))
        if (rep.isFranchiseeUser) {
          setIsFranchiseeUser(rep.isFranchiseeUser)
          if (rep.companyCode?.length > 0)
            setCompanyCode(rep.companyCode);
          else
            setSomeThingWentWrng(true);
        }
        sessionStorage.setItem("EmployeeId", rep.employeeId);
        sessionStorage.setItem("userName", rep.userName ? rep.userName : "");


        const createOptionsArray = (
          data: any,
          labelKey: any,
          valueKey: any
        ) => [
            ...data.map((val: any) => ({
              label: val[labelKey],
              value: val[valueKey],
            })),
          ];

        setZoneOptions(
          createOptionsArray(rep.zone.sort((a: any, b: any) =>
            a["label"].localeCompare(b["label"], undefined, { numeric: 'true' })), "label", "value")
        );
        const subDeptOptionsArr = createOptionsArray(
          rep.dropdownData.subdepartment,
          "descEn",
          "subdepartmentNumber"
        ).map((item) => ({
          ...item,
          deptNumber: rep.dropdownData.subdepartment.find(
            (val: any) => val.subdepartmentNumber === item.value
          )?.departmentNumber,
          deptId: rep.dropdownData.subdepartment.find(
            (val: any) => val.subdepartmentNumber === item.value
          )?.departmentId,
        }));
        console.log(subDeptOptionsArr, "The sub dept array with dept id");
        if (rep.dropdownData && rep.zone) {
          setLoader(false);
          let department = rep.dropdownData.department.sort((a: any, b: any) => a["descEn"].localeCompare(b["descEn"], undefined, { sensitivity: 'base' })
          ).map((obj: any) => {
            return {
              label: obj.descEn,
              value: obj.departmentNumber,
            };
          });
          department.unshift({
            label: "Select",
            value: "",
          });
          setDeptOptions(department);

          let subDepartment: any = rep.dropdownData.subdepartment.sort((a: any, b: any) => a["descEn"].localeCompare(b["descEn"], undefined, { sensitivity: 'base' })
          ).map(
            (obj: any) => {
              return {
                label: obj.descEn,
                value: obj.subdepartmentNumber,
                departnumber: obj.departmentNumber,
              };
            }
          );
          subDepartment.unshift({
            label: "Select",
            value: "",
          });
          const bracketArray: DropDownDetails[] = rep.dropdownData.bracket.sort((a: BracketDetails, b: BracketDetails) => a["descEn"].localeCompare(b["descEn"], undefined, { sensitivity: 'base' })
          ).map(
            (obj: BracketDetails) => {
              return {
                label: obj.descEn,
                value: obj.bracketNumber,
                subdepartmentnumber: obj.subdepartmentNumber,
              };
            }
          );
          bracketArray.unshift({
            label: "Select",
            value: "",
            subdepartmentnumber: ''
          })
          const bracket: DropDownDetails[] = bracketArray;
          setbracketOptionsOrg(bracket)
          setbracketOptions(bracket);
          setsubDeptOptions(subDepartment);
          setsubDeptOptionsOrg(subDepartment);
        } else {
          throw "Error";
        }
      })
      .catch((err) => {
        console.log(err, "the error  in  useeffect");
        setSomeThingWentWrng(true);
      });
  };
  const validatefield = (e: any, key: any) => {
    debugger;
    if (key == "rmsItemNumber")
      e.target.value = e.target.value.replace(/\D/g, "");
    if (key == "bracketNumber")
      e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, "");
    setFilterOptions({ ...filterOptions, [`${key}`]: e.target.value.trim() });
  };
  const cancelClick = () => {
    {
      setPageNumber(0);
      setCurrentTableData([]);
      setPricingGridData(undefined);
      setSearchBtnTrigger(false);
      setTotalRowCount(0);
      setFilterOptions(filterObj);
      setZoneSelected([]);
      setsubDeptOptions(JSON.parse(JSON.stringify(subDeptOptionsOrg)));
      setbracketOptions(JSON.parse(JSON.stringify(bracketOptionsOrg)))
    }
  };  
  const exportToExcel = async () => {
    if (currentTableData.length) {
      try {
        setLoader(true);
  
  
        let ExportToExcelArray = currentTableData?.map((item: any) => {
          const newItem: any = {};
          excelColumnOrder.forEach((key) => {
              newItem[key] = item[key];
          });
          return newItem;
        });
  
        let newexcel = XLSX.utils.book_new();
        let data = XLSX.utils.json_to_sheet(ExportToExcelArray);
        // Rename the headers
        XLSX.utils.sheet_add_aoa(data, [excelColumnOrder.map((key: string) => headerMapping[key] || key)], { origin: 'A1' });
        data['!cols'] = columnWidths;
        newexcel.SheetNames = ["Pricing"];
        newexcel.Sheets.Pricing = data;
  
        XLSX.writeFile(newexcel, `Pricing_${moment().format('YYYY-MM-DD hh_mm_ss')}.xlsx`);
      } catch (e) {
        console.log("error", e);
      }
      setLoader(false);
    }
  };
  const approvedFn = () => {
    return (
      <>
        <Grid>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <SuccessIcon width={"10%"} height={"10%"} />
          </Grid>
          <Grid>
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                padding: "5%",
              }}
            >
              Request has been approved.
            </Typography>
          </Grid>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <RACButton
              onClick={() => {
                setIsApproved(false);
                setIsSLACompleted(false);
                setIsSLACancel(false);
                // updatePricingQueueStatus('A')
              }}
              color="primary"
              variant="contained"
            >
              OK
            </RACButton>
          </Grid>

        </Grid>
      </>
    );
  }
  return (
    <>
      {/* Filter Button & pricing Details */}
      <Grid container md={12} lg={12} sm={12} xs={12}>
        <Grid
          item
          md={9}
          lg={9}
          sm={4}
          xs={4}
          style={{ padding: "1% 0%", display: "flex" }}
        >
          <Typography variant="h4" style={{ alignSelf: "center" }}>
            {" "}
            Pricing Details
          </Typography>
          <RACInfoIcon
              infoTitle='To filter the records, please select Department/RMS/Brand/Model fields.'
              iconClassname={Pricingrep.infocss}
              placement={'right'}
              classes={{ tooltip: Pricingrep.tooltip, arrow: Pricingrep.Tooltiparrow }}
            >
            </RACInfoIcon>
        </Grid>
        <Grid
          md={3}
          lg={3}
          sm={8}
          xs={8}
          item
          container
          style={{ padding: "0.5% 0%" }}
        >
          <Grid
            item
            md={7}
            lg={7}
            sm={4}
            xs={4}
            style={{ display: "flex", flexDirection: 'row-reverse' }}
            className={classes.CustomListStyle}
          >

            <List>
              <ListItem>Extended Aisle </ListItem>
            </List>
            <div
              style={{
                height: "10px",
                width: "10px",
                background: "#BB01FF",
                alignItems: "bottom",
                alignSelf: "center",
              }}
            ></div>
          </Grid>
          <Grid
            item
            style={{ alignSelf: "center", cursor: "pointer", opacity: loader || !(pricingGridData && searchBtnTrigger) ? '50%' : '100%' }}
            md={1}
            lg={1}
            sm={4}
            xs={4}
          >
            <Typography>
              <ExcelImg
                onClick={(e: any) => {
                  exportToExcel();
                }}
              />
            </Typography>
          </Grid>
          <Grid item md={4} lg={4} sm={4} xs={4}>
            <RACButton
              variant="contained"
              color="primary"
              size="small"
              style={{ cursor: "pointer" }}
              onClick={() => {

                setSelectedItemsArray([]);
                setIsFilterOpen(!isFilterOpen);
              }}
            >
              <RacFilter></RacFilter>
              <span style={{ margin: "0px 8px" }}>Filter</span>
              {/* Need to add filter icon !important */}
              {isFilterOpen ? <AccordianOpen /> : <AccordianClose />}
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
      {/* Filter Options Grid */}
      {isFilterOpen ? (
        <Grid style={{ margin: "0.5% 0% 1.5% 0%", overflow: "visible" }}>
          <Card style={{ overflow: "visible", borderRadius: '12px' }}>
            <CardContent style={{ overflow: "visible" }}>
              <Grid container spacing={4}>
                <Grid item md={2}>
                  <Typography>
                    Zone<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <MultiSelect
                    isLoading={zoneOptions?.length > 0 || (isFranchiseeUser && companyCode.length == 0) ? false : true}
                    options={zoneOptions}
                    value={zoneSelected}
                    className={`${pricing.demo}  ${pricing.prcingzoneMultiSelect} `}
                    onChange={(e: any) => {
                      setZoneSelected(e);
                      let arr = e.map((item: any) => {
                        if (item.value == item.value) return item.value;
                      });
                      console.log(arr);

                      setFilterOptions({
                        ...filterOptions,
                        ["zoneNumber"]: arr,
                      });
                    }}
                    labelledBy={"Select"}
                  />
                </Grid>
                <Grid item md={2}>
                  <Typography>Department</Typography>
                  <RACSelect
                    loading={deptOptions.length > 1 ? false : true}
                    options={deptOptions}
                    defaultValue={
                      filterOptions.departmentNumber
                        ? filterOptions.departmentNumber
                        : ""
                    }
                    onChange={(e) => {
                      setFilterOptions({
                        ...filterOptions,
                        ["departmentNumber"]: e.target.value,
                        ["subdepartmentNumber"]: "",
                        ["bracketNumber"]: ""
                      });
                      let subDept = subDeptOptionsOrg.filter((obj: any) => {
                        if (obj.departnumber == e.target.value) {
                          return obj;
                        }
                      });
                      if (subDept.length == 0) {
                        subDept = subDeptOptionsOrg;
                      }
                      else {
                        subDept.sort((a: any, b: any) =>
                          a["descEn"] < b["descEn"] ? -1 : 1).unshift({
                            label: "Select",
                            value: "",
                            deptId: "",
                          });
                      }
                      setsubDeptOptions(subDept);
                    }}
                  />
                </Grid>
                <Grid item md={2}>
                  <Typography>Sub Department</Typography>
                  <RACSelect
                    options={subDeptOptions}
                    loading={(subDeptOptions.length > 1 )? false : true}
                    defaultValue={
                      filterOptions.subdepartmentNumber
                        ? filterOptions.subdepartmentNumber
                        : ""
                    }
                    isDisabled={!(filterOptions.departmentNumber)}
                    onChange={(e) => {
                      setFilterOptions({
                        ...filterOptions,
                        ["subdepartmentNumber"]: e.target.value,
                        ["bracketNumber"]: ""
                      });
                      let bracket: DropDownDetails[] = bracketOptionsOrg.filter((obj: any) => {
                        if (obj.subdepartmentnumber == e.target.value) {
                          return obj;
                        }
                      });
                      if (bracket.length == 0) {
                        bracket = subDeptOptionsOrg;
                      }
                      else {
                        bracket.sort((a: DropDownDetails, b: DropDownDetails) =>
                          a["label"] < b["label"] ? -1 : 1).unshift({
                            label: "Select",
                            value: "",
                            subdepartmentnumber: "",
                          });
                      }
                      setbracketOptions(bracket);
                    }}
                  />
                </Grid>
                <Grid item md={2}>
                  <Typography>Bracket</Typography>
                  {/* <RACTextbox
                    maxlength={60}
                    value={
                      filterOptions.bracketNumber ? filterOptions.bracketNumber : ""
                    }
                    OnChange={(e) => validatefield(e, "bracketNumber")}
                  /> */}
                  <RACSelect
                    options={bracketOptions}
                    loading={(bracketOptions.length > 1 )? false : true}
                    isDisabled ={!(filterOptions.subdepartmentNumber)}
                    defaultValue={
                      filterOptions.bracketNumber
                        ? filterOptions.bracketNumber
                        : ""
                    }
                    onChange={(e) => {
                      setFilterOptions({
                        ...filterOptions,
                        ["bracketNumber"]: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item md={2}>
                  <Typography>RMS #</Typography>
                  <RACTextbox
                    maxlength={13}
                    value={
                      filterOptions.rmsItemNumber
                        ? filterOptions.rmsItemNumber
                        : ""
                    }
                    OnChange={(e) => validatefield(e, "rmsItemNumber")}
                  />
                </Grid>
                <Grid item md={2}>
                  <Typography>Brand</Typography>
                  <RACTextbox
                    maxlength={50}
                    value={
                      filterOptions.brandName ? filterOptions.brandName : ""
                    }
                    OnChange={(e) => validatefield(e, "brandName")}
                  />
                </Grid>
                <Grid item md={2}>
                  <Typography>Model #</Typography>
                  <RACTextbox
                    maxlength={30}
                    value={
                      filterOptions.modelNumber ? filterOptions.modelNumber : ""
                    }
                    OnChange={(e) => validatefield(e, "modelNumber")}
                  />
                </Grid>
                
                <Grid item md={2}>
                  <Typography>Type</Typography>
                  <RACSelect
                    options={type}
                    defaultValue={filterOptions.type ? filterOptions.type : ""}
                    onChange={(e) => {
                      setFilterOptions({
                        ...filterOptions,
                        ["type"]: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item md={2}>
                  <Typography>Category</Typography>
                  <RACSelect
                    options={category}
                    defaultValue={
                      filterOptions.category ? filterOptions.category : ""
                    }
                    onChange={(e) => {
                      setFilterOptions({
                        ...filterOptions,
                        ["category"]: e.target.value,
                      });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid md={12} style={{ float: "right" }} spacing={3}>
                  <RACButton
                    variant="contained"
                    color="primary"
                    // The apply button enabled only if the zone is selected.
                    disabled={
                      (filterOptions["zoneNumber"]?.length > 0 &&
                        !filterOptions["zoneNumber"]?.includes("") && !(loader)
                        && (filterOptions.departmentNumber || filterOptions.rmsItemNumber)
                      )
                        ? false
                        : true
                    }
                    size="small"
                    style={{
                      float: "right",
                      cursor: "pointer",
                      marginLeft: "2%",
                    }}
                    onClick={() => {
                      setCurrentTableData([]);
                      setPricingGridData([]);
                      setTotalRowCount(0);
                      setPageNumber(0);
                      setSearchBtnTrigger(true);
                      setNoRecords(false);
                      setSelectedItemsArray([]);
                      fetchpricingGridData("A");

                    }}
                  >
                    Apply
                  </RACButton>
                  <RACButton
                    variant="outlined"
                    color="primary"
                    size="small"
                    disabled={!(loader) ? false
                      : true}
                    style={{ float: "right", cursor: "pointer" }}
                    onClick={() => cancelClick()}
                  >
                    Clear
                  </RACButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ) : null}
      <RACModalCard
        isOpen={isApproved}
        maxWidth="xs"
        borderRadius={10}
        closeIcon={true}
        onClose={() => {
          setIsSLACompleted(false);
          setIsSLACancel(false);
          setIsApproved(false)
        }}
      >
        {approvedFn()}
      </RACModalCard>
    </>
  );
}
